import React, { useEffect, useState } from "react";
import "./Filter.css";
import { GetCleaners, getCleanersummary } from "../../API/axios";

function FilterCleanerSummary(props) {
  const [cleaners, setCleaners] = useState([]);
  const [allcleaners, setAllCleaners] = useState([]);

  const currentYear = new Date().getFullYear();
  const numberOfPreviousYears = 5;

  const yearOptions = Array.from(
    { length: numberOfPreviousYears + 1 },
    (_, index) => currentYear - numberOfPreviousYears + index
  );

  useEffect(() => {
    const GetCleanersFilter = async () => {
      try {
        const data = await GetCleaners();
        setAllCleaners(data.data.result);
      } catch (err) {
        console.log(err);
      }
    };
    const GetAllCleaners = async () => {
      try {
        const Data = await getCleanersummary();
        //console.log(Data.data.data);
        setCleaners(Data.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetCleanersFilter();
    GetAllCleaners();
  }, []);

  const uniqueCleanerFirstNames = Array.from(
    new Set(allcleaners.map((item) => item.f_name))
  );
  const uniqueCleanerLastNames = Array.from(
    new Set(allcleaners.filter((items) => items.f_name === props.firstName).map((item) => item.l_name))
  );

  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFirstName(e.target.value);
            }}
          >
            <option value={""} selected>
              Select First Name
            </option>
            {cleaners.length > 0 ? (
              uniqueCleanerFirstNames.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))
            ) : (
              <option value={""} selected>
                No Cleaner to select
              </option>
            )}
          </select>
        </div>
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setLastName(e.target.value);
            }}
          >
            <option value={""} selected>
              Select Last Name
            </option>
            {cleaners.length > 0 ? (
              uniqueCleanerLastNames.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))
            ) : (
              <option value={""} selected>
                No Cleaner to select
              </option>
            )}
          </select>
        </div>
        {/*<div className="col">
          <select
            value={props.filterYear}
            className="form-select"
            onChange={(e) => {
              props.setFilterYear(e.target.value);
            }}
          >
            <option value="">Select Year</option>
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFilterMonth(e.target.value);
            }}
          >
            <option selected value={""}>
              Select Month
            </option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          </div>*/}
      </div>
    </div>
  );
}

export default FilterCleanerSummary;
