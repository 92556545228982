import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import AuditCreateForm from "./AuditCreateForm";

function AuditModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.Title == "create" ? "Upload Audit Document" : "Update Help"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AuditCreateForm
            title={props.Title}
            show={props.show}
            setShow={props.setShow}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AuditModal;
