import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import { GetClients, addNewCleaner, updateCleaner,sendNotification } from "../../API/axios";
import ProPic from "../../Assets/ProPic.png";
import PRIMARY from "../../Theme/palette";
import "./CreateForm.css";

function CreateForm(props) {
  const [formData, setFormData] = useState({});
  const [sitesData, setSitesData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchSitesData = async () => {
      try {
        const response = await GetClients();
        if (response.status === 200) {
          const data = response.data;
          //console.log(data);
          setSitesData(data.sites);
        } else {
          console.error("Failed to fetch data: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;

      setValidationErrors({
        ...validationErrors,
        [name]: "",
      });

      if (
        name === "startDate" &&
        formData.endDate &&
        value > formData.endDate
      ) {
        // If Start Date is changed and it's later than the current End Date,
        // update the End Date to match the new Start Date
        setFormData({
          ...formData,
          [name]: value,
          endDate: value,
        });
      } else if (
        name === "endDate" &&
        formData.startDate &&
        value < formData.startDate
      ) {
        // If End Date is changed and it's earlier than the current Start Date,
        // update the Start Date to match the new End Date
        setFormData({
          ...formData,
          [name]: value,
          startDate: value,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    };
    fetchSitesData();

    if (props.title === "update") {
      // var fullStartDate = props.item.start_date;
      // var fullEndDate = props.item.end_date;

      // if (fullStartDate && fullStartDate.length >= 10) {
      //   var trimmedStartDate = fullStartDate.substring(0, 10);
      // } else {
      //   console.log("Invalid start date format");
      // }
      // if (fullEndDate && fullEndDate.length >= 10) {
      //   var trimmedEndDate = fullEndDate.substring(0, 10);
      // } else {
      //   console.log("Invalid end date format");
      // }
      setFormData({
        firstName: props.item.f_name,
        lastName: props.item.l_name,
        mobile: props.item.phone,
        email: props.item.email,
        password: "",
        profilePicture: props.item.image,
        confirmPassword: "",
      });
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        password: "",
        profilePicture: "",
        confirmPassword: "",
        address: "",
        profilePicture: null,
        document: null,
        startDate: "",
        endDate: "",
      });
    }
  }, [props.title, props.item]);

  const uniqueClientNames = Array.from(
    new Set(sitesData?.map((item) => item.site_name))
  );

  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "startDate") {
      setFormData((prevData) => ({
        ...prevData,
        endDate: "",
      }));
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    
    if (files && files.length > 0) {
      const selectedFile = files[0];

      setFormData({
        ...formData,
        [name]: selectedFile,
      });
    } else {
      setFormData({
        ...formData,
        [name]: formData.document,
      });
    }

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const validateMobileNumber = (mobile) => {
    const mobileRegex = /^\+\d{2,3}[0-9]{9}$/;
    return mobileRegex.test(mobile);
  };
  const validateemail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (props.title === "update") {
      if (!formData.firstName) {
        errors.firstName = "First Name is required.";
      }
      if (!formData.profilePicture) {
        errors.profilePicture = "Profile Picture is required.";
      }
      if (!formData.lastName) {
        errors.lastName = "Last Name is required.";
      }
      if (!formData.mobile) {
        errors.mobile = "Mobile Number is required.";
      } else if (!validateMobileNumber(formData.mobile)) {
        errors.mobile = "Please enter a valid mobile number.";
      }
      if (!formData.email) {
        errors.email = "Email is required.";
      } else if (!validateemail(formData.email)) {
        errors.email = "Please enter a valid email address.";
      }
      if (!formData.password) {
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]){8,}/.test(
          formData.password
        )
      ) {
        errors.password =
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol, and be at least 8 characters long.";
      }

      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password do not match.";
      }
      // if (!formData.address) {
      //   errors.address = "Client is required.";
      // }
      // if (!formData.startDate) {
      //   errors.startDate = "Start Date is required.";
      // }
      // if (!formData.endDate) {
      //   errors.endDate = "End Date is required.";
      // } else if (formData.startDate > formData.endDate) {
      //   errors.endDate =
      //     "End Date should be a future date compared to the Start Date.";
      // }
    } else {
      if (!formData.firstName) {
        errors.firstName = "First Name is required.";
      }
      if (!formData.profilePicture) {
        errors.profilePicture = "Profile Picture is required.";
      }
      if (!formData.lastName) {
        errors.lastName = "Last Name is required.";
      }
      if (!formData.mobile) {
        errors.mobile = "Mobile Number is required.";
      } else if (!validateMobileNumber(formData.mobile)) {
        errors.mobile = "Please enter a valid mobile number.";
      }
      if (!formData.email) {
        errors.email = "Email is required.";
      } else if (!validateemail(formData.email)) {
        errors.email = "Please enter a valid email address.";
      }
      if (!formData.password) {
        errors.password = "Password is required.";
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]){8,}/.test(
          formData.password
        )
      ) {
        errors.password =
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol, and be at least 8 characters long.";
      }

      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password do not match.";
      }
      if (!formData.password) {
        errors.password = "Password is required.";
      }
      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password do not match.";
      }
      if (!formData.address) {
        errors.address = "Client is required.";
      }
      if (!formData.document) {
        errors.document = "Document is required.";
      }
      if (!formData.startDate) {
        errors.startDate = "Start Date is required.";
      }
      if (!formData.endDate) {
        errors.endDate = "End Date is required.";
      } else if (formData.startDate > formData.endDate) {
        errors.endDate =
          "End Date should be a future date compared to the Start Date.";
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);

    if (validateForm()) {
      setisButtonLoading(true);

      const createRequestBody = {
        fname: formData.firstName,
        lname: formData.lastName,
        phone: formData.mobile,
        email: formData.email,
        password: formData.password,
        image: formData.profilePicture,
        role: "cleaner",
        site_id: formData.address,
        documents: formData.document,
        start_date: formData.startDate,
        end_date: formData.endDate,
      };

      if (props.title === "update") {
        const updateRequestBody = {
          fname: formData.firstName,
          lname: formData.lastName,
          phone: formData.mobile,
          email: formData.email,
          password: formData.password,
          image:
            typeof formData.profilePicture === "string"
              ? "null"
              : formData.profilePicture,
          role: "cleaner",
          //site_id: formData.address,
          //documents: formData.document,
          //start_date: formData.startDate,
          //end_date: formData.endDate,
          //emp_no: props.item.emp_no,
          //document_name: "hellothisisNew",
          //allocation_id: props.item.siteAllocateiD,
          //user_id: props.item.user_id,
        };
        //console.log("kkkk:", updateRequestBody);

        const notificationReqbody = {
          title: 'Your Password has been Changed',
          description: 
            `Your password has been changed by the admin. the new password is ${formData.password}`,
          clientId: props.item.site_id,
      };
      // console.log("clientId : ", props.item.site_id);
      const responseNotificationSaved = await sendNotification(notificationReqbody);
      console.log("responseNotificationSaved : ", responseNotificationSaved);
        updateCleaner(props.item.user_id, updateRequestBody)
          .then((response) => {
            if (response.status) {
              // props.handleClose();
              props.GetAllCleaners();
              props.setShow(!props.show);
              toast.success("Cleaner updated successfully!");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.response.data.message);
            setisButtonLoading(false);
            setIsDisabled(false);
          });
      } else {
        addNewCleaner(createRequestBody)
          .then((response) => {
            console.log("response : ", response);
            if (response.status) {
              props.handleClose();
              props.setShow(!props.show);
              toast.success("Cleaner Registered successfully!");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.response.data.message);
            setisButtonLoading(false);
            setIsDisabled(false);
          });
      }
    } else {
      setIsDisabled(false);
    }
  };

  const handleProfilePictureChange = (event) => {
    setFormData({
      ...formData,
      profilePicture: event.target.files[0],
    });
  };
  // const Selectedimg = () => {
  //   if (formData.profilePicture.File) {
  //     return URL.createObjectURL(formData.profilePicture);
  //   } else return formData.profilePicture;
  // };
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group
            controlId="formBasicName"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Form.Label>Profile Picture:</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              style={{ display: "none" }}
              ref={inputRef}
            />
            {formData.profilePicture ? (
              <img
                style={{ width: "150px", height: "150px", borderRadius: "50%" }}
                src={
                  props.title === "update" &&
                  typeof formData.profilePicture === "string"
                    ? formData.profilePicture
                    : URL.createObjectURL(formData.profilePicture)
                }
                alt={
                  props.title === "update"
                    ? "profile_pic_update"
                    : "profile_pic"
                }
                onClick={() => {
                  inputRef.current.click();
                }}
              />
            ) : (
              <img
                style={{ width: "150px", height: "auto" }}
                src={ProPic}
                alt="profile_pic"
                onClick={() => {
                  inputRef.current.click();
                }}
              />
            )}
            {validationErrors.profilePicture && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.profilePicture}
              </p>
            )}
          </Form.Group>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {validationErrors.firstName && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.firstName}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {validationErrors.lastName && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.lastName}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Mobile:</Form.Label>
              <PhoneInput
                international
                defaultCountry="AU"
                placeholder="Enter phone number"
                value={formData.mobile}
                onChange={(value) =>
                  handleInputChange({ target: { name: "mobile", value } })
                }
              />
              <p
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  fontSize: "12px",
                  padding: "2px 8px",
                  margin: "0",
                  color:
                    formData.mobile &&
                    formData.mobile.replace(/\D/g, "").length > 12
                      ? "red"
                      : "inherit",
                }}
              >
                {formData.mobile
                  ? `${formData.mobile.replace(/\D/g, "").length} /12`
                  : ""}
              </p>
              {validationErrors.mobile && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.mobile}
                </p>
              )}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {validationErrors.email && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.email}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Password:</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                {formData.password ? (
                  <span
                    className="toggle-password"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {validationErrors.password && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.password}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Confirm Password:</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                {formData.confirmPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  >
                    {isConfirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {validationErrors.confirmPassword && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.confirmPassword}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        {props.title === "create" ? (
          <>
            <br />
            <Row style={{ marginBottom: "10px" }}>
              <Form.Group>
                <Form.Label>Client:</Form.Label>
                <Form.Select
                  id="clientName"
                  name="clientName"
                  value={formData.clientName}
                  onChange={handleInputChange}
                >
                  <option value="">Select a Client</option>

                  {uniqueClientNames && uniqueClientNames.length > 0 ? (
                    uniqueClientNames.map((site) => (
                      <option key={site} value={site}>
                        {site}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No clients available
                    </option>
                  )}
                </Form.Select>
                {validationErrors.clientName && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.clientName}
                  </p>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Select Site:</Form.Label>
                <Form.Select
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  disabled={!formData.clientName} // Disable the site selection if no client is selected
                >
                  <option value="">Select a Site</option>

                  {sitesData && sitesData.length > 0 && formData.clientName ? (
                    sitesData
                      .filter((site) => site.site_name === formData.clientName)
                      .map((filteredSite) => (
                        <option
                          key={filteredSite.site_id}
                          value={filteredSite.site_id}
                        >
                          {filteredSite.site_address}
                        </option>
                      ))
                  ) : (
                    <option value="" disabled>
                      {formData.clientName
                        ? "No sites available for the selected client"
                        : "Please select a client first"}
                    </option>
                  )}
                </Form.Select>
                {validationErrors.address && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.address}
                  </p>
                )}
              </Form.Group>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Form.Group>
                <Form.Label>Upload Document:</Form.Label>
                <Form.Control
                  type="file"
                  name="document"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                  multiple
                />
                {formData.document && (
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "0",
                      fontWeight: "bold",
                    }}
                  >
                    Selected Document: {formData.document.name}
                  </p>
                )}
                {validationErrors.document && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.document}
                  </p>
                )}
              </Form.Group>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col>
                <Form.Group>
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleInputChange}
                  />
                  {validationErrors.startDate && (
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "2px 0",
                        margin: "0",
                      }}
                      className="text-danger"
                    >
                      {validationErrors.startDate}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleInputChange}
                    min={
                      formData.startDate &&
                      (() => {
                        const nextDay = new Date(formData.startDate);
                        nextDay.setDate(nextDay.getDate() + 1);
                        return nextDay.toISOString().split("T")[0];
                      })()
                    }
                  />
                  {validationErrors.endDate && (
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "2px 0",
                        margin: "0",
                      }}
                      className="text-danger"
                    >
                      {validationErrors.endDate}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : (
          <div></div>
        )}
        <br />
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default CreateForm;
