import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import PRIMARY from "../../../Theme/palette";
import { GetClients, uploadAuditDocument } from "../../../API/axios";
import { Spinner } from "react-bootstrap";

function AuditCreateForm(props) {
  const initialFormData = {
    client: "",
    site: "",
    document: [],
  };
  const [formData, setFormData] = useState(initialFormData);
  const [sites, setAllSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [fileNames, setFileNames] = useState([]);

  const GetAllSites = async () => {
    try {
      const Data = await GetClients();
      setAllSites(Data.data.sites);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetAllSites();
  }, []);

  const uniqueClientNames = Array.from(
    new Set(sites.map((item) => item.site_name))
  );
  const uniqueSiteNames = Array.from(
    new Set(
      sites
        .filter((items) => items.site_name === formData.client)
        .map((item) => ({
          site_id: item.site_id,
          site_address: item.site_address,
        }))
    )
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: [...prevFormData[name], ...files],
    }));

    const names = Array.from([...formData[name], ...files]).map(
      (file) => file.name
    );
    setFileNames(names);
  };

  const validateForm = () => {
    const errors = {};
    const allowedTypes = ["application/pdf"];
    if (!formData.client.trim()) {
      errors.client = "Client is required.";
    }
    if (!formData.site.trim()) {
      errors.site = "Site is required.";
    }
    if (formData.document.length === 0) {
      errors.document = "Document is required.";
    } else if (formData.document.length > 3) {
      errors.document = "Maximum of three documents allowed.";
    } else {
      // Check each document type
      formData.document.forEach((document) => {
        if (document.type && !allowedTypes.includes(document.type)) {
          errors.document = "Please upload valid PDF files.";
        }
      });
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    if (validateForm()) {
      try {
        setisButtonLoading(true);

        // Build request body
        const requestBody = {
          site_id: formData.site,
          document: formData.document,
        };

        console.log("reqbody", requestBody);
        // Create request
        await uploadAuditDocument(requestBody)
          .then((response) => {
            if (response.status) {
              props.handleClose();
              props.setShow(!props.show);
              toast.success(" Uploaded successfully");
            } else {
              toast.error("Error uploading document");
            }
          })
          .catch((error) => {
            console.error("Error uploading document:", error);
            toast.error("Error uploading document");
          });
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setisButtonLoading(false);
        setIsDisabled(false);
      }
    } else {
      setisButtonLoading(false);
      setIsDisabled(false);
    }
  };

  const handleRemoveFile = (index) => {
    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData.document];
      newDocuments.splice(index, 1);
      return {
        ...prevFormData,
        document: newDocuments,
      };
    });

    const newFileNames = [...fileNames];
    newFileNames.splice(index, 1);
    setFileNames(newFileNames);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Client:</Form.Label>
              <Form.Select
                name="client"
                value={formData.client}
                onChange={handleInputChange}
              >
                <option value="">Select a Client</option>

                {uniqueClientNames && uniqueClientNames.length > 0 ? (
                  uniqueClientNames.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No clients available
                  </option>
                )}
              </Form.Select>
              {validationErrors.client && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.client}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Site:</Form.Label>
              <Form.Select
                name="site"
                value={formData.site}
                onChange={handleInputChange}
              >
                <option value="">Select a Site</option>

                {uniqueSiteNames && uniqueSiteNames.length > 0 ? (
                  uniqueSiteNames.map((site) => (
                    <option key={site.site_id} value={site.site_id}>
                      {site.site_address}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No sites available
                  </option>
                )}
              </Form.Select>
              {validationErrors.site && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.site}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group>
            <Form.Label>Upload Documents:</Form.Label>
            <Form.Control
              type="file"
              name="document"
              onChange={handleFileChange}
              accept=".pdf "
              multiple
              disabled={formData.document.length >= 3}
            />
            {validationErrors.document && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.document}
              </p>
            )}
            {fileNames.length > 0 && (
              <div style={{ marginTop: "5px" }}>
                <strong>Selected Files:</strong>
                <ul>
                  {fileNames.map((name, index) => (
                    <li key={index} style={{ marginBottom: "5px" }}>
                      {name}
                      <Button
                        variant="danger"
                        size="sm"
                        style={{ marginLeft: "5px", borderRadius: "20px" }}
                        onClick={() => handleRemoveFile(index)}
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Form.Group>
        </Row>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
      <ToastContainer />
    </Container>
  );
}

export default AuditCreateForm;
