import React, { useState, useEffect } from "react";
import { getOrders, updateOrder, sendNotification } from "../../API/axios";
import { Pagination } from "react-bootstrap";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FaEye, FaTrash } from "react-icons/fa";
import DeleteModal from "../Modal/DeleteModal/DeleteModal";

function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkboxLoading, setCheckboxLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const userType = localStorage.getItem("userType");
  const [deleteShow, setdeleteShow] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [filterOrders, setFilterOrders] = useState([]);
  const [filterChanged, setFilterChanged] = useState(false);

  const GetAllOrders = async () => {
    try {
      const response = await getOrders();
      setOrders(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllOrders();
  }, []);

  useEffect(() => {
    GetAllOrders();
  }, [props.filterClient, props.filterSite, props.readFilter]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredOrders = (orders.data || []).filter((order) => {
      const isClientMatch =
        props.filterClient === "" || order.site_name === props.filterClient;
      const isSiteMatch =
        props.filterSite === "" || order.site_address === props.filterSite;

      const isReadStatusMatch =
        props.readFilter === "All" ||
        (props.readFilter === "Read" && order.is_read === 1) ||
        (props.readFilter === "Unread" && order.is_read === 0);

      return isClientMatch && isSiteMatch && isReadStatusMatch;
    });

    setFilterOrders(filteredOrders);
    setFilterChanged(true);
  }, [props.filterClient, props.filterSite, props.readFilter, orders]);

  const totalPages = Math.ceil(filterOrders.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = filterOrders.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const handleRowClick = (id) => {
    const link = `/myorders/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const handleShowDelete = (id) => {
    setDeleteid(id);
    setdeleteShow(true);
  };




  const toggleItemSelection = async (order) => {
    console.log("selected Id", order.id);


    try {
      setCheckboxLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [order.id]: true,
      }));  
      const notificationReqbody = {
          title: 'Your order is confirmed!',
          description: 
            `Order Details:,
            Order ID: ${order.reference_no},
            Product: ${order.product_name},
            Description: ${order.description},
            Quantity: ${order.order_qty}`,
          clientId: order.site_id,
      };
      console.log("clientId", order.site_id)
      const responseNotificationSaved = await sendNotification(notificationReqbody);
      const response = await updateOrder(order.id, 1);

      // const newSelectedItems = selectedItems.includes(order.id)
      //   ? selectedItems.filter((itemId) => itemId !== order.id)
      //   : [...selectedItems, order.id];


      if (responseNotificationSaved.status) {
        console.log("notification sent successfully", responseNotificationSaved.message);
        toast.success("Notification sent successfully!");
      } else {
        console.error("Error sent Notification:", responseNotificationSaved.message);
        toast.error("Error sent Notification");
      }


      if (response.status) {
        toast.success("Order Placed successfully!");
        GetAllOrders();
      } else {
        console.error("Error approving order:", response.message);
        toast.error("Error Placed Order");
      }
     
    } catch (error) {
      console.error("Error approving order:", error);
      toast.error("Error Placed Order");
    } finally {
      setCheckboxLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [order.id]: false,
      }));
    }
  };

  const truncateDescription = (title) => {
    return title.length > 20 ? title.substring(0, 20) + "..." : title;
  };

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  const isClient = localStorage.getItem("userType") === "Client";

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <div>
        <table className="table" style={{ margin: "10px 20px" }}>
          <thead className="thead-light">
            <tr>
              {!isClient && <th scope="col">Client</th>}
              {!isClient && <th scope="col">Site </th>}
              <th scope="col">Reference No</th>
              <th scope="col">Order </th>
              {userType === "SuperAdmin" && <th scope="col">Reply </th>}
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : currentOrders && currentOrders.length === 0 ? (
              <tr>
                <td colSpan="5">There is no data.</td>
              </tr>
            ) : (
              currentOrders.map((order) => (
                <tr
                  key={order.id}
                  style={{
                    fontWeight: !isClient
                      ? order.is_read === 1
                        ? "normal"
                        : "bold"
                      : "normal",
                  }}
                >
                  {!isClient && (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(order.id)}
                    >
                      {order.site_name}
                    </td>
                  )}
                  {!isClient && (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(order.id)}
                      title={order.site_address}
                    >
                      {truncateSiteName(order.site_address)}
                    </td>
                  )}
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(order.id)}
                  >
                    {order.reference_no}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(order.id)}
                  >
                    {truncateDescription(order.product_name)}
                  </td>
                  {userType === "SuperAdmin" && (
                    <td>
                      <input
                        type="checkbox"
                        checked={order.is_approved === 1}
                        disabled={
                          order.is_approved === 1 || checkboxLoading[order.id]
                        }
                        onChange={() => toggleItemSelection(order)}
                        style={{ cursor: "pointer" }}
                      />
                      {checkboxLoading[order.id] && (
                        <Spinner animation="border" size="sm" />
                      )}
                    </td>
                  )}
                  <td>
                    <FaEye
                      className="action-icon"
                      style={{ cursor: "pointer", margin: "8px" }}
                      onClick={() => handleRowClick(order.id)}
                    />
                  
                    {!isClient && (
                      <FaTrash
                        className="delete-icon"
                        style={{ cursor: "pointer", margin: "10px" }}
                        onClick={() => handleShowDelete(order.id)}
                      />
                    )}

                  </td>
                </tr>
              ))
            )}
          </tbody>
          <DeleteModal
            show={deleteShow}
            title={"order-delete"}
            id={deleteid}
            setdeleteShow={setdeleteShow}
            GetAllOrders={GetAllOrders}
          />
        </table>
      </div>
      {currentOrders.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}



export default Orders;
