import React, { useState, useEffect } from "react";
import { getHelpDetailsById } from "../../API/axios";
import { useParams } from "react-router-dom";
import "./HelpViewDetail.css";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

function HelpViewDetail(props) {
  const { id } = useParams();
  const [selectedHelp, setSelectedHelp] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchHelpDetails() {
      try {
        const response = await getHelpDetailsById(id);
        setSelectedHelp(response.helpDocs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
        setLoading(false);
      }
    }

    fetchHelpDetails();
  }, [id]);

  const renderDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      const tooltipContent = (
        <div >
          <strong></strong> {description}
        </div>
      );
  
      const tooltip = <Tooltip id="tooltip">{tooltipContent}</Tooltip>;
  
      return (
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span style={{ cursor: "pointer" }}>
            {description.substring(0, maxLength)}....
          </span>
        </OverlayTrigger>
      );
    } else {
      return description;
    }
  };
  
  return (
    <>
      <div className="help-wrapper">
        {loading ? (
          <p>Loading...</p>
        ) : selectedHelp ? (
          <table className="table">
            <tbody>
              <React.Fragment>
                <tr>
                  <td>
                    <strong>Module:</strong>
                  </td>
                  <td>{selectedHelp.module}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Title:</strong>
                  </td>
                  <td>{selectedHelp.heading}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Description:</strong>
                  </td>
                  <td>{renderDescription(selectedHelp.description, 55)}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Uploaded Video:</strong>
                  </td>
                  {/* <td>{<iframe width="500" height="300" src={selectedHelp.url}></iframe>}</td> */}
                  <>
                    <video width="500" height="300" controls>
                      <source src={selectedHelp.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </>
                </tr>
              </React.Fragment>
            </tbody>
          </table>
        ) : (
          <p>No data available for the selected order.</p>
        )}
      </div>
    </>
  );
}

export default HelpViewDetail;
