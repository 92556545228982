import React, { useEffect, useState } from "react";
import "../FilterSite/FilterSite.css";
import { GetClients } from "../../API/axios";

function FilterAssignTasks(props) {
  const [cleaners, setClients] = useState([]);

  useEffect(() => {
    const GetAllClients = async () => {
      try {
        const Data = await GetClients();
        console.log(Data.data.sites);
        setClients(Data.data.sites);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllClients();
  }, []);

  const uniqueClientNames = Array.from(
    new Set(cleaners.map((item) => item.site_name))
  );
  const uniqueSiteNames = Array.from(
    new Set(
      cleaners
        .filter((items) => items.site_name === props.filterClient)
        .map((item) => item.site_address)
    )
  );
  console.log("Filtersite: " + props.filterClient);
  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterClient(localStorage.getItem("user"));
  }
  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterClient(e.target.value);
                props.setFilterSite("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {uniqueClientNames ? (
                uniqueClientNames.map((name, index) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {uniqueSiteNames ? (
                uniqueSiteNames.map((name) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              const selectedValue = e.target.value;

              if (selectedValue === "") {
                props.setFilterPriority("");
              } else {
                const intValue = parseInt(selectedValue, 10);

                props.setFilterPriority(intValue);
              }
            }}
          >
            <option selected value={""}>
              Select Priority
            </option>
            <option value="1">High</option>
            <option value="2">Medium</option>
            <option value="3">Low</option>
          </select>
        </div>
      </div>
    </div>
  );
}
export default FilterAssignTasks;
