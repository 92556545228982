import React, { createContext, useState } from 'react';

export const PDFContext = createContext();

export const PDFProvider = ({ children }) => {
  const [pdfBlob, setPdfBlob] = useState(null);

  return (
    <PDFContext.Provider value={{ pdfBlob, setPdfBlob }}>
      {children}
    </PDFContext.Provider>
  );
};
