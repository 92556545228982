import React, { useEffect, useState } from "react";
import { FaPen, FaTrash, FaEye } from "react-icons/fa";
import DeleteModal from "../Modal/DeleteModal/DeleteModal";
import CleanerModal from "../Modal/CleanerModal";
import { GetCleaners } from "../../API/axios";
import { Pagination } from "react-bootstrap";

function Table(props) {
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);

  const handleShowView = (item) => {
    setItem(item);
    setShow(true);
  };
  const [cleaners, setCleaners] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const isClient = localStorage.getItem("userType") === "Client";
  const [item, setItem] = useState([]);
  const [user, setUser] = useState(null);

  const handleShowDelete = (id) => {
    setUser(id);
    props.setdeleteShow(true);
  };

  const GetAllCleaners = async () => {
    try {
      const Data = await GetCleaners();
      setCleaners(Data.data.result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error("Error response:", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllCleaners();
  }, [props.show, show]);

  useEffect(() => {
    GetAllCleaners();
  }, [props.filterName, props.filterSite, props.filterSiteAdd]);

  useEffect(() => {
    const filteredData = cleaners.filter((item) => {
      const nameMatch =
        props.filterName === "" || item.f_name === props.filterName;
      const lastnameMatch =
        props.filterLastName === "" || item.l_name === props.filterLastName;
      const siteMatch =
        props.filterSite === "" ||
        item.site_name
          ?.split(",")
          .map((site) => site.trim())
          .includes(props.filterSite.trim());
      const addressMatch =
        props.filterSiteAdd === "" ||
        item.site_address
          ?.split(",")
          .map((site) => site.trim())
          .includes(props.filterSiteAdd.trim());
      return nameMatch && lastnameMatch && siteMatch && addressMatch;
    });

    setFilteredCleaners(filteredData);
    setFilterChanged(true);
  }, [
    props.filterName,
    props.filterSite,
    props.filterSiteAdd,
    props.filterLastName,
    cleaners,
  ]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  const handleRowClick = (id) => {
    const link = `/mycleaner/cleanerdetails/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const limitClientNames = (clientNames) => {
    if (clientNames === null) {
      return "";
    }
    const MAX_NAMES = 5;
    const namesArray = clientNames.split(",").map((name) => name.trim());

    if (namesArray.length <= MAX_NAMES) {
      return clientNames;
    }

    const limitedNames = namesArray.slice(0, MAX_NAMES).join(", ");
    return `${limitedNames} ...`;
  };

  const totalPages = Math.ceil(filteredCleaners.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentCleaners = filteredCleaners.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col" style={{ width: "100px" }}>
              Emp ID
            </th>
            <th scope="col" style={{ width: "200px", minWidth: "200px" }}>
              Cleaner
            </th>
            <th scope="col" style={{ width: "250px" }}>
              Email
            </th>
            {!isClient && (
              <th
                scope="col"
                style={{
                  width: "250px",
                  minWidth: "250px",
                }}
              >
                Clients
              </th>
            )}
            {/* {!isClient && <th scope="col">Site</th>} */}
            {!isClient && (
              <th scope="col" style={{ width: "120px", minWidth: "120px" }}>
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentCleaners.length > 0 ? (
            currentCleaners.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.user_id)}
                  >
                    {item.emp_no}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.user_id)}
                  >
                    {item.f_name} {item.l_name}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.user_id)}
                  >
                    {item.email}
                  </td>
                  {!isClient && (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(item.user_id)}
                      title={item.site_name ? item.site_name : ""}
                    >
                      {item.site_name
                        ? limitClientNames(item.site_name)
                        : "Currently have't a client"}
                    </td>
                  )}
                  <td>
                    <FaEye
                      className="action-icon"
                      style={{ cursor: "pointer", margin: "8px" }}
                      onClick={() => handleRowClick(item.user_id)}
                    />
                    {!isClient && (
                      <FaPen
                        className="action-icon"
                        style={{ cursor: "pointer", margin: "8px" }}
                        onClick={() => handleShowView(item)}
                      />
                    )}
                    {!isClient && (
                      <FaTrash
                        className="delete-icon"
                        style={{ cursor: "pointer", margin: "8px" }}
                        onClick={() => handleShowDelete(item.user_id)}
                      />
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>

      {currentCleaners.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}

      <CleanerModal
        show={show}
        setShow={setShow}
        GetAllCleaners={GetAllCleaners}
        item={item}
        Title="update"
      />
      <DeleteModal
        show={props.deleteShow}
        title={"mycleaner"}
        setAreas={GetAllCleaners}
        id={user}
        setdeleteShow={props.setdeleteShow}
      />
    </div>
  );
}

export default Table;
