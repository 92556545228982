import { firebase, analytics, firestore } from "../API/firebase";
import { collection, getDocs , addDoc ,getDoc ,updateDoc,doc,deleteDoc} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getStorage } from "firebase/storage";

const storage = getStorage();

export const getData = async () => {
  const dataCollection = collection(firestore, "chat_rooms");
  const dataSnapshot = await getDocs(dataCollection);

  const data = [];
  dataSnapshot.forEach((doc) => {
    data.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return data;
};

export const getMessages = async (chatId) => {
  const dataCollection = collection(firestore, `chat_rooms/${chatId}/messages`);
  const dataSnapshot = await getDocs(dataCollection);

  const data = [];
  dataSnapshot.forEach((doc) => {
    data.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return data;
};

export const uploadImageToStorage = async (file) => {
  const storageRef = ref(storage, `Comm_Chats/`);
  const snapshot = await uploadBytes(storageRef, file);

  const downloadURL = await getDownloadURL(snapshot.ref);

  console.log("Image uploaded to Firebase Storage.");
  return downloadURL;
};

export const sendMessages = async (chatId, messageData) => {
  const messagesCollection = collection(firestore, `chat_rooms/${chatId}/messages`);

  const docRef = await addDoc(messagesCollection, messageData);
  return docRef.id;
};

export const createChat = async (messageData) => {
  const messagesCollection = collection(firestore, `chat_rooms/`);
  const docRef = await addDoc(messagesCollection, messageData);
  const docSnapshot = await getDoc(docRef);
  const insertedData = docSnapshot.data();

  const data = {
    id: docSnapshot.id,
    ...insertedData,
  };

  return data;
};

export const updateChat = async (chatId) => {
  const chatDocRef = doc(firestore, `chat_rooms/${chatId}`);

  await updateDoc(chatDocRef, {
    ['active']: false,
  });

  const docSnapshot = await getDoc(chatDocRef);
  const updatedData = docSnapshot.data();

  return updatedData;
};

export const deleteChat = async (chatId) => {
  try {
    const messageRef = doc(firestore, `chat_rooms/${chatId}`);
    
    await deleteDoc(messageRef);

    console.log('Message deleted successfully',messageRef);
  } catch (error) {
    console.error('Error deleting message:', error.message);
    throw error;
  }
};

export const updateReadMessageCount = async (chatId,senderID1) =>{
  const chatDocRef = doc(firestore, `chat_rooms/${chatId}`);

  try {
    const dataSnapshot = await getDoc(chatDocRef);
    if (dataSnapshot.exists()) {
      const count = dataSnapshot.data().unReadMessageCount+1;
      await updateDoc(chatDocRef, {
        unReadMessageCount : count,
        senderID:senderID1
      });
    } else {
      console.log("Document does not exist");
    }
  } catch (error) {
    console.log(error);
  }
}

export const setReadCountToZero = async (chatId,senderID) =>{
  const chatDocRef = doc(firestore, `chat_rooms/${chatId}`);

  try {
    const dataSnapshot = await getDoc(chatDocRef);
    const id = dataSnapshot.data().senderID;
    if (dataSnapshot.exists() && senderID != id) {
      await updateDoc(chatDocRef, {
        unReadMessageCount : 0,
      });
    } else {
      console.log("Document does not exist");
    }
  } catch (error) {
    console.log(error);
  }
}