import React, { useState, useEffect, useRef } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import PRIMARY from "../../Theme/palette";
import { addToolbox } from "../../API/axios";
import { toast } from "react-toastify";
import { GetClients } from "../../API/axios";
import { Spinner } from "react-bootstrap";

function NewToolBoxDocument(props) {
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [formData, setFormData] = useState({});
  const [sitesData, setSitesData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [sites, setAllSites] = useState([]);

  useState(false);
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    if (files && files.length > 0) {
      const selectedFile = files[0];

      setFormData({
        ...formData,
        [name]: selectedFile,
      });
    } else {
      setFormData({
        ...formData,
        [name]: formData.document,
      });
    }

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const GetAllSites = async () => {
    try {
      const Data = await GetClients();
      setAllSites(Data.data.sites);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setFormData({
      selectedClient: "",
      selectedSite: "",
      profilePicture: "",
    });
    GetAllSites();
  }, []);

  const uniqueClientNames = Array.from(
    new Set(sites.map((item) => item.site_name))
  );

  const uniqueSiteNames = Array.from(
    new Set(
      sites
        .filter((items) => items.site_name === formData.selectedClient)
        .map((item) => ({
          site_id: item.site_id,
          site_address: item.site_address,
        }))
    )
  );

  const validateForm = async () => {
    const errors = {};

    if (!formData.selectedClient.trim()) {
      errors.selectedClient = "Client Name is required.";
    }
    if (!formData.selectedSite.trim()) {
      errors.selectedSite = "Site Name is required.";
    }

    if (!formData.document) {
      errors.document = "PDF file is required";
    } else if (!formData.document.type.startsWith("application/pdf")) {
      errors.document = "Please upload a valid pdf file";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);
    try {
      const isValid = await validateForm();

      if (isValid) {
        setisButtonLoading(true);
        const createRequestBody = {
          document:
            typeof formData.document === "string" ? "null" : formData.document,
          doc_name: "xxx",
          site_id: formData.selectedSite,
        };

        addToolbox(createRequestBody)
          .then((response) => {
            console.log("response : ", response);
            if (response.status) {
              props.handleCloseModal();
              props.setShowModal(!props.show);
              toast.success("Document Upload successfully!");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.message);
            setisButtonLoading(false);
            setIsDisabled(false);
          });
      } else {
        setisButtonLoading(false);
        setIsDisabled(false);
      }
    } catch (error) {
      console.log(error);
      // Handle validation error or submission error
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Client:</Form.Label>
              <Form.Select
                name="selectedClient"
                value={formData.selectedClient}
                onChange={handleInputChange}
              >
                <option value="">Select a Client</option>
                {uniqueClientNames && uniqueClientNames.length > 0 ? (
                  uniqueClientNames.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))
                ) : (
                  <option selected>No Client to Select</option>
                )}
              </Form.Select>

              {validationErrors.selectedClient && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.selectedClient}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Site:</Form.Label>
              <Form.Select
                name="selectedSite"
                value={formData.selectedSite}
                onChange={handleInputChange}
              >
                <option selected>Select Site</option>
                {uniqueSiteNames && uniqueSiteNames.length > 0 ? (
                  uniqueSiteNames.map((site) => (
                    <option key={site.site_id} value={site.site_id}>
                      {site.site_address}
                    </option>
                  ))
                ) : (
                  <option selected>No Site to Select</option>
                )}
              </Form.Select>
              {validationErrors.selectedSite && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.selectedSite}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Upload File</Form.Label>
              <br />
              <input
                type="file"
                name="document"
                accept=".pdf"
                onChange={handleFileChange}
                style={{ marginBottom: "10px" }}
                isInvalid={!!validationErrors.document}
              />
              {formData.document && (
                <p
                  style={{
                    marginTop: "5px",
                    marginBottom: "0",
                    fontWeight: "bold",
                  }}
                >
                  Selected Document: {formData.document.name}
                </p>
              )}
              {validationErrors.document && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.document}
                </p>
              )}
              {/* {selectedFile && (
                <div>
                  {selectedFile.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                  ) : (
                    <div>
                      <object
                        data={URL.createObjectURL(selectedFile)}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      ></object>
                    </div>
                  )}
                </div>
              )} */}
              <Form.Control.Feedback type="invalid">
                {validationErrors.document}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default NewToolBoxDocument;
