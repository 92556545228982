import React, { useEffect, useState } from "react";
import "./FilterSite.css";
import { GetClients } from "../../API/axios";

function FilterSite(props) {
  const [clients, setClients] = useState([]);
  const [mySite, setMySite] = useState("");

  //get all clients
  useEffect(() => {
    const GetAllClients = async () => {
      try {
        const Data = await GetClients();
        setClients(Data.data.sites);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllClients();
  }, []);

  //client names
  const uniqueClientNames = Array.from(
    new Set(clients.map((item) => item.site_name))
  );

  //filter the site address according to the relevent site name
  const uniqueSiteAddress = Array.from(
    new Set(
      clients
        .filter((items) => items.site_name === props.filterClient)
        .map((item) => item.site_address)
    )
  );

  const isClient = localStorage.getItem("userType") === "Client";
  useEffect(() => {
    if (isClient) {
      props.setFilterClient(localStorage.getItem("user"));
      props.setFilterSite(localStorage.getItem("Location"));
    }
  }, [isClient, props.setFilterClient, props.setFilterSite]);
  
  //get the site id
  const onSelectSetSiteID = (site) => {
    if(site===""){
      props.setFilterSite("");
    }else{
      setMySite(site);
      const siteID = clients.find(
        (item) => item.site_address === site
      );
      if (siteID) {
        props.setFilterSite(siteID.site_id);
        if (!isClient) {
          props.setFilterClient(siteID.site_name);
        }
      }
    }
  }
  

  return (
    <div className={props.page === 'Communication' ? "comm-filter-wrapper p-0" : props.page === 'AnotherPage' ? "another-page-filter-wrapper" : "filter-wrapper p-3"}>
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterClient(e.target.value);
                props.setFilterSite("");
              }}
              value={props.filterClient}
            >
              <option value={""}>
                {props.page==='Communication' ? 'Client' :  props.page==='AnotherPage' ? 'Client' : 'Select Client'}
              </option>
              {clients.length > 0 ? (
                uniqueClientNames.map((client, index) => {
                  return <option value={client}>{client}</option>;
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                // {props.page==='Communication' ? onSelectSetSiteID(e.target.value) : props.page==='AnotherPage' ? onSelectSetSiteID(e.target.value) :props.setFilterSite(e.target.value)}
                onSelectSetSiteID(e.target.value)
              }}
              value={mySite}
            >
              <option selected value={""}>
              {props.page==='Communication' ? 'Site' : props.page==='AnotherPage' ? 'Site' : 'Select Site'}
              </option>
              {clients.length > 0 ? (
                uniqueSiteAddress.map((name, index) => {
                  return <option value={name}>{name}</option>;
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterSite;
