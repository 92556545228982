import React, { useState } from "react";
import "./EndChatModal.css";
import { updateChat } from "../../../API/fireStoreService";

const EndChatModal = ({ isOpen, onClose ,selectedChat, setSelectedChat,fetchData }) => {
  const [chatName, setChatName] = useState("");

  const id=localStorage.getItem('ID');

  const handleEndChat = async() => {
      const result =await updateChat(selectedChat.id);
      console.log(result);
      fetchData();
      setSelectedChat([]);
      onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
          <h4>End Chat</h4>
            <div className="modal-header">
              <h6>Are you sure you want to end the chat ? </h6>
            </div>
            <div className="end-chat-modal-footer">
              <button className="end-chat-modal-button" onClick={onClose}>
                No
              </button>
              <button className="end-chat-modal-button" onClick={handleEndChat}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EndChatModal;
