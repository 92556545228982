import React, { useState } from 'react';
import Orders from '../../Componants/Orders/Orders';
import Filter from '../../Componants/Filters/Order/Filter';

function Myorders () {
    const [filterClient, setFilterClient] = useState("");
    const [filterSite, setFilterSite] = useState("");
    const [readFilter, setReadFilter] = useState("All");
    
    
    return (
       <div> 
        <div className="cleaner-topic">
        <p>Orders</p>
        </div>
       <Filter 
       setFilterClient={setFilterClient}
       setFilterSite={setFilterSite}
       filterClient={filterClient}
       filterSite={filterSite}
       setReadFilter={setReadFilter}
       />  
        <Orders 
        filterClient={filterClient}
        filterSite={filterSite}
        readFilter={readFilter}
        />
        </div>
    )
}

export default Myorders;