import React, { useState } from "react";
import "./ChangedPassword.css";
import { FaEye, FaEyeSlash} from "react-icons/fa";
import { changeSuperAdminPwd } from "../../../API/axios";
import { toast } from "react-toastify";

function ChangedPassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    validateOldPassword();
  };

  const validateOldPassword = () => {
    if (!oldPassword) {
      setOldPasswordError("Old Password is required.");
    } else {
      setOldPasswordError("");
    }
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    validatePassword();
  };

  const validatePassword = () => {
    // Password validation: at least one upper case, one lower case, and one number
    if (!newPassword) {
      setPasswordError("New Password is required.");
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])/.test(newPassword)) {
      setPasswordError(
        "Password must be at least 8 characters long should contain at least one uppercase letter, one lowercase letter,one number and one symbol."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    validateConfirmPassword();
  };

  const validateConfirmPassword = () => {
    if (!confirmNewPassword) {
      setConfirmPasswordError("Confirm Password is required.");
    } else {
      setConfirmPasswordError("");
    }
  };

  // const handleSave = async (event) => {
  //   event.preventDefault();
  //   validateOldPassword();
  //   validatePassword();
  //   validateConfirmPassword();
  //   if (oldPassword.length > 0 && newPassword.length > 0 && confirmNewPassword.length > 0 && newPassword !== confirmNewPassword) {
  //     setError("New password and Confirm Password do not match");
  //   } else if (oldPassword.length > 0 && newPassword.length > 0 && confirmNewPassword.length > 0 && oldPassword === newPassword) {
  //     setError("Old password and New Password are same");
  //   } else {
  //     const adminId = localStorage.getItem('ID');
  //     const requestBody = {
  //       oldPassword: oldPassword,
  //       newPassword: newPassword,
  //     };
  //     changeSuperAdminPwd(adminId, requestBody)
  //       .then((response) => {
  //         if (response.status === false) {
  //           toast.error(response.message);
  //         }
  //         else if(response.status===true){
  //           toast.success(response.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         toast.error(error.response.data.message);
  //       });
  //       setError("");
  //   }
  // };
  const handleSave = async (event) => {
    event.preventDefault();
    validateOldPassword();
    validatePassword();
    validateConfirmPassword();

    if (oldPassword && newPassword && confirmNewPassword && newPassword !== confirmNewPassword) {
      setError("New password and Confirm Password do not match");
    }else if (oldPassword && newPassword && confirmNewPassword && oldPassword === newPassword) {
      setError("Old password and New Password are same");
    } else if(oldPassword && newPassword && confirmNewPassword) {
      const adminId = localStorage.getItem('ID');
      const requestBody = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      try {
        const response = await changeSuperAdminPwd(adminId, requestBody);
        if (response.status === false) {
          toast.error(response.message);
        } else if (response.status === true) {
          toast.success(response.message);
        }
      } catch (error) {
        console.error(error);
        toast.error(error.response ? error.response.data.message : "Failed to change password.");
      }
      setError("");
    }
  };
  
  return (
    <div className="containerpwd">
      <div>
        <h2 className="pw-title">Change Password Here</h2> 
      </div>
        <form onSubmit={handleSave}>
          <div>
            <div className="input-groupr">
              <label htmlFor="oldPassword">Old Password<span class="text-danger">*</span></label>
              <div className="inputdiv">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="oldPassword"
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                  onBlur={validateOldPassword}
                  className="inputpwd"
                />
                {oldPassword ? (
                  <span
                    className="password-toggle-icon-login"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {oldPasswordError && (
                <div className="text-danger">{oldPasswordError}</div>
              )}
            </div>
          </div>

          <div>
            <div className="input-groupr">
              <label htmlFor="newPassword">New Password<span class="text-danger">*</span></label>
              <div className="inputdiv">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  id="newPassword"
                  value={newPassword}
                  onChange={handlePasswordChange}
                  onBlur={validatePassword}
                  className="inputpwd"
                />
                {newPassword ? (
                  <span
                    className="password-toggle-icon-login"
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  >
                    {newPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {passwordError && (
                <div className="text-danger">{passwordError}</div>
              )}
            </div>
          </div>

          <div>
            <div className="input-groupr">
              <label htmlFor="confirmNewPassword">Confirm New Password<span class="text-danger">*</span></label>
              <div className="inputdiv">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={handleConfirmPasswordChange}
                  onBlur={validateConfirmPassword}
                  className="inputpwd"
                />
                {confirmNewPassword ? (
                  <span
                    className="password-toggle-icon-login"
                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  >
                    {confirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {confirmPasswordError && (
                <div className="text-danger">{confirmPasswordError}</div>
              )}
            </div>
          </div>

          {error && <div className="text-danger">{error}</div>}

          <div className="btndiv">
            <button className="btn-save" type="submit">
              Reset Password
            </button>
          </div>
        </form>
    </div>
  );
}

export default ChangedPassword;
