import React, { useEffect, useState } from "react";
import "./Filter.css";

function FilterHelp(props) {
  return (
    <div className="filter-wrapper-help p-3">
      <div className="row">
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFilterSite(e.target.value);
            }}
          >
            <option value="">Select a Module</option>
            <option value="Communication">Communication</option>
            <option value="Site manual">Site Manual</option>
            <option value="Recommendation">Recommendation</option>
            <option value="Incident Report">Incident Report</option>
            <option value="Site information">Site information</option>
            <option value="Tool box talk">Tool box talk</option>
            <option value="Stock">Stock</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default FilterHelp;
