import React from 'react';
import Layout from "../../Componants/Layout/Layout";
import FilterAssignTasks from "../../Componants/FilterAssignTasks/FilterAssignTasks";
import AssignTaskHistoryTable from '../../Componants/AssignTasksTable/AssignTaskHistoryTable';


function AssignTaskHistory() {
  
  return (
    <>
      <div className="cleaner-topic">
        <p>Tasks History</p>
      </div>
      <div>
        <FilterAssignTasks />
        <AssignTaskHistoryTable />
      </div>
    </>
  );
}

export default AssignTaskHistory;
