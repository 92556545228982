import React, { useState, useEffect } from "react";
import "./ToolboxReader.css";
import { getDocumentDetailsbyID, getReaderDetailsbyID } from "../../API/axios";

function SiteManualReader(props) {
  const readersid = props.siteToolboxReaders;
  const [Documentname, setDocumentname] = useState("");
  const [Documentpath, setDocumentpath] = useState("");
  const [DocumentSitename, setDocumentSitename] = useState("");
  const [DocumentSiteadd, setDocumentSiteadd] = useState("");
  const [reader, setReader] = useState("");
  const [loading, setLoading] = useState(true);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    const getDoceDetails = () => {
      getDocumentDetailsbyID(readersid)
        .then((response) => {
          const docData = response.data.data[0];
          console.log("readers new:", response.data.data[0]);
          setDocumentname(docData.document_name);
          setDocumentpath(docData.document_link);
          setDocumentSitename(docData.site_name);
          setDocumentSiteadd(docData.site_address);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getReaderDetailsbyID(readersid)
      .then((response) => {
        setReader(response.data.data);
        console.log("ReaderID", response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    getDoceDetails();
  }, [readersid]);

  const samplePdfUrl =
    "https://firebasestorage.googleapis.com/v0/b/new-qr-api-be.appspot.com/o/files%2FCharith%20De%20Mel_SE.pdf%20%20%20%20%20%20%20Wed%20Dec%2006%202023%2011%3A00%3A17%20GMT%2B0000%20(Coordinated%20Universal%20Time)?alt=media&token=50d426ea-fa8b-4d96-ab02-3dfb70cc7910";

  return (
    <>
      <div className="manual-wrapper">
        <div className="manual">
          <div className="pdf-view">
            <iframe
              title="Sample PDF"
              src={Documentpath}
              width="100%"
              height="500"
            />
          </div>
          <div className="readers">
            <div className="cleaner-topic">
              <h5>Readers List</h5>
            </div>
            <br></br>
            <div className="readerDetail">
              {/* <div>
                <span>
                  {" "}
                  <p>Site Name: {DocumentSitename}</p>
                </span>

                <span>
                  {" "}
                  <p>Site Address: {DocumentSiteadd}</p>
                </span>
                <span>
                  {" "}
                  <p>Document Name: {Documentname}</p>
                </span>
              </div> */}

              <table className="table">
                <thead className="thead-light">
                  <tr>
                  <th scope="col">Emp No</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Signed Date</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {loading ? (
                      <tr>
                        <td colSpan="5">Loading...</td>
                      </tr>
                    ) : reader.length > 0 ? (
                      reader.map((item) => (
                        <tr key={item.id}>
                          <td>{item.emp_no}</td>
                          <td>{item.user_name}</td>
                          <td>{item.signed_at}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No data</td>
                      </tr>
                    )}
                  </React.Fragment>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SiteManualReader;
