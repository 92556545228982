import React from "react";
import Modal from "react-modal";

const ChatPopupModal = ({ chatData, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Chat Popup"
    >
      <div className="chat-popup">
        <div className="chat-header">
          <h3>Chat Room {chatData.chatId}</h3>
          <button onClick={onRequestClose}>Close</button>
        </div>
      </div>
    </Modal>
  );
};

export default ChatPopupModal;
