import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import PRIMARY from "../../../Theme/palette";
import { updateCleanerDocument } from "../../../API/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

function CleanerDocumentUpdateForm(props) {
  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialFormData = {
    document: props.item.document_name,
    startDate: props.item.start_date,
    endDate: props.item.end_date,
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'startDate') {

      setFormData((prevData) => ({
        ...prevData,
        endDate: '',
      }));
    }
  };

//   const handleFileChange = (event) => {
//     const { name, files } = event.target;
//     const selectedFile = files ? files[0] : null;
//     setFormData({
//       ...formData,
//       [name]: selectedFile,
//     });
//   };

  // Validation logic
  const validateForm = () => {
    const errors = {};
    const allowedTypes = ["application/pdf"];

    if (!formData.startDate.trim()) {
      errors.startDate = "Start date is required.";
    }

    if (!formData.endDate.trim()) {
      errors.endDate = "End date is required.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);
    console.log(formData);

    if (validateForm()) {
      try {
        setisButtonLoading(true);

        const updateRequestBody = {
          start_date: formData.startDate,
          end_date: formData.endDate,
        };

        // Create request
        await updateCleanerDocument(props.item.document_id, updateRequestBody)
          .then((response) => {
            console.log(response);
            if (response.status) {
              props.handleClose();
              props.setUpdateShow(!props.updateShow);
              props.GetDocumentsByCleanerId();
              toast.success("Document updated successfully!");
              setisButtonLoading(false);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.response.data.message);
            setisButtonLoading(false);
            setIsDisabled(false);
          });
      } catch (error) {
        console.error("Error updating document:", error);
      } finally {
        setisButtonLoading(false);
        setIsDisabled(false);
      }
    } else {
      setisButtonLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group>
            <Form.Label>Document:</Form.Label>
            <Form.Control
              type="text"
              name="document"
              value={formData.document}
              readOnly
            />
            {/* {validationErrors.document && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.document}
              </p>
            )} */}
          </Form.Group>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Start Date:</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleInputChange}
              />
              {validationErrors.startDate && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.startDate}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>End Date:</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleInputChange}
                min={
                  formData.startDate &&
                  (() => {
                    const nextDay = new Date(formData.startDate);
                    nextDay.setDate(nextDay.getDate() + 1);
                    return nextDay.toISOString().split('T')[0];
                  })()
                }
              />
              {validationErrors.endDate && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.endDate}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default CleanerDocumentUpdateForm;
