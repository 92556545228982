import Layout from "../../Componants/Layout/Layout";
import FilterDailyRecord from "../../Componants/FilterDailyRecord/FilterDailyRecord";
import DailyRecordTable from "../../Componants/DailyRecordTable/DailyRecordTable";
import { useState } from "react";

function DailyRecords() {
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterDate, setFilterDate] = useState("");

  return (
    <>
      <div className="cleaner-topic">
        <p>Cleaners Daily Records</p>
      </div>
      <div>
        <FilterDailyRecord
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          setFilterDate={setFilterDate}
          filterSite={filterSite}
          filterClient={filterClient}
        />
        <DailyRecordTable
          filterClient={filterClient}
          filterSite={filterSite}
          filterDate={filterDate}
        />
      </div>
    </>
  );
}

export default DailyRecords;
