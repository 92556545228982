import React, { useState } from "react";
import "./Operations.css";
import Filter from "../../Componants/FilterOperation/Filter";
import Table from "../../Componants/ClientTank/livetable";
import PRIMARY from "../../Theme/palette";
import CleanerModal from "../../Componants/Modal/CleanerModal";

function Mycleaner() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterFname, setFilterFname] = useState("");
  const [filterLname, setFilterLname] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  return (
    <>
      <div className="cleaner-topic">
        <p>Real Time Data </p>
      </div>
      <div>
        <Filter 
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          setFilterFname={setFilterFname}
          setFilterLname={setFilterLname}
          setFilterStartDate={setFilterStartDate}
          setFilterEndDate={setFilterEndDate}
          filterClient={filterClient}
          filterSite={filterSite}
          filterFname={filterFname}
        />
        <Table filterClient={filterClient} show={show} filterSite={filterSite} filterFname={filterFname} filterLname={filterLname} filterStartDate={filterStartDate} filterEndDate={filterEndDate}/>
      </div>
    </>
  );
}

export default Mycleaner;
