import React, { useState, useEffect } from "react";
import "./ToolBoxTalk.css";
import PRIMARY from "../../Theme/palette";
import FilterTool from "../../Componants/Filters/ToolBoxTalk/Filter";
import TableTool from "../../Componants/Tables/TableTool/Table";
import { Modal } from "react-bootstrap";
import NewToolBoxDocument from "./NewToolBoxDocument";
import { Form } from "react-bootstrap";

function ToolBoxTalk() {
  const [show, setShow] = useState(false);
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [showModal, setShowModal] = useState(false);
  const userType = localStorage.getItem("userType");

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="cleaner-topic">
        <p>Tool Box Talk </p>
        {userType === "SuperAdmin" && (
          <button
            onClick={() => setShowModal(true)}
            className="add-button"
            // style={{
            //   border: "none",
            //   backgroundColor: PRIMARY.darkGray,
            //   padding: "0 20px",
            //   color: PRIMARY.white,
            //   fontWeight: "500",
            // }}
          >
            Add New +
          </button>
        )}
      </div>
      <div>
        <FilterTool
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          filterSite={filterSite}
          filterClient={filterClient}
        />
        <TableTool
          filterClient={filterClient}
          filterSite={filterSite}
          show={showModal}
          setShowModal={setShowModal}
        />
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="modal-lg"
        Title="create"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <NewToolBoxDocument
              show={showModal}
              setShowModal={setShowModal}
              Title="create"
              handleCloseModal={handleCloseModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default ToolBoxTalk;
