import React, { useState } from "react";
import "./Recommendation.css";
import PendingView from "./PendingView";
import AcceptView from "./AcceptView";
import DenyView from "./DenyView";

function Recommendation(props) {
  const recommendation = props.recommendation && props.recommendation[0];
  const approvalDetails = props.approvalDetails;
  
  if (!recommendation) {
    return <p>Loading...</p>;
  }

  const status = recommendation.status;

  if (status === 'pending') {
    return <PendingView recommendation={recommendation} setNewRecommendation={props.setNewRecommendation}/>;
  } else if (status === 'approved') {
    return <AcceptView recommendation={recommendation} approvalDetails={approvalDetails}/>;
  } else if (status === 'denied') {
    return <DenyView recommendation={recommendation} setNewRecommendation={props.setNewRecommendation}/>;
  } else {
    return <p>Unknown status</p>;
  }
}

export default Recommendation;
