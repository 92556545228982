import React, { useState } from "react";
import "./ChatList.css";

import Layout from "../../Componants/Layout/Layout";
import Table from "../../Componants/Table/Table";
import PRIMARY from "../../Theme/palette";
import FilterSite from "../../Componants/FilterSite/FilterSite";
import CleanerModal from "../../Componants/Modal/CleanerModal";
import FilterCom from "../../Componants/Filters/Communication/Filter";
import TableCom from "../../Componants/Tables/TableCom/Table";
import ChatsTable from "../../Componants/Tables/TableCom/ChatsTable";

function ChatList() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filterName, setFilterName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterSiteAdd, setFilterSiteAdd] = useState("");

  return (
    <>
      <div className="chat-list-topic">
        <p>Active Chat List</p>
        <button
        //   onClick={handleShow}
          className="btn btn-primary"
          style={{
            border: "none",
            backgroundColor: PRIMARY.darkGray,
            padding: "0 20px",
            color: PRIMARY.white,
            fontWeight: "500",
          }}
        >
         Chat History
        </button>
      </div>
      <div>
        <FilterCom
          setFilterName={setFilterName}
          setFilterSite={setFilterSite}
          setFilterSiteAdd={setFilterSiteAdd}
        />
        <ChatsTable
          filterName={filterName}
          filterSite={filterSite}
          filterSiteAdd={filterSiteAdd}
        />
        <CleanerModal show={show} setShow={setShow} Title="create" />
      </div>
    </>
  );
}

export default ChatList;
