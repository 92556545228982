import React, { useState, useEffect } from "react";
import { GetallToolbox } from "../../../API/axios";
import { Pagination } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

function TableTool(props) {
  const [toolBoxTalk, setToolBoxTalk] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredClients, setFilteredClients] = useState([]);
  const itemsPerPage = 10;
  const [filterChanged, setFilterChanged] = useState(false);

  const GetallTool = async () => {
    try {
      const response = await GetallToolbox();
      setToolBoxTalk(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetallTool();
  }, []);

  useEffect(() => {
    GetallTool();
  }, [props.show, props.filterClient, props.filterSite]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  const handleRowClick = (id) => {
    const link = `/toolboxtalk-documents/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  useEffect(() => {
    const filteredData = toolBoxTalk.filter((item) => {
      const clientMatch =
        props.filterClient === "" || item.site_name === props.filterClient;
      const siteMatch =
        props.filterSite === "" || item.site_address === props.filterSite;

      return clientMatch && siteMatch;
    });

    setFilteredClients(filteredData);
    setFilterChanged(true);
  }, [props.filterClient, props.filterSite, toolBoxTalk]);

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentToolBoxTalk = filteredClients.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Client</th>
            <th scope="col">Site</th>
            <th scope="col">Last Updated Document</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentToolBoxTalk.length > 0 ? (
            currentToolBoxTalk.map((item) => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  key={item.site_id}
                  onClick={() => handleRowClick(item.site_id)}
                >
                  <td>{item.site_name}</td>
                  <td title={item.site_address}>
                    {truncateSiteName(item.site_address)}
                  </td>
                  <td>{item.latest_document_name}</td>
                  <td>
                    <FaEye
                      style={{ cursor: "pointer", margin: "8px" }}
                      className="action-icon"
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">No data</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentToolBoxTalk.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default TableTool;
