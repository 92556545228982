import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Recommendation.css";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import RecommendationModal from "../Modal/RecommendationModal/RecommendationModal";
import { changeRecommendationStatus } from "../../API/axios";
import { toast } from "react-toastify";

const getFileExtensionFromUrl = (url) => {
  const parts = url.split("/");

  const fileName = parts[parts.length - 1];

  const fileNameParts = fileName.split(".");

  if (fileNameParts.length > 1) {
    let extensionWithParams = fileNameParts.pop();

    if (
      extensionWithParams.toLowerCase() ===
      "pdf%20fri%20dec%2001%202023%2003%3a55%3a28%20gmt%2b0000%20(coordinated%20universal%20time)"
    ) {
      return "pdf";
    }
    const extension = extensionWithParams.split("%")[0].toLowerCase();
    return extension;
  }

  return "unknown";
};

const FileViewer = ({ url, fileExtension }) => {
  if (!url) {
    return null;
  }
  const imageStyles = {
    maxWidth: "100%",
    maxHeight: "400px",
    imageRendering: "pixelated",
  };
  switch (fileExtension) {
    case "pdf":
    case "doc":
    case "docx":
      return (
        <iframe title="file-viewer" src={url} width="100%" height="600px" />
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return <img src={url} alt="file-preview" style={imageStyles} />;
    case "mp4":
    case "webm":
      return (
        <video controls width="100%" height="400px">
          <source src={url} type={`video/${fileExtension}`} />
          Your browser does not support the video tag.
        </video>
      );
    default:
      return <p>Unsupported file type</p>;
  }
};

function PendingView({ recommendation, setNewRecommendation }) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleDownload = (imageUrl, name) => {
    saveAs(imageUrl, `${name}.png`);
  };

  const navigate = useNavigate();

  const handleDeny = async () => {
    try {
      await changeRecommendationStatus(recommendation.id, "Denied");
      toast.success("Recommendation denied!");
      navigate(-1);
    } catch (error) {
      console.error("Error denying recommendation:", error);
      toast.error("Error denying recommendation");
    }
  };

  return (
    <>
      <div className="reco-wrapper">
        <div className="recommendation">
          <h2 className="recommendationTitle">{recommendation.title}</h2>
          <p className="recommendationDes">{recommendation.description}</p>
          <div>
            <FileViewer
              url={recommendation.url}
              fileExtension={getFileExtensionFromUrl(recommendation.url)}
            />
          </div>
          <div className="recomm-buttons">
            <Button
              variant="secondary"
              onClick={handleShow}
              style={{ width: "80px" }}
            >
              Accept
            </Button>
            <Button
              variant="dark"
              onClick={handleDeny}
              style={{ width: "80px" }}
            >
              Deny
            </Button>
          </div>
        </div>
        <RecommendationModal
          show={show}
          setShow={setShow}
          id={recommendation.id}
          Title="create"
          setNewRecommendation={setNewRecommendation}
        />
      </div>
    </>
  );
}

export default PendingView;
