import React, { useEffect, useState } from "react";
import { GetCleaners, getAllCleanerUsersEach } from "../../API/axios";
import "./Filter.css";

function Filter(props) {
  const [cleaners, setCleaners] = useState([]);

  useEffect(() => {
    const GetAllCleaners = async () => {
      try {
        const Data = await getAllCleanerUsersEach();
        console.log(Data.result);
        setCleaners(Data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllCleaners();
  }, [props.deleteShow]);

  const uniqueCleanerNames = Array.from(
    new Set(cleaners.map((item) => item.f_name))
  );
  const uniqueCleanerLastNames = Array.from(
    new Set(
      cleaners
        .filter((items) => items.f_name === props.filterName)
        .map((item) => item.l_name)
    )
  );
  const uniqueClientNames = Array.from(
    new Set(
      cleaners
        .filter((items) => items.f_name === props.filterName)
        .map((item) => item.site_name)
    )
  );
  const uniqueSiteNames = Array.from(
    new Set(
      cleaners
        .filter((items) => items.site_name === props.filterSite)
        .map((item) => item.site_address)
    )
  );

  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterSite(localStorage.getItem("user"));
  }
  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFilterName(e.target.value);
              props.setFilterLastName("");
              props.setFilterSite("");
              props.setFilterSiteAdd("");
            }}
          >
            <option selected value={""}>
              Select First Name
            </option>
            {cleaners.length > 0 ? (
              uniqueCleanerNames.map((name, index) => {
                return (
                  <option value={name} key={name}>
                    {name}
                  </option>
                );
              })
            ) : (
              <option selected>No Name to Select</option>
            )}
          </select>
        </div>
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFilterLastName(e.target.value);
            }}
          >
            <option selected value={""}>
              Select Last Name
            </option>
            {cleaners.length > 0 ? (
              uniqueCleanerLastNames.map((name, index) => {
                return (
                  <option value={name} key={name}>
                    {name}
                  </option>
                );
              })
            ) : (
              <option selected>No Name to Select</option>
            )}
          </select>
        </div>
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
                props.setFilterSiteAdd("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {uniqueClientNames ? (
                uniqueClientNames.map((name, index) => {
                  return <option value={name}>{name}</option>;
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSiteAdd(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {uniqueSiteNames ? (
                uniqueSiteNames.map((name) => {
                  return <option value={name}>{name}</option>;
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default Filter;
