//import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import RecommendationCreateForm from "./RecommendationCreateForm";

function RecommendationModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.Title === "create" ? "Create Recommendation" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RecommendationCreateForm
            show={props.show}
            setShow={props.setShow}
            handleClose={handleClose}
            id={props.id}
            setNewRecommendation={props.setNewRecommendation}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RecommendationModal;
