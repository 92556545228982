import React, { useState } from "react";
import "./Recommendation.css";
import FilterSite from "../../Componants/Filters/Reccommendation/Filter";
import TableReccom from "../../Componants/Tables/TableReccom/Table";

function Recommendations() {
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  return (
    <>
      <div className="cleaner-topic">
        <p>Site Recommendation </p>
      </div>
      <div>
        <FilterSite
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          setFilterDate={setFilterDate}
          setFilterStatus={setFilterStatus}
          filterClient={filterClient}
        />
        <TableReccom
          filterClient={filterClient}
          filterSite={filterSite}
          filterDate={filterDate}
          filterStatus={filterStatus}
        /> 
      </div>
    </>
  );
}

export default Recommendations;
