import React, { useEffect, useState } from "react";
import "../FilterSite/FilterSite.css";
import { GetScandList, GetClients } from "../../API/axios";
import "./Filter.css";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";

function FilterDailyRecord(props) {
  const [scandlist, setScandList] = useState([]);
  const [cleaners, setClients] = useState([]);
  const [date, setDate] = useState(null);

  useEffect(() => {
    const GetAllScandList = async () => {
      try {
        const Data = await GetScandList();
        console.log(Data.data.data);
        setScandList(Data.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllScandList();
  }, []);

  useEffect(() => {
    const GetAllClients = async () => {
      try {
        const Data = await GetClients();
        console.log(Data.data.sites);
        setClients(Data.data.sites);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllClients();
  }, []);

  const handleDateChange = (date) => {
    const selectedDate = date
      ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      : null;
    setDate(selectedDate);
    props.setFilterDate(selectedDate);
  };

  const uniqueClientNames = Array.from(
    new Set(cleaners.map((item) => item.site_name))
  );
  const uniqueSiteNames = Array.from(
    new Set(
      cleaners
        .filter((items) => items.site_name === props.filterClient)
        .map((item) => item.site_address)
    )
  );
  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterClient(localStorage.getItem("user"));
  }
  const uniqueclientNames = Array.from(
    new Set(scandlist.map((item) => item.site_name))
  );

  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterClient(e.target.value);
                props.setFilterSite("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {uniqueClientNames ? (
                uniqueClientNames.map((name, index) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {uniqueSiteNames ? (
                uniqueSiteNames.map((name) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}

        {/* <div className="col">
          <input
            type="date"
            className="form-control"
            placeholder="Select Date"
            onChange={(e) => {
              props.setFilterDate(e.target.value);
            }}
          />
        </div> */}

        <div className="col">
          <span>Select Date: </span>
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            className="datepickerDailyRec"
          />
        </div>

        {/* <div className="col">
          <DatePicker
            selected={props.filterDate ? new Date(props.filterDate) : null}
            onChange={(date) => {
              props.setFilterDate(date);
            }}
            placeholderText={
              props.filterDate
                ? `Selected Date: ${new Date(
                    props.filterDate
                  ).toLocaleDateString()}`
                : "Select Date"
            }
            dateFormat="MM/dd/yyyy"
            className="datepickerDailyRec"
            style={{ color: "red" }} // Change 'red' to your desired font color
          />
        </div> */}
      </div>
    </div>
  );
}

export default FilterDailyRecord;
