import React, { useEffect, useState } from "react";
import {
  getAllCleanerUsersEach,
  getShowList,
  unAssignCleaners,
} from "../../../API/axios";
import { toast } from "react-toastify";
import { Pagination } from "react-bootstrap";

function CleanerTable(props) {
  const [cleaners, setCleaners] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showList, setShowList] = useState([]);
  const userType = localStorage.getItem("userType");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    GetAllCleaners();
    GetShowList();
  }, []);

  useEffect(() => {
    GetAllCleaners();
    GetShowList();
  }, [props.reload]);

  const GetAllCleaners = async () => {
    try {
      const Data = await getAllCleanerUsersEach();
      setCleaners(Data.result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const GetShowList = async () => {
    try {
      const Data = await getShowList();
      setShowList(Data.data.showColoumn[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const filteredData = cleaners.filter((item) => {
      const nameMatch = props.site === "" || item.site_id === props.site;
      return nameMatch;
    });

    setFilteredCleaners(filteredData);
  }, [props.site, cleaners, props.reload]);

  const UnAssignCleaner = (id) => {
    const siteID = props.site;

    unAssignCleaners(siteID, id)
      .then((response) => {
        toast.success(response.message);
        GetAllCleaners();
        props.setReload(!props.reload);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
      });
  };

  const totalPages = Math.ceil(filteredCleaners.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentInformations = filteredCleaners.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  return (
    <>
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Cleaner ID</th>
            <th scope="col">Cleaner Name</th>
            <th scope="col">Contact</th>
            {showList.email === 1 && <th scope="col">Email</th>}
            {showList.profile === 1 && <th scope="col">Profile Picture</th>}
            {userType === "SuperAdmin" && <th scope="col">Action</th>}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentInformations.length > 0 ? (
            currentInformations.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.emp_no}</td>
                  <td>
                    {item.f_name} {item.l_name}
                  </td>
                  <td>{item.phone}</td>
                  {showList.email === 1 && <td>{item.email}</td>}
                  {showList.profile === 1 && (
                    <td>
                      <img
                        src={item.image}
                        style={{ height: "100px", width: "100px" }}
                      />
                    </td>
                  )}
                  {userType === "SuperAdmin" && (
                    <td>
                      <button
                        className="moveHistory-button"
                        onClick={() => UnAssignCleaner(item.user_id)}
                        style={{
                          zIndex: 5,
                        }}
                      >
                        Remove
                      </button>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentInformations.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </>
  );
}

export default CleanerTable;
