import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import MyAreasAndClients from "./Componants/Areas_Clients/MyAreasAndClients";
import Layout from "./Componants/Layout/Layout";
import Samplesp from "./Componants/Modal/Samplesp";
import IncidentPdf from "./Componants/Tables/TableIncident/IncidentPdf";
import PrivateRoutes from "./Componants/Utils/PrivateRoutes";
import SiteArea from "./Pages/Area/SiteArea";
import AssignTaskHistory from "./Pages/AssignTask/AssignTaskHistory";
import AssignTasks from "./Pages/AssignTask/AssignTasks";
import TaskInformation from "./Pages/AssignTask/TaskInformation";
import Passwordchange from "./Pages/Changepassword/PasswordChange";
import ChatList from "./Pages/Communication/ChatList";
import Communication from "./Pages/Communication/Communication";
import DailyRecords from "./Pages/DailyRecords/DailyRecord";
import CleanerSummary from "./Pages/Dashboard/CleanerSummary";
import ClientProgress from "./Pages/Dashboard/ClientBarChart";
import ClientSummary from "./Pages/Dashboard/ClientSummary";
import Dashboard from "./Pages/Dashboard/Dashboard";
import StockSummary from "./Pages/Dashboard/StockSummary";
import Help from "./Pages/Help/Help";
import IncidentReport from "./Pages/IncedentReport/IncedentReport";
import Login from "./Pages/Login/Login";
import Mycleaner from "./Pages/MyCleaner/Mycleaner";
import HelpView from "./Pages/MyHelp/HelpView";
import Myorders from "./Pages/MyOrders/Myorders";
import OrderView from "./Pages/MyOrders/OrderView";
import Mysites from "./Pages/MySites/Mysites";
import OperationsUI from "./Pages/OperationsUI/Operations";
import Profile from "./Pages/Profile/Profile";
import RecommendationView from "./Pages/Recommendation/RecommendationView";
import Recommendations from "./Pages/Recommendation/Recommendations";
import AuditDocuments from "./Pages/SiteInformation/AuditDocuments";
import SiteInformation from "./Pages/SiteInformation/SiteInformation";
import SiteManual from "./Pages/SiteManual/SiteManual";
import SiteManualDocuments from "./Pages/SiteManual/SiteManualDocuments";
import SiteManualHistory from "./Pages/SiteManual/SiteManualHistory";
import SiteManualReaders from "./Pages/SiteManual/SiteManualReaders";
import IncidentReportHistory from "./Pages/IncedentReportHistory/IncedentReportHistory";

import TimeOnSiteProgress from "./Pages/TimeOnSiteProgress/TimeOnSiteProgress";
import ToolBoxDocuments from "./Pages/ToolBoxTalk/ToolBoxDocuments";
import ToolBoxHistory from "./Pages/ToolBoxTalk/ToolBoxHistory";
import ToolBoxTalk from "./Pages/ToolBoxTalk/ToolBoxTalk";
import ToolboxReaders from "./Pages/ToolBoxTalk/ToolboxReaders";
import CleanerProfile from "./Pages/MyCleaner/CleanerProfile";
import { onMessage } from 'firebase/messaging';
import { generateToken,messaging } from './API/firebase';

function App() {
  const [token, setToken] = useState('');
  
  useEffect(() => {
    const setupFirebaseMessaging = async () => {
      const getToken = await generateToken();
      setToken(getToken);
      onMessage(messaging, (payload) => {
        localStorage.setItem('notificationName',payload.notification.body);
        toast(
          <div>
              <h5 style={{color:'black'}}>{payload.notification.title}</h5>
              <p style={{color:'black'}}>{payload.notification.body}</p>
          </div>,
      );
      });
    };

    setupFirebaseMessaging();
  }, []);
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login token={token}/>} />

          <Route element={<PrivateRoutes />}>
            <Route
              path="/operationsui"
              element={
                <Layout>
                  <OperationsUI />
                </Layout>
              }
            />
            <Route
              path="/timeonsiteprogress"
              element={
                <Layout>
                  <TimeOnSiteProgress />
                </Layout>
              }
            />
            <Route path="/Samplesp" element={<Samplesp></Samplesp>} />
            <Route
              path="/mycleaner"
              element={
                <Layout>
                  <Mycleaner />
                </Layout>
              }
            />
            <Route
              path="/profile"
              element={
                <Layout>
                  <Profile />
                </Layout>
              }
            />
            <Route
              path="/mysites"
              element={
                <Layout>
                  <Mysites />
                </Layout>
              }
            />
            <Route
              path="/mysites/sitearea/:index"
              element={
                <Layout>
                  <SiteArea />
                </Layout>
              }
            />
            <Route
              path="/myareas&sites"
              element={
                <Layout>
                  <MyAreasAndClients />
                </Layout>
              }
            />
            <Route
              path="/changepassword"
              element={
                <Layout>
                  <Passwordchange />
                </Layout>
              }
            />
            <Route
              path="/dailyrecords"
              element={
                <Layout>
                  <DailyRecords />
                </Layout>
              }
            />
            <Route
              path="/communication"
              element={
                <Layout>
                  <Communication />
                </Layout>
              }
            />
            <Route
              path="/chatlist/:client_id"
              element={
                <Layout>
                  <ChatList />
                </Layout>
              }
            />
            <Route
              path="/incidentreport"
              element={
                <Layout>
                  <IncidentReport />
                </Layout>
              }
            />
            <Route
              path="/incidentreport/History"
              element={
                <Layout>
                  <IncidentReportHistory />
                </Layout>
              }
            />
            <Route
              path="/incidentreportPdf"
              element={
                <Layout>
                  <IncidentPdf />
                </Layout>
              }
            />
            <Route
              path="/recommendation"
              element={
                <Layout>
                  <Recommendations />
                </Layout>
              }
            />
            <Route
              path="/recommendation/:index"
              element={
                <Layout>
                  <RecommendationView />
                </Layout>
              }
            />
            <Route
              path="/siteinformation"
              element={
                <Layout>
                  <SiteInformation />
                </Layout>
              }
            />
            <Route
              path="/siteinformation/cleaners/:site"
              element={
                <Layout>
                  <Mycleaner path={"siteinformation"} />
                </Layout>
              }
            />
            <Route
              path="/toolboxtalk"
              element={
                <Layout>
                  <ToolBoxTalk />
                </Layout>
              }
            />
            <Route
              path="/help"
              element={
                <Layout>
                  <Help />
                </Layout>
              }
            />
            <Route
              path="/myhelps/:id"
              element={
                <Layout>
                  <HelpView />
                </Layout>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/toolboxtalk-documents/:id"
              element={
                <Layout>
                  <ToolBoxDocuments />
                </Layout>
              }
            />
            <Route
              path="/clientsummary"
              element={
                <Layout>
                  <ClientSummary />
                </Layout>
              }
            />
            <Route
              path="/cleanersummary"
              element={
                <Layout>
                  <CleanerSummary />
                </Layout>
              }
            />
            <Route
              path="/stocksummary"
              element={
                <Layout>
                  <StockSummary />
                </Layout>
              }
            />
            <Route
              path="/clientprogress"
              element={
                <Layout>
                  <ClientProgress />
                </Layout>
              }
            />
            <Route
              path="/toolbox-history/:id"
              element={
                <Layout>
                  <ToolBoxHistory />
                </Layout>
              }
            />
            <Route
              path="/toolbox-readers/:id"
              element={
                <Layout>
                  <ToolboxReaders />
                </Layout>
              }
            />
            <Route
              path="/sitemanual"
              element={
                <Layout>
                  <SiteManual />
                </Layout>
              }
            />
            <Route
              path="/assigntasks"
              element={
                <Layout>
                  <AssignTasks />
                </Layout>
              }
            />
            <Route
              path="/sitemanual-documents/:id"
              element={
                <Layout>
                  <SiteManualDocuments />
                </Layout>
              }
            />
            <Route
              path="/taskinformation/:id"
              element={
                <Layout>
                  <TaskInformation />
                </Layout>
              }
            />
            <Route
              path="/sitemanual-history/:id"
              element={
                <Layout>
                  <SiteManualHistory />
                </Layout>
              }
            />

            <Route
              path="/assigntaskhistory"
              element={
                <Layout>
                  <AssignTaskHistory />
                </Layout>
              }
            />
            <Route
              path="/sitemanual-readers/:siteID/:id"
              element={
                <Layout>
                  <SiteManualReaders />
                </Layout>
              }
            />

            <Route path="/assigntasks" element={<AssignTasks />} />
            <Route path="/taskinformation" element={<TaskInformation />} />
            <Route path="/assigntaskhistory" element={<AssignTaskHistory />} />

            <Route
              path="/myorders"
              element={
                <Layout>
                  <Myorders />
                </Layout>
              }
            />
            <Route
              path="/myorders/:id"
              element={
                <Layout>
                  <OrderView />
                </Layout>
              }
            />
            <Route
              path="/siteinformation/audit-documents/:id"
              element={
                <Layout>
                  <AuditDocuments />
                </Layout>
              }
            />
            <Route
              path="/mycleaner/cleanerdetails/:id"
              element={
                <Layout>
                  <CleanerProfile />
                </Layout>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
