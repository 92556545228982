import React from "react";
import "./ImagePreviewModal.css";

const ImagePreviewModal = ({ imageUrl, onCancel, onClose}) => {
  return (
    <div className="image-preview-modal-overlay">
      <div className="image-preview-modal-content">
        <div className="image-preview-modal-content-header"></div>
        <img src={imageUrl} alt="Preview" />
        <div className="image-preview-modal-content-footer">
          <button className="image-preview-model-button" onClick={onClose}>OK</button>
          <button className="image-preview-model-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ImagePreviewModal;
