import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Pagination } from "react-bootstrap";
import { getAllStockByMonth, getAllStocksummary } from "../../API/axios";

function TableStockSummary(props) {
  const [stock, setStock] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const GetAllStock = async () => {
      try {
        if (props.filterSiteAdd) {
          const Data = await getAllStockByMonth(
            props.filterYear,
            props.filterSiteAdd
          );
          setStock(Data.data.data);
          setLoading(false);
        } else {
          const Data = await getAllStocksummary();
          setStock(Data.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    GetAllStock();
  }, [
    props.filterName,
    props.filterSite,
    props.filterSiteAdd,
    props.filterYear,
  ]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = stock.filter((item) => {
      const nameMatch =
        props.filterName === "" || item.site_name === props.filterName;
      const siteMatch =
        props.filterSite === "" || item.site_address === props.filterSite;
      return nameMatch && siteMatch;
    });
    setFilteredCleaners(filteredData);
    setFilterChanged(true);
  }, [props.filterName, props.filterSite, props.filterSiteAdd, stock]);

  // Group the data by site_id
  // Group the data by site_id, site_name, site_address, and total_qty
  const groupedData = filteredCleaners.reduce((acc, item) => {
    const {
      site_id,
      site_name,
      site_address,
      total_qty,
      is_approved,
      order_dates,
      product_name,
      quantity,
    } = item;
    const key = `${site_id}-${site_name}-${site_address}-${total_qty}`;

    if (!acc[key]) {
      acc[key] = {
        site_id,
        site_name,
        site_address,
        total_qty,
        data: [],
      };
    }

    // Split the comma-separated values and map them to objects
    const parsedData = is_approved.split(",").map((_, index) => ({
      is_approved: is_approved.split(",")[index],
      order_dates: order_dates.split(",")[index],
      product_name: product_name.split(",")[index],
      quantity: quantity.split(",")[index],
    }));

    acc[key].data = [...acc[key].data, ...parsedData];
    return acc;
  }, {});

  // Convert the grouped data object into an array of objects
  const resultArray = Object.values(groupedData);

  const totalPages = Math.ceil(resultArray.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFilteredCleaners = resultArray.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            {!isClient && <th scope="col">Client</th>}
            {!isClient && <th scope="col">Site</th>}
            <th scope="col">Equipments</th>
            <th scope="col">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : filteredCleaners.length > 0 ? (
            currentFilteredCleaners.map((item) => (
              <tr key={item.site_id}>
                {!isClient && <td>{item.site_name}</td>}
                {!isClient && (
                  <td title={item.site_address}>
                    {truncateSiteName(item.site_address)}
                  </td>
                )}
                <td>
                  <Col>
                    <td>
                      {item.data
                        .filter((items) => items.is_approved === "1")
                        .map((items) => (
                          <p>{items.product_name}</p>
                        ))}
                    </td>
                  </Col>
                  <th>Total:</th>
                </td>
                <td>
                  {item.data
                    .filter((items) => items.is_approved === "1")
                    .map((items, index) => (
                      <p key={index}>{items.quantity}</p>
                    ))}
                  <th>
                    {item.data.reduce((count, items) => {
                      if (items.is_approved === "1") {
                        return count + parseInt(items.quantity, 10);
                      }
                      return count;
                    }, 0)}
                  </th>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentFilteredCleaners.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "260px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default TableStockSummary;
