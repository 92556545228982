import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { addNewSite, updateSite } from "../../../API/axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PRIMARY from "../../../Theme/palette";
import "./ClientCreateForm.css";

function ClientCreateForm(props) {
  const [formData, setFormData] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isButtonLoading, setisButtonLoading] = useState(false);

  const [clientNamesSet, setClientNamesSet] = useState(new Set());

  useEffect(() => {
    // Update clientNamesSet whenever formData.client changes.
    if (formData.client) {
      setClientNamesSet((prevSet) =>
        new Set(prevSet).add(formData.client.toLowerCase())
      );
    }
  }, [formData.client]);

  useEffect(() => {
    if (props.title === "create") {
      setFormData({
        client: "",
        mobile: "",
        email: "",
        site: "",
        latitude: "",
        longitude: "",
        attendanceRate: "",
        password: "",
        confirmPassword: "",
        sun: "",
        mon: "",
        tues: "",
        wed: "",
        thur: "",
        fri: "",
        satur: "",
      });
    } else {
      setFormData({
        client: props.item.site_name,
        mobile: props.item.mobile,
        email: props.item.site_email,
        site: props.item.site_address,
        latitude: props.item.latitude,
        longitude: props.item.longitude,
        attendanceRate: props.item.rate,
        password: props.item.password,
        confirmPassword: props.item.confirmPassword,
        sun: props.item.sun,
        mon: props.item.mon,
        tues: props.item.tues,
        wed: props.item.wed,
        thur: props.item.thur,
        fri: props.item.fri,
        satur: props.item.satur,
      });
    }
  }, [props.title, props.item]);

  const preventPaste = (e) => {
    e.preventDefault();
  };

  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    if (name === "latitude" || name === "longitude") {
      const numericValue = value.replace(/[^-+0-9.]/g, "");
      if (
        (name === "latitude" && (numericValue < -90 || numericValue > 90)) ||
        (name === "longitude" && (numericValue < -180 || numericValue > 180))
      ) {
        setValidationErrors({
          ...validationErrors,
          [name]: `Invalid ${name}. Must be within ${
            name === "latitude" ? "-90 to +90" : "-180 to +180"
          }.`,
        });
      } else {
        setFormData({
          ...formData,
          [name]: numericValue,
        });
      }

      if (numericValue !== value) {
        setValidationErrors({
          ...validationErrors,
          [name]: "Enter valid format value.",
        });
      }
    } else if (name === "client") {
      const formattedClientName = value
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase());

      setFormData({
        ...formData,
        [name]: formattedClientName,
      });
    } else if (name === "attendanceRate") {
      const numericValue = value.replace(/[^0-9][0-9]?|101|[e.]/g);
      const parsedValue = parseFloat(numericValue);

      if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= 100) {
        setFormData({
          ...formData,
          [name]: parsedValue,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: null,
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: "Attendance Rate should be a valid number between 1 and 100.",
        });
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (
      name === "sun" ||
      name === "mon" ||
      name === "tues" ||
      name === "wed" ||
      name === "thur" ||
      name === "fri" ||
      name === "satur"
    ) {
      const numericValue = parseInt(value, 10);

      if (!isNaN(numericValue) && numericValue <= 24) {
        setFormData({
          ...formData,
          [name]: numericValue,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: null,
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: `Please enter a valid number below or equal to 24.`,
        });
        setFormData({
          ...formData,
          [name]: "",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const validateMobileNumber = (mobile) => {
    const mobileRegex = /^\+\d{2,3}[0-9]{9}$/;
    return mobileRegex.test(mobile);
  };

  const validateemail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (props.title === "create") {
      if (!formData.client) {
        errors.client = "Client Name is required.";
      }
      if (!formData.mobile) {
        errors.mobile = "Mobile Number is required.";
      } else if (!validateMobileNumber(formData.mobile)) {
        errors.mobile = "Please enter a valid mobile number.";
      }
      if (!formData.email) {
        errors.email = "Email is required.";
      } else if (!validateemail(formData.email)) {
        errors.email = "Please enter a valid email address.";
      }
      if (!formData.site) {
        errors.site = "Site is required.";
      }
      if (!formData.latitude) {
        errors.latitude = "Latitude is required.";
      }
      if (!formData.longitude) {
        errors.longitude = "Longitude is required.";
      }
      if (!formData.attendanceRate) {
        errors.attendanceRate = "Attendance Rate is required.";
      }
      if (formData.attendanceRate > 100 || formData.attendanceRate < 1) {
        errors.attendanceRate = "Attendance Rate should between 1-100%.";
      }
      if (!formData.password) {
        errors.password = "Password is required.";
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
          formData.password
        )
      ) {
        errors.password =
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol, and be at least 8 characters long.";
      }

      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password do not match.";
      }
    } else {
      if (!formData.client) {
        errors.client = "Client Name is required.";
      }
      if (!formData.mobile) {
        errors.mobile = "Mobile Number is required.";
      } else if (!validateMobileNumber(formData.mobile)) {
        errors.mobile = "Please enter a valid mobile number.";
      }
      if (!formData.email) {
        errors.email = "Email is required.";
      } else if (!validateemail(formData.email)) {
        errors.email = "Please enter a valid email address.";
      }
      if (!formData.site) {
        errors.site = "Site is required.";
      }
      if (!formData.latitude) {
        errors.latitude = "Latitude is required.";
      }
      if (!formData.longitude) {
        errors.longitude = "Longitude is required.";
      }
      if (!formData.attendanceRate) {
        errors.attendanceRate = "Attendance Rate is required.";
      }
      if (formData.attendanceRate > 100 || formData.attendanceRate < 1) {
        errors.attendanceRate = "Attendance Rate should be between 1 - 100%.";
      }

      if (!formData.password) {
      } else if (
        !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
          formData.password
        )
      ) {
        errors.password =
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol, and be at least 8 characters long.";
      }

      if (
        formData.password &&
        formData.confirmPassword &&
        formData.password !== formData.confirmPassword
      ) {
        errors.confirmPassword = "Password and Confirm Password do not match.";
      }
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsDisabled(false);
    }
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);

    if (validateForm()) {
      setisButtonLoading(true);

      const createRequestBody = {
        site_name: formData.client,
        site_address: formData.site,
        email: formData.email,
        rate: formData.attendanceRate,
        password: formData.password,
        mobile: formData.mobile,
        latitude: formData.latitude,
        longitude: formData.longitude,
        sun: formData.sun ? formData.sun : 0,
        mon: formData.mon ? formData.mon : 0,
        tues: formData.tues ? formData.tues : 0,
        wed: formData.wed ? formData.wed : 0,
        thur: formData.thur ? formData.thur : 0,
        fri: formData.fri ? formData.fri : 0,
        satur: formData.satur ? formData.satur : 0,
      };

      const updateRequestBody = {
        site_name: formData.client,
        site_address: formData.site,
        email: formData.email,
        rate: formData.attendanceRate,
        password: formData.password,
        mobile: formData.mobile,
        latitude: formData.latitude,
        longitude: formData.longitude,
        sun: formData.sun,
        mon: formData.mon,
        tues: formData.tues,
        wed: formData.wed,
        thur: formData.thur,
        fri: formData.fri,
        satur: formData.satur,
      };

      if (props.title === "create") {
        addNewSite(createRequestBody)
          .then((response) => {
            console.log("response : ", response);
            if (response.status) {
              props.handleClose();
              props.setShow(!props.show);
              toast.success("Site added successfully!");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.response.data.message);
            setisButtonLoading(false);
            setIsDisabled(false);
          });
      } else {
        console.log("site id : ", props.item.site_id);

        updateSite(props.item.site_id, updateRequestBody)
          .then((response) => {
            console.log(response);
            if (response.status) {
              props.handleClose();
              props.getAllClients();
              toast.success("Site updated successfully!");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.response.data.message);
            setisButtonLoading(false);
            setIsDisabled(false);
          });
      }
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Client:</Form.Label>
              <Form.Control
                type="text"
                name="client"
                value={formData.client}
                onChange={handleInputChange}
              />
              {validationErrors.client && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.client}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Mobile:</Form.Label>
              <PhoneInput
                international
                defaultCountry="AU"
                placeholder="Enter phone number"
                value={formData.mobile}
                onChange={(value) =>
                  handleInputChange({ target: { name: "mobile", value } })
                }
              />
              <p
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  fontSize: "12px",
                  padding: "2px 8px",
                  margin: "0",
                  color:
                    formData.mobile &&
                    formData.mobile.replace(/\D/g, "").length > 12
                      ? "red"
                      : "inherit",
                }}
              >
                {formData.mobile
                  ? `${formData.mobile.replace(/\D/g, "").length} /12`
                  : ""}
              </p>
              {validationErrors.mobile && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.mobile}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Email:</Form.Label>
              <Form.Control
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {validationErrors.email && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.email}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group>
            <Form.Label>Site:</Form.Label>
            <Form.Control
              as="textarea"
              name="site"
              value={formData.site}
              onChange={handleInputChange}
            />
            {validationErrors.site && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.site}
              </p>
            )}
          </Form.Group>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Latitude:</Form.Label>
              <Form.Control
                type="text"
                name="latitude"
                value={formData.latitude}
                onChange={handleInputChange}
              />
              {validationErrors.latitude && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.latitude}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Longitude:</Form.Label>
              <Form.Control
                type="text"
                name="longitude"
                value={formData.longitude}
                onChange={handleInputChange}
              />
              {validationErrors.longitude && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.longitude}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Attendance Rate(100%):</Form.Label>
              <Form.Control
                type="number"
                name="attendanceRate"
                value={formData.attendanceRate}
                onChange={handleInputChange}
              />
              {validationErrors.attendanceRate && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.attendanceRate}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Password:</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                {formData.password ? (
                  <span
                    className="toggle-password"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {validationErrors.password && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.password}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Confirm Password:</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  onPaste={preventPaste}
                />
                {formData.confirmPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  >
                    {isConfirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                  </span>
                ) : (
                  ""
                )}
              </div>
              {validationErrors.confirmPassword && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.confirmPassword}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Form.Label>Schedule:</Form.Label>
        <Row
          style={{
            backgroundColor: "lightyellow",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <Col>
            <Form.Group>
              <Form.Label>SUN:</Form.Label>
              <Form.Control
                type="text"
                name="sun"
                value={formData.sun}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.sun}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.sun}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Mon:</Form.Label>
              <Form.Control
                type="text"
                name="mon"
                value={formData.mon}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.mon}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.mon}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col>
            <Form.Group>
              <Form.Label>TUE:</Form.Label>
              <Form.Control
                type="text"
                name="tues"
                value={formData.tues}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.tues}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.tues}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col>
            <Form.Group>
              <Form.Label>WED:</Form.Label>
              <Form.Control
                type="text"
                name="wed"
                value={formData.wed}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.wed}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.wed}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col>
            <Form.Group>
              <Form.Label>THU:</Form.Label>
              <Form.Control
                type="text"
                name="thur"
                value={formData.thur}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.thur}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.thur}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col>
            <Form.Group>
              <Form.Label>FRI:</Form.Label>
              <Form.Control
                type="text"
                name="fri"
                value={formData.fri}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.fri}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.fri}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>SAT:</Form.Label>
              <Form.Control
                type="text"
                name="satur"
                value={formData.satur}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.satur}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.satur}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default ClientCreateForm;
