import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetClients } from "../../API/axios";
import "./Filter.css";

function FilterTimeOnSiteProgress(props) {
  const [clients, setClients] = useState([]);
  const [date, setDate] = useState(null);
  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterClient(localStorage.getItem("user"));
  }

  const GetAllClients = async () => {
    try {
      const Data = await GetClients();
      setClients(Data.data.sites);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    GetAllClients();
  }, []);

  const uniqueClientNames = Array.from(
    new Set(clients.map((item) => item.site_name))
  );
  const uniqueSiteNames = Array.from(
    new Set(
      clients
        .filter((items) => items.site_name === props.filterClient)
        .map((item) => item.site_address)
    )
  );

  const handleDateChange = (date) => {
    const selectedDate = date
      ? new Date(Date.UTC(date.getFullYear(), date.getMonth()))
      : null;
    setDate(selectedDate);
    props.setFilterDate(selectedDate);
  };

  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterClient(e.target.value);
                props.setFilterSite("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {uniqueClientNames ? (
                uniqueClientNames.map((name, index) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {uniqueSiteNames ? (
                uniqueSiteNames.map((name, index) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
        <div className="col">
          <span>Select Date: </span>
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            showMonthYearPicker
            dateFormat="MM/yyyy"
            className="datepickerTOS"
          />
        </div>
      </div>
    </div>
  );
}

export default FilterTimeOnSiteProgress;
