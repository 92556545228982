// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyCmnd04L69uBA7040i08B5J2-Y9prp_rTc",
//   authDomain: "cleanerconnect-b06a3.firebaseapp.com",
//   projectId: "cleanerconnect-b06a3",
//   storageBucket: "cleanerconnect-b06a3.appspot.com",
//   messagingSenderId: "33588551520",
//   appId: "1:33588551520:web:82ed93f83c68a15e03c805",
//   measurementId: "G-CV8PYFW1J8"
// };

// const firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebase);
// export default firebase;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging"
import { getFirestore } from "firebase/firestore"; // Import Firestore

const firebaseConfig = {
  apiKey: "AIzaSyCmnd04L69uBA7040i08B5J2-Y9prp_rTc",
  authDomain: "cleanerconnect-b06a3.firebaseapp.com",
  projectId: "cleanerconnect-b06a3",
  storageBucket: "cleanerconnect-b06a3.appspot.com",
  messagingSenderId: "33588551520",
  appId: "1:33588551520:web:82ed93f83c68a15e03c805",
  measurementId: "G-CV8PYFW1J8"
};

const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const firestore = getFirestore(firebase); // Initialize Firestore

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  // console.log(permission);
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: "BJVCDZD_YsBxVGJyjmMXZh6aUxTQS4FnBBeK08k1zVPHsjc2ae3hwiVDIpe7c5yZ7p8PdXGJwsEH3Qc9qVLhZYQ"
    }).then((currentFCMToken) => {
      if (currentFCMToken) {
        // console.log("currentFCMToken", currentFCMToken);
        return currentFCMToken;
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    });
  
    return token;
  }else if(permission === "denied"){
    console.log('premisson denieded')
  }
}


export { firebase, analytics, firestore };