import React from "react";
import Modal from "react-bootstrap/Modal";
import CleanerDocumentUploadForm from "./CleanerDocumentUploadForm";

function CleanerDocumentUploadModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.title === "create" ? "Upload Document" : " "}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CleanerDocumentUploadForm
            title={props.title}
            show={props.show}
            setShow={props.setShow}
            handleClose={handleClose}
            id={props.id}
            GetDocumentsByCleanerId={props.GetDocumentsByCleanerId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CleanerDocumentUploadModal;
