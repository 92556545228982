//import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import IncidentHistoryForm from "./IncidentHistoryForm";

function IncidentHistoryModal(props) {
  const handleClose = () => props.setShow(false);

  const handleFormSubmission = () => {
    handleClose();
    props.handleIncidentsUpdated();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Incident Report History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IncidentHistoryForm
            show={props.show}
            setShow={props.setShow}
            handleClose={handleClose}
            selectedIncident={props.selectedIncident}
            handleFormSubmission={handleFormSubmission}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default IncidentHistoryModal;
