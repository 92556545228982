import React, { useState } from "react";
import "../EndChatModal/EndChatModal.css";
import { updateChat } from "../../../API/fireStoreService";

const DeleteChatModal = ({ isOpen, onClose ,deletehandler }) => {

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
          <h4>Delete Chat</h4>
            <div className="modal-header">
              <h6>Are you sure you want to delete the chat ? </h6>
            </div>
            <div className="end-chat-modal-footer">
              <button className="end-chat-modal-button" onClick={onClose}>
                No
              </button>
              <button className="end-chat-modal-button" onClick={deletehandler}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteChatModal;
