import React, { useState , useEffect} from "react";
import { Pagination } from "react-bootstrap";
import { FaEye } from "react-icons/fa";


const dummyChatData = [
  {
    clientId: "01",
    client: "ABC Corporation",
    site: "Site 1",
    activeChatCount: 3,
  },
  {
    clientId: "02",
    client: "XYZ Inc.",
    site: "Site 2",
    activeChatCount: 2,
  },
  {
    clientId: "03",
    client: "123 Services",
    site: "Site 3",
    activeChatCount: 5,
  },
];

function TableCom(props) {
  const [show, setShow] = useState(false);
  const [chats , setChats] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setChats(dummyChatData);
  }, []);

  const handleRowClick = (client_id) => {
    const link = `/chatlist/${client_id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const totalPages = Math.ceil(dummyChatData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentChatData = dummyChatData.slice(startIndex, endIndex);

  return (
    <>
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Client</th>
            <th scope="col">Site</th>
            <th scope="col">Active Chat Count</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
        {currentChatData.length > 0 ? (
          currentChatData.map((item, index) => (
            <tr key={index}>
                  <td 
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.clientId)}
                  >
                    {item.client}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.clientId)}
                  >
                    {item.site}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.clientId)}
                  >
                    {item.activeChatCount}</td>
                </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">There is no data.</td>
          </tr>
        )}
      </tbody>
      </table>
      <Pagination  style={{ display: "flex", justifyContent: "center", marginTop: "20px" }} >
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {Array.from({ length: totalPages }).map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </>
  );
}

export default TableCom;
