import React, { useState } from "react";
import { FaPen, FaTrash, FaDownload } from "react-icons/fa";
import DeleteModal from "../Modal/DeleteModal/DeleteModal";
import AreaModal from "../Modal/AreaModal/AreaModal";
import { saveAs } from "file-saver";
import { Pagination } from "react-bootstrap";


function AreaTable({ areas, setAreas, loading }) {
  const [show, setShow] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [area, setArea] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const userType = localStorage.getItem("userType");

  const handleShowView = (area) => {
    setArea(area);
    setShow(true);
  };

  const [deleteShow, setdeleteShow] = useState(false);
  const handleShowDelete = (id) => {
    setDeleteid(id);
    setdeleteShow(true);
  };

  const handleDownload = (imageUrl, name) => {
    saveAs(imageUrl, `${name}.png`);
  };
  
  const handleImageDownload = async (item, name) => {
    const imgUrl = `https://backend.clean-connect.com.au/site/site-area/barcode-image/${item.id}`;
    try {
      const response = await fetch(imgUrl);
      const blob = await response.blob();
      saveAs(blob, `${name}.png`);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const totalPages = Math.ceil(areas.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentAreas = areas.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  return (
    <>
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr style={{ cursor: "pointer" }}>
            <th scope="col">Area </th>
            <th scope="col">QR code</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentAreas.length > 0 ? (
            currentAreas.map((item, index) => (
              <tr key={index}>
                <td>{item.area_name}</td>
                <td>
                  <img
                    src={item.barcode_image}
                    alt="Sample Image"
                    style={{ width: "120px", height: "120px" }}
                    onClick={() =>
                      handleDownload(item.barcode_image, item.area_name)
                    }
                  />
                </td>
                <td>
                  {userType === "SuperAdmin" && (
                    <div style={{ margin: "10px 0" }}>
                      <FaPen
                        className="action-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleShowView(item)}
                      />
                    </div>
                  )}
                  {userType === "SuperAdmin" && (
                    <div style={{ margin: "10px 0" }}>
                      <FaTrash
                        className="delete-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleShowDelete(item.id)}
                      />
                    </div>
                  )}
                  <div style={{ margin: "10px 0" }}>
                    <FaDownload
                      className="action-icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleImageDownload(item, item.area_name)}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">There is no data.</td>
            </tr>
          )}
        </tbody>
        <AreaModal
          show={show}
          setShow={setShow}
          area={area}
          setAreas={setAreas}
          Title="update"
        />
        <DeleteModal
          show={deleteShow}
          title={"area-delete"}
          id={deleteid}
          setAreas={setAreas}
          setdeleteShow={setdeleteShow}
        />
      </table>
      {currentAreas.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "250px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </>
  );
}

export default AreaTable;
