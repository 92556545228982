import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import PRIMARY from "../../../Theme/palette";
import { createAdminRecommendation } from "../../../API/axios";
import { Spinner } from "react-bootstrap";

function RecommendationCreateForm(props) {
  const initialFormData = {
    title: "",
    description: "",
    document: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isDisabled, setIsDisabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.title.trim()) {
      errors.title = "Title is required.";
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required.";
    }
    if (formData.document) {
      const allowedTypes = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      if (!allowedTypes.includes(formData.document.type)) {
        errors.document =
          "Please upload a valid document file (PDF, JPG, JPEG, PNG).";
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    if (validateForm() && props.id) {
      setisButtonLoading(true);
      const createRequestBody = {
        site_recommendation_id: props.id,
        title: formData.title,
        description: formData.description,
        file: formData.document,
      };

      console.log("request body", createRequestBody);

      createAdminRecommendation(createRequestBody)
        .then((response) => {
          console.log("response : ", response);
          if (response.status) {
            props.handleClose();
            props.setShow(!props.show);
            props.setNewRecommendation();
            toast.success("Site Recommendation created successfully!");
          } else {
            toast.error("Error creating recommendation");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            error.response?.data?.error || "Error creating recommendation"
          );
          setisButtonLoading(false);
          setIsDisabled(false);
        });
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
              {validationErrors.title && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.title}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows={5}
                value={formData.description}
                onChange={handleInputChange}
              />
              {validationErrors.description && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.description}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group>
            <Form.Label>File Upload:</Form.Label>
            <Form.Control
              type="file"
              name="document"
              onChange={handleFileChange}
              accept=".pdf, .jpg, .jpeg, .png"
            />
            {validationErrors.document && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.document}
              </p>
            )}
          </Form.Group>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default RecommendationCreateForm;
