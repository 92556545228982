import React, { useState } from "react";
import Layout from "../../Componants/Layout/Layout";
import FilterAssignTasks from "../../Componants/FilterAssignTasks/FilterAssignTasks";
import AssignTasksTable from "../../Componants/AssignTasksTable/AssignTasksTable";
import PRIMARY from "../../Theme/palette";
import { FaPlus } from "react-icons/fa";
import CreateTaskModal from "../../Componants/Modal/CreateTaskModal/CreateTaskModal";
import { Link } from "react-router-dom";

function AssignTasks() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterPriority, setFilterPriority] = useState("");

  const buttonStyle = {
    border: "none",
    backgroundColor: PRIMARY.darkGray,
    padding: "0 20px",
    color: PRIMARY.white,
    fontWeight: "500",
    marginBottom: "10px",
    width: "140px",
    height: "40px",
  };

  return (
    <>
      <div className="cleaner-topic">
        <p>Tasks</p>
        <div>
          <div>
            <button
              onClick={handleShow}
              className="add-button"
              // style={buttonStyle}
            >
              Add New +
              {/* <FaPlus
                style={{ margin: "5px 10px 5px 10px", fontSize: "12px" }}
              /> */}
            </button>
          </div>
        </div>
      </div>
      <div>
        <FilterAssignTasks
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          setFilterPriority={setFilterPriority}
          filterSite={filterSite}
          filterClient={filterClient}
        />
        <AssignTasksTable
          filterClient={filterClient}
          filterSite={filterSite}
          filterPriority={filterPriority}
          show={show}
          setShow={setShow}
        />
      </div>
      <CreateTaskModal show={show} setShow={setShow} Title="create" />
    </>
  );
}

export default AssignTasks;
