import React, { useState, useEffect } from "react";
import { GetClients } from "../../../API/axios";
import { FaEye } from "react-icons/fa";
import { Pagination } from "react-bootstrap";

function TableSiteManual(props) {
  
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    const GetAllClients = async () => {
      try {
        const Data = await GetClients();
        setClients(Data.data.sites);
        setFilteredClients(Data.data.sites);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        // Create dummy data when fetching fails (for development or error handling)
        const dummyFilteredData = [
          {
            ID: 1,
            site_id: 101,
            site_name: "Dummy Site 1",
            site_address: "123 Dummy St, Dummy City",
            // Add more properties as per your actual data structure
          },
          {
            ID: 2,
            site_id: 102,
            site_name: "Dummy Site 2",
            site_address: "456 Test Ave, Testville",
            // Add more properties as per your actual data structure
          },
          // Add more dummy objects as needed
        ];
        setFilteredClients(dummyFilteredData);
      }
    };

    GetAllClients();
  }, []);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);


  const handleRowClick = (id) => {
    const link = `/sitemanual-documents/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  useEffect(() => {
    const filteredData = clients.filter((item) => {
      const clientMatch =
        props.filterSite === "" || item.site_name === props.filterSite;
      const siteMatch =
        props.filterSiteAdd === "" || item.site_address === props.filterSiteAdd;
      return clientMatch && siteMatch;
    });
    const data =
      props.filterSiteAdd !== "" || props.filterSite !== ""
        ? filteredData
        : clients;
    setFilteredClients(data);
    setFilterChanged(true);
  }, [props.filterSite, props.filterSiteAdd, clients]);

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFilteredClients = filteredClients.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
    style={{
      margin: "10px 12px",
      padding: "0 40px 0 0",
      marginLeft: "12px",
      marginRight: "12px",
    }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col" style={{ width: "450px"}}>Client</th>
            <th scope="col" style={{ width: "500px"}}>Site</th>
            <th scope="col" style={{ width: "200px"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentFilteredClients.length > 0 ? (
            currentFilteredClients.map((item) => (
              <tr key={item.ID}>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(item.site_id)}
                >
                  {item.site_name}
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(item.site_id)}
                  title={item.site_address}
                >
                  {truncateSiteName(item.site_address)}
                </td>
                <td>
                  <FaEye
                    className="action-icon"
                    style={{ cursor: "pointer", margin: "10px" }}
                    onClick={() => handleRowClick(item.site_id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentFilteredClients.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>

  );
}

export default TableSiteManual;
