import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PRIMARY from "../../../Theme/palette";
import React, { useState } from "react";
import { manualMoveFromHistory, manualMoveToHistory } from "../../../API/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

function ManualMoveModal(props) {
  const [isButtonLoading, setisButtonLoading] = useState(false);

  const handleClose = async () => {
    setisButtonLoading(true);
    if (props.title === "manual-move-history") {
      await manualMoveToHistory(props.siteID, props.manualId)
        .then((response) => {
          if (response.status) {
            toast.success(response.message);
            props.setmoveModalShow(!props.moveModalShow);
            props.setManuals();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setmoveModalShow(!props.moveModalShow);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }
    if (props.title === "manual-move-from-history") {
      await manualMoveFromHistory(props.siteID, props.manualId)
        .then((response) => {
          if (response.status) {
            toast.success(response.message);
            props.setmoveModalShow(!props.moveModalShow);
            props.setNewManualsHistory();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setmoveModalShow(!props.moveModalShow);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }
  };

  const handleCloseButton = async () => {
    props.setmoveModalShow(!props.moveModalShow);
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={props.moveModalShow}
        onHide={handleCloseButton}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.title === "manual-move-history"
              ? "Confirm move to history"
              : "Confirm move back to manual"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {props.title === "manual-move-history"
              ? "Are you sure you want to move to history this record!"
              : "Are you sure you want to move back to site manual!"}{" "}
          </div>
          <br />
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              onClick={handleClose}
              style={{
                backgroundColor: PRIMARY.black,
                color: PRIMARY.white,
                fontWeight: "500",
                border: "none",
                padding: "10px 60px",
              }}
            >
              {isButtonLoading ? (
                <>
                  <Spinner animation="border" size="sm" />{" "}
                </>
              ) : props.title === "manual-move-history" ? (
                "Move to history"
              ) : (
                "Move back to site manual"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ManualMoveModal;
