import React, { useState } from "react";
import "./IncedentReport.css";
import FilterInci from "../../Componants/Filters/IncidentReport/Filter";
import TableIncident from "../../Componants/Tables/TableIncident/Table";
import PRIMARY from "../../Theme/palette";

function IncidentReport() {
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterDate, setFilterDate] = useState("");


  const handleClick = () => {
    const link = `/incidentreport/History`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  return (
    <>
      <div className="cleaner-topic">
        <p>Incident Report </p>
        <button
          onClick={handleClick}
          className="add-button"
          // style={{
          //   border: "none",
          //   backgroundColor: PRIMARY.darkGray,
          //   padding: "0 20px",
          //   color: PRIMARY.white,
          //   fontWeight: "500",
          // }}
        >
          History
        </button>
      </div>
      <div>
        <FilterInci
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          setFilterDate={setFilterDate}
          filterClient={filterClient}
        />
        <TableIncident
          filterClient={filterClient}
          filterSite={filterSite}
          filterDate={filterDate}
        />
      </div>
    </>
  );
}

export default IncidentReport;
