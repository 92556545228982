import React from "react";
import CleanerDetails from "../../Componants/CleanerProfile/CleanerProfile";
import { useParams } from "react-router-dom";

function CleanerProfile() {

  const { id } = useParams();

  return (
    <div>
      <div>
        <p className="cleaner-topic">Cleaner Profile</p>
      </div>
      <div>
        <CleanerDetails id={id}/>
      </div>
    </div>
  );
}
export default CleanerProfile;
