import React, { useState, useEffect } from "react";
import "./SiteManualDocuments.css";
import TableHistory from "../../Componants/Tables/TableTool/TableHistory";
import { useParams } from "react-router-dom";
import { getSiteById } from "../../API/axios";

function SiteManualHistory() {
  const { id } = useParams();
  const [site, setSite] = useState("");

  useEffect(() => {
    getSiteById(id)
      .then((response) => {
        setSite(response.sites[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  });

  return (
    <>
      <div className="toolboxdoc-topic">
        <p>Site Manual History </p>
      </div>
      <div className="toolboxdoc-topic">
        <div className="toolbox-lower-topic">
          <div className="toolbox-topic">
            <span style={{ fontWeight: "bold" }}>Client : </span>
            <span>{site.site_name}</span>
          </div>
          <div className="toolbox-topic">
            <span style={{ fontWeight: "bold" }}>Site : </span>
            <span>{site.site_address}</span>
          </div>
        </div>
      </div>

      <div>
        <TableHistory siteID={id} />
      </div>
    </>
  );
}

export default SiteManualHistory;
