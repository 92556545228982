import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { getShowList ,assignCleaners, updateShowList } from "../../../API/axios";
import PRIMARY from "../../../Theme/palette";
import { ToastContainer, toast } from "react-toastify";
import { FaRunning } from "react-icons/fa";

const AddColoumnTable = (props) => {

  const [showList, setShowList] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);

  useEffect(() => {
    GetShowList();
  }, []);

  const GetShowList = async () => {
    try {
      const Data = await getShowList();
      setShowList(Data.data.showColoumn[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

//   useEffect(() => {
//     const filteredData = showList.filter((item) => {
//       const siteMatch =
//         props.site === "" || item.site_id !== props.site;
//       return siteMatch;
//     });
//     setFilteredCleaners(filteredData);
//   }, [showList]);

  const actionHeaderStyle = {
    textAlign: "right",
    paddingRight: "30px",
  };

  const assignColoumn = (id,type) => {
    var formData=formData={
        "id" : showList.id,
        "email" : showList.email,
        "profile" : showList.profile,
        "start" : showList.start_date,
        "end" : showList.end_date
      };
      
    switch (type) {
        case "Email":
            formData["email"]= showList.email===0 ? 1 : 0;
            break;
        case "Profile":
            formData["profile"]= showList.profile===0 ? 1 : 0;
            break;
        case "Start":
            formData["start"]= showList.start_date===0 ? 1 : 0;
            break;
        case "End":
            formData["end"]= showList.end_date===0 ? 1 : 0;
            break;
      }
    updateShowList(formData)
        .then((response) => {
            toast.success(response.message);
            GetShowList();
            props.setReload(!props.reload);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.response.data.message);
        });

  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Coloumn Name</th>
          <th style={actionHeaderStyle}>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td>Email</td>
        <td style={actionHeaderStyle}>
              <div className="text-right">
                <button
                    className="btn btn-primary"
                    onClick={() => assignColoumn(showList.id,"Email")}
                    style={{
                    border: "none",
                    backgroundColor: PRIMARY.darkGray,
                    padding: "5px 5px",
                    color: PRIMARY.white,
                    fontWeight: "300",
                    width:"60px",
                    fontSize:"12px",
                    zIndex:5
                    }}
                >
                    {showList.email===1 ? "Remove" : "Add"}
                </button>
              </div>
            </td>
        </tr>
        <tr>
        <td>Profile</td>
        <td style={actionHeaderStyle}>
              <div className="text-right">
                <button
                    className="btn btn-primary"
                    onClick={() => assignColoumn(showList.id,"Profile")}
                    style={{
                    border: "none",
                    backgroundColor: PRIMARY.darkGray,
                    padding: "5px 5px",
                    color: PRIMARY.white,
                    width:"60px",
                    fontWeight: "300",
                    fontSize:"12px",
                    zIndex:5
                    }}
                >
                    {showList.profile===1 ? "Remove" : "Add"}
                </button>
              </div>
            </td>
        </tr>
        {/* <tr>
        <td>Start Date</td>
        <td style={actionHeaderStyle}>
              <div className="text-right">
                <button
                    className="btn btn-primary"
                    onClick={() => assignColoumn(showList.id,"Start")}
                    style={{
                    border: "none",
                    backgroundColor: PRIMARY.darkGray,
                    padding: "5px 5px",
                    color: PRIMARY.white,
                    fontWeight: "300",
                    width:"60px",
                    fontSize:"12px",
                    zIndex:5
                    }}
                >
                    {showList.start_date===1 ? "Remove" : "Add"}
                </button>
              </div>
            </td>
        </tr> */}
        {/* <tr>
        <td>End Date</td>
        <td style={actionHeaderStyle}>
              <div className="text-right">
                <button
                    className="btn btn-primary"
                    onClick={() => assignColoumn(showList.id,"End")}
                    style={{
                    border: "none",
                    backgroundColor: PRIMARY.darkGray,
                    padding: "5px 5px",
                    color: PRIMARY.white,
                    fontWeight: "300",
                    width:"60px",
                    fontSize:"12px",
                    zIndex:5
                    }}
                >
                    {showList.end_date===1 ? "Remove" : "Add"}
                </button>
              </div>
            </td>
        </tr> */}
      </tbody>
    </Table>
  );
};

export default AddColoumnTable;
