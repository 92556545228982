import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import PRIMARY from "../../../Theme/palette";
import { addSiteArea,updateSiteArea } from "../../../API/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

function AreaCreateForm({site,handleClose,setAreas,area,title}) {
  const [formData, setFormData] = useState(title=='create'?{
    site_area: "",
    site: site,
  }:{
    site_area: area.area_name,
    area_id: area.id,
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: '', 
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

    // Validation logic
    const validateForm = () => {
      const errors = {};
  
      if (!formData.site_area.trim()) {
        errors.site_area = "Area Name is required.";
      }
    
      setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    };
  
    const handleSubmit = async(event) => {
      event.preventDefault();
      console.log(formData);
      if (validateForm()) {
        setisButtonLoading(true);

        // Submit the form data to your backend or perform further actions
        console.log("Form data submitted:", formData);
        if(title=='create'){
        await addSiteArea(formData).then((response) => {
          if(response.status){
            handleClose();
            toast.success(response.message);
            setAreas();
          }
        })
        .catch((error) => {
          handleClose();
          toast.error(error.response.data.message);
          setisButtonLoading(false);
        });
      }
      if(title=='update'){
        await updateSiteArea(formData).then((response) => {
          if(response.status){
            handleClose();
            toast.success(response.message);
            setAreas();
          }
        })
        .catch((error) => {
          handleClose();
          toast.error("Error Updating The Site Area.");
        });
      }
      }
    };

  return (
    <Container>
      <ToastContainer/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Area Name:</Form.Label>
              <Form.Control
                type="text"
                name="site_area"
                value={formData.site_area}
                onChange={handleInputChange}
              />
              {validationErrors.site_area && (
                <p style={{fontSize:'12px', padding:'2px 0', margin:'0'}} className="text-danger">{validationErrors.site_area}</p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />
                {' '}
              </>
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default AreaCreateForm;
