import React, { useState, useEffect } from "react";
import "./ToolboxReaders.css";
import ToolboxReader from "../../Componants/Toolbox/ToolboxReader";
import { useParams } from "react-router-dom";
import { getDocumentDetailsbyID } from "../../API/axios";

function ToolReader() {
  const { id } = useParams();
  const [siteToolReaders, setsiteToolReaders] = useState([]);

  useEffect(() => {
    getDocumentDetailsbyID(id)
      .then((response) => {
        console.log("readers", response.data.data[0].document_link);
        setsiteToolReaders(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <div className="cleaner-topic">
        <p>PDF Document</p>
      </div>
      <div>
        <ToolboxReader siteToolboxReaders={id} />
      </div>
    </div>
  );
}

export default ToolReader;
