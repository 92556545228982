import React, { useState } from "react";
import { FaTrash, FaEye } from "react-icons/fa";
import DeleteModal from "../../Modal/DeleteModal/DeleteModal";
import HistoryModal from "../../Modal/HistoryModal/HistorymoveModal";
import { Pagination } from "react-bootstrap";

function TableHistory({ siteToolboxHistory, setNewToolboxHistory, loading }) {
  const [deleteShow, setdeleteShow] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [historyid, setHistoryid] = useState("");
  const [historyShow, sethistoryShow] = useState(false);
  const itemsPerPage = 10;
  const userType = localStorage.getItem("userType");

  // Function to handle row click and navigate
  const handleRowClick = (id) => {
    const link = `/toolbox-readers/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const handleShowDelete = (id) => {
    setDeleteid(id);
    setdeleteShow(true);
  };

  const handleMoveBackFromHistory = (id) => {
    setHistoryid(id);
    sethistoryShow(true);
  };

  const totalPages = Math.ceil(siteToolboxHistory.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentSiteToolboxHistory = siteToolboxHistory.slice(
    startIndex,
    endIndex
  );
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  return (
    <>
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Document Names</th>
            <th scope="col">Read Count</th>
            {userType === "SuperAdmin" && <th scope="col">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentSiteToolboxHistory.length > 0 ? (
            currentSiteToolboxHistory.map((item) => (
              <tr key={item.id}>
                <td
                  onClick={() => handleRowClick(item.document_id)}
                  style={{ cursor: "pointer" }}
                >
                  {item.document_name}
                </td>
                <td
                  onClick={() => handleRowClick(item.document_id)}
                  style={{ cursor: "pointer" }}
                >
                  {item.user_count}
                </td>
                <td>
                  {userType === "SuperAdmin" && (
                    <button
                      className="moveHistory-button"
                      onClick={() =>
                        handleMoveBackFromHistory(item.document_id)
                      }
                    >
                      Re-move
                    </button>
                  )}
                  <FaEye
                    className="action-icon"
                    style={{ cursor: "pointer", margin: "10px" }}
                    onClick={() => handleRowClick(item.document_id)}
                  />
                  {userType === "SuperAdmin" && (
                    <FaTrash
                      className="delete-icon"
                      onClick={() => handleShowDelete(item.document_id)}
                      style={{ cursor: "pointer", margin: "10px" }}
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data</td>
            </tr>
          )}
        </tbody>
        <DeleteModal
          show={deleteShow}
          title={"toolboxhistory-delete"}
          id={deleteid}
          setNewToolboxHistory={setNewToolboxHistory}
          setdeleteShow={setdeleteShow}
        />
        <HistoryModal
          show={historyShow}
          title={"toolbox-movebackfromhistory"}
          id={historyid}
          // setToolBox={setToolBox}
          // setNewManuals={setNewManuals}
          sethistoryShow={sethistoryShow}
        />
      </table>

      {currentSiteToolboxHistory.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </>
  );
}

export default TableHistory;
