import React from 'react';
import OrderViewDetail from '../../Componants/Orders/OrderViewDetail';
import HelpViewDetail from '../../Componants/Helps/HelpViewDetail';

function HelpView () {

    return (
        <div>
            <div>
                <p className="cleaner-topic">Help Details</p>
            </div>
        <HelpViewDetail />
        </div>
    )

};
export default HelpView;