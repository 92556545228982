import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import PRIMARY from "../../Theme/palette";
import {
  updateCleaner,
  CreateHelp,
} from "../../API/axios";

function HelpCreateForm(props) {
  const [formData, setFormData] = useState({
    module: "",
    heading: "",
    description: "",
    video: null,
  });

  //const [open, setopen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;

    setValidationErrors({
      ...validationErrors,
      video: "",
    });
    
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.module) {
      errors.module = "Module is required";
    }

    if (!formData.heading) {
      errors.heading = "Title is required";
    }

    if (!formData.description) {
      errors.description = "Description is required";
    }

    if (!formData.video) {
      errors.video = "Video file is required";
    } else if (!formData.video.type.startsWith("video/")) {
      errors.video = "Please upload a valid video file";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    if (validateForm()) {
      try {
        setisButtonLoading(true);


        // Build request body
        const requestBody = {
          module: formData.module,
          heading: formData.heading,
          description: formData.description,
          videos: formData.video,
        };

        if (props.title === "update") {
          // Update request
          await updateCleaner(props.cleanerId, requestBody); // Replace with actual API call
        } else {
          // Create request
          //await new Promise((resolve) => setTimeout(resolve, 13476));

          await CreateHelp(requestBody)
            .then((response) => {
              console.log(response);
              if (response.status) {
                toast.success(" uploaded successfully");
                props.handleClose();
                props.setShow(!props.show);
              } else {
                toast.error("Error uploading document, try again...");
                props.setShow(true);
              }
            })
            .catch((error) => {
              console.error("Error uploading document:", error);
              toast.error("Error uploading document, try again...");
              props.setShow(true);
            });
        }
      } catch (error) {
        toast.error("Error uploading document, try again...");
        console.error("Error submitting form:", error);
        props.setShow(true);
      } finally {
        setisButtonLoading(false);
        setIsDisabled(false);
      }
    } else {
      setisButtonLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Module:</Form.Label>
              <Form.Select
                name="module"
                value={formData.module}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.module}
              >
                <option value="">Select a Module</option>
                <option value="Communication">Communication</option>
                <option value="Site manual">Site Manual</option>
                <option value="Recommendation">Recommendation</option>
                <option value="Incident Report">Incident Report</option>
                <option value="Site information">Site information</option>
                <option value="Tool box talk">Tool box talk</option>
                <option value="Stock">Stock</option>
                {/* Populate options */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {validationErrors.module}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                name="heading"
                value={formData.heading}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.heading}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.heading}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.description}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group>
            <Form.Label>Upload Video:</Form.Label>
            <Form.Control
              type="file"
              name="video"
              onChange={handleFileChange}
              accept="video/*"
              isInvalid={!!validationErrors.video}
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.video}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
      {/* <ToastContainer /> */}
    </Container>
  );
}

export default HelpCreateForm;
