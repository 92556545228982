import React, { useEffect, useState } from "react";
import { getAllCleanerWorkingHrs } from "../../API/axios";
import { Pagination } from "react-bootstrap";

function TableClientSummary(props) {
  const [cleaners, setCleaners] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";
  const [loading, setLoading] = useState(true);

  const limitUserNames = (userNames) => {
    if (userNames === null) {
      return "No cleaners";
    }
    const MAX_NAMES = 5;
    const namesArray = userNames.split(",").map((name) => name.trim());

    if (namesArray.length <= MAX_NAMES) {
      return userNames;
    }

    const limitedNames = namesArray.slice(0, MAX_NAMES).join(",");
    return `${limitedNames} ...`;
  };

  useEffect(() => {
    const GetAllCleaners = async () => {
      try {
        const Data = await getAllCleanerWorkingHrs();
        setCleaners(Data.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    GetAllCleaners();
  }, [props.filterName, props.filterSite]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = cleaners.filter((item) => {
      const nameMatch =
        props.filterName === "" || item.site_name === props.filterName;

      for (let i = 0; i < item.site_addresses.length; i++) {
        const siteMatches =
          props.filterSite === "" ||
          item.site_addresses[i].site_address === props.filterSite;
      }

      return nameMatch;
    });

    setFilteredCleaners(filteredData);
    setFilterChanged(true);
  }, [props.filterName, props.filterSite, cleaners]);

  const totalPages = Math.ceil(filteredCleaners.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFilteredCleaners = filteredCleaners.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            {!isClient && (
              <th scope="col" style={{ width: "150px", minWidth: "150px" }}>
                Client
              </th>
            )}
            {!isClient && (
              <th scope="col" style={{ width: "150px", minWidth: "150px" }}>
                Site
              </th>
            )}
            <th scope="col" style={{ width: "250px", minWidth: "250px" }}>
              Cleaners
            </th>
            <th
              scope="col"
              style={{ textAlign: "center", width: "150px", minWidth: "150px" }}
            >
              Working Hours (hrs)
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentFilteredCleaners.length > 0 ? (
            currentFilteredCleaners.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    {!isClient && (
                      <td rowSpan={item.site_addresses.length}>
                        {item.site_name}
                      </td>
                    )}
                    {!isClient && (
                      <td title={item.site_addresses[0].site_address}>
                        {truncateSiteName(item.site_addresses[0].site_address)}
                      </td>
                    )}
                    <td title={item.site_addresses[0].f_names}>
                      {limitUserNames(item.site_addresses[0].f_names)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.site_addresses[0].total_work_hours
                        ? item.site_addresses[0].total_work_hours.toFixed(1)
                        : 0}
                    </td>
                  </tr>

                  {item.site_addresses
                    .slice(1)
                    .map((addressItem, addressIndex) => (
                      <tr key={addressIndex}>
                        {!isClient && (
                          <td title={addressItem.site_address}>
                            {truncateSiteName(addressItem.site_address)}
                          </td>
                        )}
                        <td title={addressItem.f_names}>
                          {limitUserNames(addressItem.f_names)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {addressItem.total_work_hours
                            ? addressItem.total_work_hours.toFixed(1)
                            : 0}
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan={!isClient ? "4" : "3"}>There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentFilteredCleaners.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default TableClientSummary;
