import React, { useState, useEffect } from "react";
import { FaTrash, FaEye } from "react-icons/fa";
import DeleteModal from "../../Modal/DeleteModal/DeleteModal";
import { getAuditDocumentBySiteId } from "../../../API/axios";
import { Modal } from "react-bootstrap";
import { Pagination } from "react-bootstrap";

function TableAuditDocuments(props) {
  const [deleteShow, setdeleteShow] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const isClient = localStorage.getItem("userType") === "Client";
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    getAuditDocumentBySiteId(props.id)
      .then((response) => {
        setDocuments(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const setNewAudit = () => {
    getAuditDocumentBySiteId(props.id)
      .then((response) => {
        setDocuments(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleRowClick = (item) => {
    setShowModal(true);
    setSelectedDocument(item);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowDelete = (id) => {
    setDeleteid(id);
    setdeleteShow(true);
  };

  const totalPages = Math.ceil(documents?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentDocuments = documents?.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  return (
    <>
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Document ID</th>
            <th scope="col">Document Name</th>
            <th scope="col" style={{ width: "150px" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentDocuments?.length > 0 ? (
            currentDocuments.map((item) => (
              <tr key={item.audit_id}>
                <td
                  onClick={() => handleRowClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item.audit_id}
                </td>
                <td
                  onClick={() => handleRowClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item.document_name.replace(/\.pdf$/, "")}
                </td>
                <td>
                  <FaEye
                    className="action-icon"
                    style={{ cursor: "pointer", margin: "10px" }}
                    onClick={() => handleRowClick(item)}
                  />
                  {!isClient && (
                    <FaTrash
                      className="delete-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => handleShowDelete(item.audit_id)}
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data</td>
            </tr>
          )}
        </tbody>
        <DeleteModal
          show={deleteShow}
          title={"audit-delete"}
          id={deleteid}
          setNewAudit={setNewAudit}
          setdeleteShow={setdeleteShow}
        />
      </table>
      {currentDocuments?.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "290px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedDocument?.document_name.replace(/\.pdf$/, "")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view">
            <iframe
              title={selectedDocument?.document_name.replace(/\.pdf$/, "")}
              src={selectedDocument?.document}
              width="100%"
              height="500"
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default TableAuditDocuments;
