import React, { useEffect, useState } from "react";
import "./Mycleaner.css";
import FilterClientProgress from "../../Componants/FilterClientProgress/FilterClientSummary";
import { BarChart } from "@mui/x-charts";
import { Row } from "react-bootstrap";
import { clientProgress } from "../../API/axios";

function ClientProgress() {
  const currentYear = new Date().getFullYear();
  const [filterName, setFilterName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterYear, setFilterYear] = useState(currentYear);
  const [filterMonth, setFilterMonth] = useState(1);
  const [siteId, setSiteId] = useState("");
  const [contractedTime, setContractedTime] = useState([0, 0, 0, 0, 0]);
  const [diliverdTime, setDiliverdTime] = useState([0, 0, 0, 0, 0]);

  const isClient = localStorage.getItem("userType") === "Client";
  const userID = localStorage.getItem("ID");

  useEffect(() => {
    if (filterYear && filterMonth) {
      const GetCleanerrProgress = async () => {
        try {
          let selectedSiteId = siteId;
          if (isClient) {
            selectedSiteId = userID; // set siteId to userID if user type is client
          }

          const data = await clientProgress(
            selectedSiteId,
            filterYear,
            filterMonth
          );

          //map response before map in graph
          if (
            data.data.weekNumbers &&
            data.data.weekNumbers.length > 0 &&
            data.data.data &&
            data.data.data.length > 0
          ) {
            const templateRecord = data.data.data[0];
            let mappedData = [];

            data.data.weekNumbers.forEach((weekNumber) => {
              const record = data.data.data.find(
                (data) => data.week_no === weekNumber
              );

              if (!record) {
                const newRecord = {
                  ...templateRecord,
                  total_contracted_hours_per_week: 0,
                  total_delivered_hours_per_week: 0,
                  week_no: weekNumber,
                };
                mappedData.push(newRecord);
              } else {
                mappedData.push(record);
              }
            });

            // const sortedProgress = mappedData.sort(
            //   (a, b) => a.week_no - b.week_no
            // );

            const contractedHourArray = mappedData.map((obj) =>
              obj.total_contracted_hours_per_week !== null
                ? obj.total_contracted_hours_per_week
                : 0
            );
            const deliveredHourArray = mappedData.map((obj) =>
              obj.total_delivered_hours_per_week !== null
                ? obj.total_delivered_hours_per_week
                : 0
            );

            setContractedTime(contractedHourArray);
            setDiliverdTime(deliveredHourArray);
          } else {
            setContractedTime([0, 0, 0, 0, 0]);
            setDiliverdTime([0, 0, 0, 0, 0]);
          }
        } catch (err) {
          console.log("Error fetching data:", err);
        }
      };

      GetCleanerrProgress();
    } else {
      setContractedTime([0, 0, 0, 0, 0]);
      setDiliverdTime([0, 0, 0, 0, 0]);
    }
  }, [siteId, filterMonth, filterYear, isClient, userID]);

  return (
    <>
      <div className="cleaner-topic">
        <p>Client Progress</p>
      </div>
      <FilterClientProgress
        filterName={filterName}
        filterSite={filterSite}
        filterMonth={filterMonth}
        filterYear={filterYear}
        setFilterName={setFilterName}
        setFilterSite={setFilterSite}
        setFilterMonth={setFilterMonth}
        setSiteId={setSiteId}
        setFilterYear={setFilterYear}
        siteId={siteId}
      />
      <div style={{ margin: "0 50px", fontSize: "12px" }}>
        <Row style={{ padding: "5px 0" }}>
          <div
            style={{
              width: "50px",
              height: "20px",
              backgroundColor: "#02B2AF",
              margin: "0 5px",
            }}
          ></div>
          Contracted Time(hrs)
        </Row>
        <Row>
          <div
            style={{
              width: "50px",
              height: "20px",
              backgroundColor: "#2E96FF",
              margin: "0 5px",
            }}
          ></div>
          Deliverd Time(hrs)
        </Row>
      </div>
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: ["Week 01", "Week 02", "Week 03", "Week 04", "Week 05", "Week 06"],
          },
        ]}
        series={[{ data: contractedTime }, { data: diliverdTime }]}
        width={1000}
        height={400}
      />
    </>
  );
}

export default ClientProgress;
