import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PRIMARY from "../../../Theme/palette";
import TableAddCleanerList from "../../Tables/TableAddCleanerList/TableAddCleanerList";

function AssignCleanerModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Assign Cleaners
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableAddCleanerList site={props.site} setReload={props.setReload} reload={props.reload}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AssignCleanerModal;
