import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import PRIMARY from "../../../Theme/palette";
import { GetClients, SendEmails } from "../../../API/axios";
import { Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

function IncidentEmailCreateForm(props) {
  
  const initialFormData = {
    fileName:props.selectedIncident.incident_id,
    downloadURL:props.selectedIncident.file,
    emails: [],
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isDisabled, setIsDisabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');


  // ---------------------
  const handleEmailsChange = (e) => {
    setNewEmail(e.target.value);
    setValidationErrors({ ...validationErrors, emails: '' });
  };

  // const saveEmails = () => {
  //   const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newEmail)
  //   if (!checkEmail) {
  //     setValidationErrors({ ...validationErrors, emails: 'Please enter a valid email.' });
  //     return;
  //   }

  //   setEmails([...emails, newEmail]);
  //   setNewEmail(''); 

  //   setFormData({
  //     ...formData,
  //     emails: [...emails, newEmail],
  //   })
  // };

  const saveEmails = () => {
    const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newEmail);
    if (!checkEmail) {
      setValidationErrors({ ...validationErrors, emails: 'Please enter a valid email.' });
      return;
    }
  
    if (!emails.includes(newEmail)) {
      setEmails([...emails, newEmail]);
      setNewEmail(''); 
  
      setFormData({
        ...formData,
        emails: [...formData.emails, newEmail],
      });
    } else {
      setValidationErrors({ ...validationErrors, emails: 'Email already added.' });
    }
  };
  

  const handleRemoveFile = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);

    setFormData({
      ...formData,
      emails: [newEmails],
    })
  };


  // -----------------------------------


  const validateForm = () => {
    const errors = {};
    if (formData.emails.length == 0 || formData.emails.length == null) {
      errors.emails = "email is required.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);

    if (validateForm()) {
      try {
        setisButtonLoading(true);

        // Build request body
        const requestBody = {
          from:'admincleanerconnect@gmail.com',
          to:formData.emails,
          subject:`Submited Incident Report - incident_id:${formData.fileName}`,
          filename:`${formData.fileName}.pdf`,
          downloadURL:formData.downloadURL,
        };

        // Create request
        await SendEmails(requestBody)
          .then((response) => {
            if (response.status) {
              props.handleClose();
              props.setShowBox(!props.showBox);
              toast.success(" Shared document");
            } else {
              toast.error("Error Share document");
            }
          })
          .catch((error) => {
            console.error("Error Sharing document:", error);
            toast.error("Error Sharing document");
          });
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setisButtonLoading(false);
        setIsDisabled(false);
      }
    } else {
      setisButtonLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Enter the email address:</Form.Label>
              <Form.Control
              type="text"
              name="emails"
              value={newEmail}
              onChange={handleEmailsChange}
            />
            {validationErrors.emails && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.emails}
              </p>
            )}
            <br />
            <Button onClick={saveEmails} style={{width:'100px',backgroundColor:'black',border:'None'}}>Add Email</Button>
            {emails.length > 0 && (
              <div style={{ marginTop: "5px" }}>
                <strong>Selected Emails:</strong>
                <ul>
                  {emails.map((name, index) => (
                    <li key={index} style={{ marginBottom: "5px" }}>
                      {name}
                      <Button
                        variant="danger"
                        size="sm"
                        style={{ marginLeft: "5px", borderRadius: "10px" }}
                        onClick={() => handleRemoveFile(index)}
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            </Form.Group>
          </Col>
        </Row>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </Form>
      <ToastContainer />
    </Container>
  );
}


export default IncidentEmailCreateForm;
