import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap"; // Assuming you are using Bootstrap components

function Samplesp() {
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    startDate: "",
    endDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear validation errors when the user starts typing
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  return (
    <div>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <Form.Group>
            <Form.Label>Start Date:</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
            />
            {validationErrors.startDate && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.startDate}
              </p>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>End Date:</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              min={formData.startDate}
            />
            {validationErrors.endDate && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.endDate}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

export default Samplesp;
