import React, { useState , useEffect} from "react";
import Layout from "../../Componants/Layout/Layout";
import "./Communication.css";
import ChatListLayout from "./ChatListLayOut";
import SingleChatCard from "./SingleChatCard";
import {getData} from "../../API/fireStoreService";
import { GetClients } from "../../API/axios";
import { getSiteById } from "../../API/axios";


function Communication() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [chats,setChats] = useState([]);
  const [clients,setClients] = useState([]);

  const id = localStorage.getItem('ID');
  const admin = localStorage.getItem('Admin');

  console.log("seha",id);

  const [selectedChat, setSelectedChat] = useState([]);

  const fetchData = async () => {
    try {
      const result = await getData();
      console.log("seha result:",result);
      if(admin==='superAdmin'){
        setChats(result);
      }else{
        console.log("seha result:",result);
        const filteredChats = result.filter(chat => chat.site_id == id);
        console.log(filteredChats);
        setChats(filteredChats);
      }  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCleints = async () => {
    try {
      const result = await GetClients();
        setClients(result.data.sites);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const changeSelectedChat =()=>{
    setSelectedChat([]);
  }

  const getClient =async()=>{
    try {
      const result=await getSiteById(id);
      console.log(result.sites[0].site_name);
      localStorage.setItem('Name',result.sites[0].site_name);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
    fetchCleints();
    getClient();
  }, []);

  console.log("selectedChat :",selectedChat);

  return (
    <ChatListLayout chats={chats} setSelectedChat={setSelectedChat} fetchData={fetchData} clients={clients}>
      <div className="sidebar">
      </div>
      <div className="content">
        <SingleChatCard selectedChat={selectedChat} setSelectedChat={changeSelectedChat} fetchData={fetchData}/>
      </div>
    </ChatListLayout>
  );
}

export default Communication;
