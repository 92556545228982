import React, { useState, useEffect } from "react";
import Layout from "../../Componants/Layout/Layout";
import "./Mysites.css";
import FilterSite from "../../Componants/FilterSite/FilterSite";
import SiteTable from "../../Componants/SiteTable/Table";
import ClientModal from "../../Componants/Modal/ClientModal/ClientModal";
import PRIMARY from "../../Theme/palette";

function Mysites() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [load, setLoad] = useState(false);
  const [userRole, setUserRole] = useState("");
  useEffect(() => {
    // Retrieve user role from local storage
    const roleFromStorage = localStorage.getItem("userType");
    setUserRole(roleFromStorage);
  }, []);

  const UserClient = userRole !== "Client";
  const isClient = localStorage.getItem("userType") === "Client";
  return (
    <>
      <div className="cleaner-topic">
        <div>{UserClient ? <p>My Clients & Sites</p> : <p>My Sites</p>}</div>
        {!isClient && (
          <button
            onClick={handleShow}
            className="add-button"
            // style={{
            //   border: "none",
            //   backgroundColor: PRIMARY.darkGray,
            //   padding: "0 20px",
            //   color: PRIMARY.white,
            //   fontWeight: "500",
            // }}
          >
            Add New +
          </button>
        )}
      </div>
      <div>
        <FilterSite
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          filterClient={filterClient}
          filterSite={filterSite}
        />
        <SiteTable
          filterClient={filterClient}
          show={show}
          filterSite={filterSite}
        />
        <ClientModal show={show} setShow={setShow} Title="create" />
      </div>
    </>
  );
}

export default Mysites;
