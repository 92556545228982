import React, { useState, useEffect } from "react";
import "./SiteManualReader.css";
import { getSiteManualById } from "../../API/axios";

function SiteManualReader(props) {
  const [manual, setManual] = useState("");
  const readers = props.siteManualReaders;
  const manualId = props.id;
  const [loading, setLoading] = useState(true);

  const GetManualById = async () => {
    try {
      const Data = await getSiteManualById(manualId);
      setManual(Data.site_manuals[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetManualById();
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0];
  };

  return (
    <>
      <div className="manual-wrapper">
        <div className="manual">
          <div className="pdf-view">
            <iframe
              title="Sample PDF"
              src={manual.url}
              width="100%"
              height="500"
            />
          </div>
          <div className="">
            <div className="cleaner-topic">
              <h5>Readers List</h5>
            </div>
            <br></br>
            <div className="readerDetail">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Emp No</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Signed Date</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {loading ? (
                      <tr>
                        <td colSpan="5">Loading...</td>
                      </tr>
                    ) : readers.length > 0 ? (
                      readers.map((item) => (
                        <tr key={item.id}>
                          <td>{item.emp_no}</td>
                          <td>{item.f_name} {item.l_name}</td>
                          <td>{formatDate(item.read_at)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No readers..</td>
                      </tr>
                    )}
                  </React.Fragment>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SiteManualReader;
