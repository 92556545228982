import React, { useEffect, useState } from "react";
import { GetScandList } from "../../API/axios";
import { Pagination } from "react-bootstrap";

function DailyRecordTable(props) {
  const [scandlist, setScandList] = useState([]);
  const [filteredScanList, setFilteredScanList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";

  useEffect(() => {
    const GetAllScandList = async () => {
      try {
        const Data = await GetScandList();
        console.log(Data.data.data);
        setScandList(Data.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    GetAllScandList();
  }, []);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = scandlist.filter((item) => {
      const nameMatch =
        props.filterClient === "" || item.site_name === props.filterClient;
      const siteMatch =
        props.filterSite === "" || item.site_address === props.filterSite;

      const datePickerDate = props.filterDate
        ? new Date(props.filterDate).toISOString().split("T")[0]
        : "";
      const scanDate = item.scanned_time
        ? new Date(item.scanned_time).toISOString().split("T")[0]
        : "";
      const dateMatch =
        props.filterDate === null ||
        props.filterDate === "" ||
        !item.scanned_time ||
        scanDate === datePickerDate;

      return nameMatch && siteMatch && dateMatch;
    });

    setFilteredScanList(filteredData);
    setFilterChanged(true);
  }, [props.filterClient, props.filterSite, props.filterDate, scandlist]);

  const totalPages = Math.ceil(filteredScanList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFilteredScanList = filteredScanList.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table class="table" style={{ margin: "10px 20px" }}>
        <thead class="thead-light">
          <tr>
            {!isClient && (
              <th scope="col" style={{ width: "150px" }}>
                Client
              </th>
            )}
            {!isClient && (
              <th scope="col" style={{ width: "250px" }}>
                Site
              </th>
            )}
            <th scope="col" style={{ width: "200px" }}>
              Area
            </th>
            <th scope="col" style={{ width: "200px" }}>
              Cleaner Name
            </th>
            <th scope="col" style={{ width: "200px" }}>
              Scan date & time
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentFilteredScanList.length > 0 ? (
            currentFilteredScanList.map((item, index) => {
              const scanDate = new Date(item.scanned_time);
              const formattedDate = scanDate.toLocaleDateString();
              const formattedTime = scanDate.toLocaleTimeString();

              return (
                <tr key={index}>
                  {!isClient && <td>{item.site_name}</td>}
                  {!isClient && (
                    <td title={item.site_address}>
                      {truncateSiteName(item.site_address)}
                    </td>
                  )}
                  <td>{item.area_name}</td>
                  <td>
                    {item.f_name} {item.l_name}
                  </td>
                  <td>
                    <div className="col">
                      <div className="raw">Scan date: {formattedDate}</div>
                      <div className="raw">Scan Time: {formattedTime}</div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentFilteredScanList.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default DailyRecordTable;
