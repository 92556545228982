import React from "react";
import Modal from "react-bootstrap/Modal";
import CleanerDocumentUpdateForm from "./CleanerDocumentUpdateForm";


function CleanerDocumentUpdateModal(props) {
  const handleClose = () => props.setUpdateShow(false);

  return (
    <>
      <Modal show={props.updateShow} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.title === "update" ? "Update Document" : " "}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CleanerDocumentUpdateForm
            title={props.title}
            updateShow={props.updateShow}
            setUpdateShow={props.setUpdateShow}
            handleClose={handleClose}
            item={props.item}
            GetDocumentsByCleanerId={props.GetDocumentsByCleanerId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CleanerDocumentUpdateModal;
