import React, { useState, useEffect } from "react";
import { FaTrash, FaEye } from "react-icons/fa";
import DeleteModal from "../../Modal/DeleteModal/DeleteModal";
import HistoryModal from "../../Modal/HistoryModal/HistoryModal";
import { getCleanerToolBoxBySiteID } from "../../../API/axios";
import { Pagination } from "react-bootstrap";

function TableDocument(siteID) {
  const [deleteShow, setdeleteShow] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [historyShow, sethistoryShow] = useState(false);
  const [historyid, setHistoryid] = useState("");
  const [toolBoxTalkDocumets, setToolBoxTalkDocuments] = useState([]);
  const userType = localStorage.getItem("userType");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const site_id = siteID.siteID;

  useEffect(() => {
    async function fetchToolBoxTalkDocuments() {
      try {
        const response = await getCleanerToolBoxBySiteID(site_id);
        setToolBoxTalkDocuments(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching toolBoxTalk:", error);
        setLoading(false);
      }
    }

    fetchToolBoxTalkDocuments();
  }, []);

  const handleMoveToHistory = (id) => {
    setHistoryid(id);
    sethistoryShow(true);
  };

  const handleDelete = (id) => {
    setDeleteid(id);
    setdeleteShow(true);
  };

  // Function to handle row click and navigate
  const handleRowClick = (item) => {
    const link = `/toolbox-readers/${item}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const totalPages = Math.ceil(toolBoxTalkDocumets.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentToolBoxTalk = toolBoxTalkDocumets.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const setToolBox = () => {
    getCleanerToolBoxBySiteID(site_id)
      .then((res) => {
        setToolBoxTalkDocuments(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const isClient = localStorage.getItem("userType") === "Client";

  return (
    <>
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Document Names</th>
            <th scope="col">Read Count</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentToolBoxTalk.length > 0 ? (
            currentToolBoxTalk.map((item, index) => (
              <tr key={index}>
                <td
                  onClick={() => handleRowClick(item.document_id)}
                  style={{ cursor: "pointer" }}
                >
                  {item.document_name}
                </td>
                <td
                  onClick={() => handleRowClick(item.document_id)}
                  style={{ cursor: "pointer" }}
                >
                  {item.user_count}
                </td>
                <td>
                  {/* "Move to History" button */}
                  {userType === "SuperAdmin" && (
                    <button
                      className="moveHistory-button"
                      onClick={() => handleMoveToHistory(item.document_id)}
                    >
                      Move to History
                    </button>
                  )}
                  <FaEye
                    className="action-icon"
                    style={{ cursor: "pointer", margin: "10px" }}
                    onClick={() => handleRowClick(item.document_id)}
                  />
                  {/* "Delete" button */}
                  {!isClient && (
                    <FaTrash
                      className="delete-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => handleDelete(item.document_id)}
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data</td>
            </tr>
          )}
        </tbody>
        <DeleteModal
          show={deleteShow}
          title={"toolbox-delete"}
          id={deleteid}
          setToolBox={setToolBox}
          // setNewManuals={setNewManuals}
          setdeleteShow={setdeleteShow}
        />
        <HistoryModal
          show={historyShow}
          title={"toolbox-history"}
          id={historyid}
          setToolBox={setToolBox}
          // setNewManuals={setNewManuals}
          sethistoryShow={sethistoryShow}
        />
      </table>
      {currentToolBoxTalk.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </>
  );
}

export default TableDocument;
