import React, { useState, useEffect } from "react";
import { GetClients } from "../../../API/axios";
import { FaTrash, FaEye } from "react-icons/fa";
import DeleteModal from "../../Modal/DeleteModal/DeleteModal";
import { Pagination } from "react-bootstrap";

function TableSiteinfo(props) {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [siteId, setSiteId] = useState(null);
  const [filteredClients, setFilteredClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";

  const GetAllClients = async () => {
    try {
      const Data = await GetClients();
      setClients(Data.data.sites);
      setFilteredClients(Data.data.sites);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllClients();
  }, []);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  const [deleteShow, setdeleteShow] = useState(false);
  const handleShowDelete = (id) => {
    setSiteId(id);
    setdeleteShow(true);
  };

  const handleRowClick = (id) => {
    const link = `/siteinformation/cleaners/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const handleMoveToAudit = (id) => {
    const link = `/siteinformation/audit-documents/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  useEffect(() => {
    const filteredData = clients.filter((item) => {
      const clientMatch =
        props.filterSite === "" || item.site_name === props.filterSite;
      const siteMatch =
        props.filterSiteAdd === "" || item.site_address === props.filterSiteAdd;
      return clientMatch && siteMatch;
    });
    var data =
      props.filterSiteAdd !== "" || props.filterSite !== ""
        ? filteredData
        : clients;
    setFilteredClients(data);
    setFilterChanged(true);
  }, [props.filterSite, props.filterSiteAdd, clients]);

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentInformations = filteredClients.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

 

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <>
      <div
        style={{
          margin: "10px 12px",
          padding: "0 15px",
          marginLeft: "12px",
          marginRight: "12px",
        }}
      >
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th scope="col" style={{ width: "400px" }}>
                Client
              </th>
              <th scope="col" style={{ width: "350px" }}>
                Site
              </th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : currentInformations.length > 0 ? (
              currentInformations.map((item) => (
                <tr key={item.ID}>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.site_id)}
                  >
                    {item.site_name}
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                    onClick={() => handleRowClick(item.site_id)}
                    title={item.site_address}
                  >
                    {truncateSiteName(item.site_address)}
                  </td>

                  <td>
                    <button
                      className="moveHistory-button"
                      onClick={() => handleMoveToAudit(item.site_id)}
                    >
                      Audit Documents
                    </button>
                    <FaEye
                      className="action-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => handleRowClick(item.site_id)}
                    />
                    {!isClient && (
                      <FaTrash
                        className="delete-icon"
                        style={{ cursor: "pointer", margin: "10px" }}
                        onClick={() => handleShowDelete(item.site_id)}
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">There is no data.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {currentInformations.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
      <DeleteModal
        show={deleteShow}
        title={"site"}
        id={siteId}
        setAreas={GetAllClients}
        setdeleteShow={setdeleteShow}
      />
    </>
  );
}

export default TableSiteinfo;
