import React, { useEffect, useState } from "react";
import "./Filter.css";
import { GetClients } from "../../../API/axios";

function FilterSiteInfo(props) {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const GetAllClients = async () => {
      try {
        const Data = await GetClients();
        setClients(Data.data.sites);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllClients();
  }, []);

  const uniqueClientNames = Array.from(
    new Set(clients.map((item) => item.site_name))
  );
  const uniqueSiteNames = Array.from(
    new Set(
      clients
        .filter((items) => items.site_name === props.filterSite)
        .map((item) => item.site_address)
    )
  );
  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterSite(localStorage.getItem("user"));
    props.setFilterSiteAdd(localStorage.getItem("Location"));
  }
  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
                props.setFilterSiteAdd("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {uniqueClientNames ? (
                uniqueClientNames.map((name, index) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSiteAdd(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {uniqueSiteNames ? (
                uniqueSiteNames.map((name, index) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterSiteInfo;
