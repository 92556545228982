import React, { useEffect, useState } from "react";
import { FaPen, FaTrash, FaEye } from "react-icons/fa";
import ClientModal from "../Modal/ClientModal/ClientModal";
import DeleteModal from "../Modal/DeleteModal/DeleteModal";
import { GetClients } from "../../API/axios";
import PRIMARY from "../../Theme/palette";
import { Pagination } from "react-bootstrap";

function SiteTable(props) {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState([]);
  const [siteId, setSiteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";

  const handleShowView = (item) => {
    setItem(item);
    setShow(true);
  };

  const [deleteShow, setdeleteShow] = useState(false);
  const handleShowDelete = (id) => {
    setSiteId(id);
    setdeleteShow(true);
  };

  const handleRowClick = (id) => {
    const link = `/mysites/sitearea/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const GetAllClients = async () => {
    try {
      const Data = await GetClients();
      //console.log(Data.data.sites);
      setClients(Data.data.sites);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllClients();
  }, []);

  useEffect(() => {
    GetAllClients();
  }, [props.show, props.filterClient, props.filterClient]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = clients.filter((item) => {
      const clientMatch =
        props.filterClient === "" || item.site_name === props.filterClient;
      const siteMatch =
        props.filterSite === "" || item.site_address === props.filterSite;
      return clientMatch && siteMatch;
    });

    setFilteredClients(filteredData);
    setFilterChanged(true);
  }, [props.filterClient, props.filterSite, clients]);

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClients = filteredClients.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const limitUserNames = (userNames) => {
    if (userNames === null) {
      return "No cleaners";
    }
    const MAX_NAMES = 3;
    const namesArray = userNames.split(",").map((name) => name.trim());

    if (namesArray.length <= MAX_NAMES) {
      return userNames;
    }

    const limitedNames = namesArray.slice(0, MAX_NAMES).join(", ");
    return `${limitedNames} ...`;
  };

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div>
      <div style={{ margin: "10px 12px", padding: "0 4px" }}>
        <table className="table">
          <thead className="thead-light">
            <tr>
              {!isClient && (
                <th
                  scope="col"
                  style={{
                    verticalAlign: "top",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Client
                </th>
              )}
              {!isClient && (
                <th
                  scope="col"
                  style={{
                    verticalAlign: "top",
                    width: "150px",
                    minWidth: "150px",
                  }}
                >
                  Site
                </th>
              )}
              <th
                scope="col"
                style={{
                  width: "300px",
                  minWidth: "300px",
                  verticalAlign: "top",
                }}
              >
                Cleaners
              </th>
              <th scope="col">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "340px",
                    minWidth: "340px",
                  }}
                >
                  Cleaning Schedule
                </div>
                <div
                  className="row"
                  style={{ fontSize: "12px", backgroundColor: "lightyellow" }}
                >
                  <div className="col">SUN</div>
                  <div className="col">MON</div>
                  <div className="col">TUE</div>
                  <div className="col">WED</div>
                  <div className="col">THU</div>
                  <div className="col">FRI</div>
                  <div className="col">SAT</div>
                </div>
              </th>
              <th
                scope="col"
                style={{
                  width: "150px",
                  minWidth: "150px",
                  verticalAlign: "top",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">Loading...</td>
              </tr>
            ) : currentClients.length > 0 ? (
              currentClients.map((item) => (
                <tr key={item.ID}>
                  {!isClient && (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(item.site_id)}
                    >
                      {item.site_name}
                    </td>
                  )}
                  {!isClient && (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(item.site_id)}
                      title={item.site_address}
                    >
                      {truncateSiteName(item.site_address)}
                    </td>
                  )}
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.site_id)}
                    title={item.user_data}
                  >
                    {limitUserNames(item.user_data)}
                  </td>
                  <td
                    className="row"
                    style={{
                      backgroundColor: PRIMARY.lighterGray,
                      fontSize: "12px",
                      marginLeft: "3px",
                    }}
                  >
                    <div className="col">{item.sun ? item.sun : "-"}</div>
                    <div className="col">{item.mon ? item.mon : "-"}</div>
                    <div className="col">{item.tues ? item.tues : "-"}</div>
                    <div className="col">{item.wed ? item.wed : "-"}</div>
                    <div className="col">{item.thur ? item.thur : "-"}</div>
                    <div className="col">{item.fri ? item.fri : "-"}</div>
                    <div className="col">{item.satur ? item.satur : "-"}</div>
                  </td>
                  <td>
                    <FaEye
                      className="action-icon"
                      style={{ cursor: "pointer", margin: "8px" }}
                      onClick={() => handleRowClick(item.site_id)}
                    />
                    {!isClient && (
                      <FaPen
                        className="action-icon"
                        style={{ cursor: "pointer", margin: "8px" }}
                        onClick={() => handleShowView(item)}
                      />
                    )}
                    {!isClient && (
                      <FaTrash
                        className="delete-icon"
                        style={{ cursor: "pointer", margin: "8px" }}
                        onClick={() => handleShowDelete(item.site_id)}
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">There is no data.</td>
              </tr>
            )}
          </tbody>
          <ClientModal
            show={show}
            setShow={setShow}
            item={item}
            GetAllClients={GetAllClients}
            Title="update"
          />
          <DeleteModal
            show={deleteShow}
            title={"site"}
            id={siteId}
            setAreas={GetAllClients}
            setdeleteShow={setdeleteShow}
          />
        </table>
      </div>
      {currentClients.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "20px",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default SiteTable;
