import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PRIMARY from "../../../Theme/palette";
import CreateTaskForm from "./CreateTaskForm";

function CreateTaskModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.Title === "create" ? "Add Task" : "Update Task"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateTaskForm
            title={props.Title}
            handleClose={handleClose}
            setShow={props.setShow}
            show={props.show}
            getAllClients={props.GetAllClients}
            item={props.item}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateTaskModal;
