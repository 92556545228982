import React, { useEffect, useState } from "react";
import "./Filter.css";
import { GetClients, getAllStock } from "../../API/axios";

function FilterStockSummary(props) {
  const currentYear = new Date().getFullYear();
  const numberOfPreviousYears = 5;

  const yearOptions = Array.from(
    { length: numberOfPreviousYears + 1 },
    (_, index) => currentYear - numberOfPreviousYears + index
  );
  const [stock, setStock] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const GetAllCleaners = async () => {
      try {
        const Data = await GetClients();
        //console.log(Data.data.sites);
        setClients(Data.data.sites);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const GetAllStock = async () => {
      try {
        const Data = await getAllStock();
        //console.log(Data.data.data);
        setStock(Data.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetAllCleaners();
    GetAllStock();
  }, []);

  const uniqueClientNames = Array.from(
    new Set(clients.map((item) => item.site_name))
  );
  const uniqueAdd = Array.from(
    new Set(
      clients
        .filter((items) => items.site_name === props.filterName)
        .map((item) => item.site_address)
    )
  );
  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterName(localStorage.getItem("user"));
    props.setFilterSite(localStorage.getItem("Location"));
  }
  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterName(e.target.value);
                props.setFilterSite("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {stock.length > 0 ? (
                uniqueClientNames.map((item) => {
                  return <option value={item}>{item}</option>;
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {stock.length > 0 ? (
                uniqueAdd.map((item) => {
                  return <option value={item}>{item}</option>;
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
        <div className="col">
          <select
            value={props.filterYear}
            className="form-select"
            onChange={(e) => {
              props.setFilterYear(e.target.value);
            }}
          >
            <option value="">Select Year</option>
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFilterSiteAdd(e.target.value);
            }}
          >
            <option selected value={""}>
              Select Month
            </option>
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default FilterStockSummary;
