import React, { useEffect, useState } from "react";
import {
  getAllCleanerWorking,
  getAllCleanerWorkingHrsBySite,
} from "../../API/axios";
import { Pagination } from "react-bootstrap";

function TableCleanerSummary(props) {
  const [cleaners, setCleaners] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterChanged, setFilterChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const isClient = localStorage.getItem("userType") === "Client";

  useEffect(() => {
    const GetAllCleaners = async () => {
      try {
        if (props.filterMonth) {
          const Data = await getAllCleanerWorkingHrsBySite(
            props.firstName,
            props.lastName,
            props.filterYear,
            props.filterMonth
          );
          setCleaners(Data.data.data);
          setLoading(false);
        } else {
          const Data = await getAllCleanerWorking();
          setCleaners(Data.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    GetAllCleaners();
  }, [props.filterMonth]);

  const groupData = (data) => {
    return data.reduce((acc, curr) => {
      const existingCleaner = acc.find(
        (cleaner) => cleaner.emp_no === curr.emp_no && cleaner.id === curr.id
      );

      const siteDetails = {
        site_address: curr.site_address || null,
        site_id: curr.site_id || null,
        site_name: curr.site_name || null,
        total_working_hours_on_site: curr.total_working_hours_on_site || 0,
      };

      if (existingCleaner) {
        existingCleaner.sites.push(siteDetails);
      } else {
        acc.push({
          emp_no: curr.emp_no,
          f_name: curr.f_name,
          id: curr.id,
          l_name: curr.l_name,
          total_working_hours: curr.total_working_hours || 0,
          sites: [siteDetails],
        });
      }

      return acc;
    }, []);
  };

  const groupedData = groupData(cleaners);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = groupedData.filter((item) => {
      const firstNameMatch =
        props.firstName === "" || item.f_name === props.firstName;
      const lastNameMatch =
        props.lastName === "" || item.l_name === props.lastName;

      return firstNameMatch && lastNameMatch;
    });

    setFilteredCleaners(filteredData);
    setFilterChanged(true);
  }, [props.firstName, props.lastName, cleaners]);

  const totalPages = Math.ceil(filteredCleaners.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFilteredCleaners = filteredCleaners.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col" style={{ verticalAlign: "top" }}>
              Id
            </th>
            <th scope="col" style={{ verticalAlign: "top" }}>
              First Name
            </th>
            <th scope="col" style={{ verticalAlign: "top" }}>
              Last Name
            </th>
            {!isClient && (
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "top" }}
              >
                Total Working Hours
              </th>
            )}
            {!isClient && (
              <th
                scope="col"
                style={{
                  width: "180px",
                  minWidth: "180px",
                  verticalAlign: "top",
                }}
              >
                Client
              </th>
            )}
            {!isClient && (
              <th
                scope="col"
                style={{
                  width: "180px",
                  minWidth: "180px",
                  verticalAlign: "top",
                }}
              >
                Site
              </th>
            )}
            <th
              scope="col"
              style={{
                width: "150px",
                minWidth: "150px",
                textAlign: "center",
                verticalAlign: "top",
              }}
            >
              {!isClient ? "Working Hours" : "Total Working Hours"}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentFilteredCleaners.length > 0 ? (
            currentFilteredCleaners.map((item) =>
              item.sites.map((site, index) => (
                <tr key={`${item.emp_no}-${index}`}>
                  {index === 0 ? (
                    <>
                      <td>{item.emp_no}</td>
                      <td>{item.f_name}</td>
                      <td>{item.l_name}</td>
                      {!isClient && (
                        <td style={{ textAlign: "center" }}>
                          {item.total_working_hours.toFixed(2)}
                        </td>
                      )}
                    </>
                  ) : (
                    <td colSpan={4}></td>
                  )}
                  {!isClient && (
                    <td>
                      {site.site_name ? site.site_name : "Haven't Client!"}
                    </td>
                  )}
                  {!isClient && (
                    <td title={site.site_address}>
                      {site.site_address
                        ? truncateSiteName(site.site_address)
                        : "Haven't Site!"}
                    </td>
                  )}
                  <td style={{ textAlign: "center" }}>
                    {site.total_working_hours_on_site.toFixed(2)}
                  </td>
                </tr>
              ))
            )
          ) : (
            <tr>
              <td colSpan="6">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentFilteredCleaners.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "290px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

// const parseSiteWorkHours = (siteWorkHoursString) => {
//   if (!siteWorkHoursString) {
//     return {};
//   }
//   const siteWorkHoursArray = siteWorkHoursString.split(", ");
//   const siteWorkHoursObject = {};

//   siteWorkHoursArray.forEach((entry) => {
//     const [siteName, hours] = entry.split(":");
//     const trimmedSiteName = siteName.trim();
//     const formattedHours = hours ? parseFloat(hours.trim().split(" ")[0]) : 0;
//     siteWorkHoursObject[trimmedSiteName] =
//       (siteWorkHoursObject[trimmedSiteName] || 0) + formattedHours;
//   });

//   return siteWorkHoursObject;
// };

// const calculateTotalWorkHours = (siteWorkHoursString) => {
//   if (!siteWorkHoursString) {
//     return { totalWorkHoursPerSite: [], totalWorkHoursOverall: 0 };
//   }

//   const siteWorkHoursObject = parseSiteWorkHours(siteWorkHoursString);
//   const totalWorkHoursPerSite = Object.entries(siteWorkHoursObject).map(
//     ([siteName, hours]) => ({
//       siteName,
//       totalHours: hours,
//     })
//   );
//   const totalWorkHoursOverall = totalWorkHoursPerSite.reduce(
//     (total, { totalHours }) => total + parseFloat(totalHours),
//     0
//   );
//   return {
//     totalWorkHoursPerSite,
//     totalWorkHoursOverall: totalWorkHoursOverall.toFixed(2),
//   };
// };

export default TableCleanerSummary;
