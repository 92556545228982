import React, { useState, useEffect } from "react";
import Layout from "../../Componants/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import "./TaskInformation.css";
import { gettaskByID, gettaskCommentByID } from "../../API/axios";

function TaskInformation() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [senderUserName, setSenderUserName] = useState("");
  const [senderSiteAddress, setSenderSiteAddress] = useState("");
  const [receiverFirstName, setReceiverFirstName] = useState("");
  const [receiverLastName, setReceiverLastName] = useState("");
  const [deadline, setDeadline] = useState("");
  const [task_tittle, setTask_tittle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [comment, setComment] = useState("");
  const [created_date, setCreated_date] = useState("");
  const [loading, setLoading] = useState(true);
  const [cdescription, setCDescription] = useState("");
  const [cimage, setCImage] = useState("");
  const [cfname, setCFname] = useState("");
  const [clname, setCLname] = useState("");
  const [cemail, setCEmail] = useState("");
  const [cemp_no, setCEmp_no] = useState("");
  const [csdate, setCSdate] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    const getSiteDetails = () => {
      gettaskByID(id)
        .then((response) => {
          const siteData = response.tasks[0];

          var deadlineDate = siteData.deadline;

          if (deadlineDate && deadlineDate.length >= 10) {
            var trimmedDeadlineDate = deadlineDate.substring(0, 10);
          } else {
            console.log("Invalid Deadline date format");
          }

          setDeadline(trimmedDeadlineDate);
          setSenderUserName(siteData.senderUserName);
          setSenderSiteAddress(siteData.senderSiteAddress);
          setReceiverFirstName(siteData.receiverFirstName);
          setReceiverLastName(siteData.receiverLastName);
          setTask_tittle(siteData.task_tittle);
          setDescription(siteData.description);
          setPriority(siteData.priority);
          setCreated_date(siteData.created_date.substring(0, 10));
          setImage(siteData.image);
          console.log(siteData.senderUserName);
        })
        .catch((error) => {
          console.log(error);
        });

      //

      gettaskCommentByID(id)
        .then((response) => {
          setComment(response.comments);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      // .then((response) => {
      // setCDescription(response.comments[0].description);
      // setCImage(response.comments[0].image);
      // setCFname(response.comments[0].f_name);
      // setCLname(response.comments[0].l_name);
      // setCEmail(response.comments[0].email);
      // setCEmp_no(response.comments[0].emp_no);
      // setCSdate(response.comments[0].start_date);
      // })
      // .catch((error) => {
      //   console.error(error);
      // });
      // .then((response) => {
      //   const sitecomments = response.comments[0];

      //   setComment(sitecomments);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    };

    getSiteDetails();
  }, [id]);

  return (
    <>
      <div className="cleaner-topic">
        <p>Task Information</p>
      </div>
      <div className="site-info-container">
        <div className="left-container">
          <div className="upper-left">
            <h5>Image</h5>
            <object
              data={image}
              type="application/pdf"
              width="100%"
              height="100%"
            ></object>
            {/* <img src={image} width={100} alt="" className="p-logo" /> */}
          </div>
          <div className="lower-left">
            <h5>Comments</h5>

            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Description</th>
                  {/* <th scope="col">Name</th> */}
                  <th
                    scope="col"
                    style={{
                      width: "130px",
                      minWidth: "130px",
                      verticalAlign: "top",
                    }}
                  >
                    Image
                  </th>
                </tr>
              </thead>
              <tbody>
                <React.Fragment>
                  {loading ? (
                    <tr>
                      <td colSpan="5">Loading...</td>
                    </tr>
                  ) : comment.length > 0 ? (
                    comment.map((item) => (
                      <tr key={item.id}>
                        <td>{item.comment_description}</td>
                        {/* <td>{item.f_name + " " + item.l_name}</td> */}
                        <td>
                          {" "}
                          <img
                            src={item.comment_image}
                            alt=""
                            className="manualLogImg"
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No data</td>
                    </tr>
                  )}
                </React.Fragment>
              </tbody>
            </table>
          </div>
        </div>
        <div className="right-container">
          <Form.Label>Client : {senderUserName}</Form.Label>
          <br></br>
          <Form.Label>Site : {senderSiteAddress}</Form.Label>
          <br></br>
          <Form.Label>Task Title : {task_tittle}</Form.Label>
          <br></br>
          {/* <Form.Label>Task Assignee : {receiverFirstName}</Form.Label>
          <br></br> */}
          <Form.Label>Assignee : {receiverFirstName}</Form.Label>
          <br></br>
          <Form.Label>Deadline : {deadline}</Form.Label>
          <br></br>

          <Form.Label>
            Priority : {priority === 1 && "High"}
            {priority === 2 && "Medium"}
            {priority === 3 && "Low"}
          </Form.Label>
          <br></br>

          <Form.Label>Task Description : {description}</Form.Label>
          <br></br>
          {/* 
          <h6>Site: {senderSiteAddress}</h6>
          <h6>Task Title: {task_tittle}</h6>
          <h6>Task Assignee: {receiverFirstName}</h6>
          <h6>Assign By: {receiverLastName}</h6>
          <h6>Deadline: {deadline}</h6>
          <h6>Priority: {priority}</h6>
          <h6>Task Description: {description}</h6> */}
        </div>
      </div>
    </>
  );
}
export default TaskInformation;
