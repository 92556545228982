import React, { useState } from "react";
import "../MySites/Mysites.css";
import TimeOnSiteProgressTable from "../../Componants/TimeOnSiteProgressTable/TimeOnSiteProgressTable";
import FilterTimeOnSiteProgress from "../../Componants/FilterTimeOnSiteProgress/Filter";

function TimeOnSiteProgress() {
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterDate, setFilterDate] = useState("");

  return (
    <>
      <div className="cleaner-topic">
        <p>Time On Site Progress</p>
      </div>

      <div>
        <FilterTimeOnSiteProgress
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          setFilterDate={setFilterDate}
          filterClient={filterClient}
        />
        <TimeOnSiteProgressTable
          filterClient={filterClient}
          filterSite={filterSite}
          filterDate={filterDate}
        />
      </div>
    </>
  );
}

export default TimeOnSiteProgress;
