import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { GetCleaners ,assignCleaners, getAllCleanerUsersEach } from "../../../API/axios";
import PRIMARY from "../../../Theme/palette";
import { ToastContainer, toast } from "react-toastify";

const TableAddCleanerList = (props) => {

  const [cleaners, setCleaners] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);

  useEffect(() => {
    GetAllCleaners();
  }, []);

  const GetAllCleaners = async () => {
    try {
      const Data = await getAllCleanerUsersEach();
      setCleaners(Data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const filteredData = cleaners.filter((item) => {
      return props.site === "" || item.site_id !== props.site;
    });

    const matches = cleaners.filter((item) => {
      return props.site === "" || item.site_id === props.site;
    });
    
    const userData = filteredData.filter((item) => {
      return !matches.some((match) => match.user_id === item.user_id);
    });
    
    const groupedCleaners = {};
    const allocatedUserIds = new Set(); 
  
    userData.forEach((item) => {
      if (!allocatedUserIds.has(item.user_id)) {
        if (!groupedCleaners[item.user_id]) {
          groupedCleaners[item.user_id] = [];
        }
        groupedCleaners[item.user_id].push(item);
      }
      allocatedUserIds.add(item.user_id);
    });
  
    const groupedCleanersArray = Object.values(groupedCleaners).reduce((acc, val) => acc.concat(val), []);
  
    setFilteredCleaners(groupedCleanersArray);
  }, [cleaners, props.site]);
  

  const actionHeaderStyle = {
    textAlign: "right",
    paddingRight: "30px",
  };

  const assignCleaner = (id) => {
    var formData = {"id":id , "site":props.site}
    assignCleaners(formData)
        .then((response) => {
            toast.success(response.message);
            GetAllCleaners();
            props.setReload(!props.reload);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.response.data.message);
        });

  }

  return (
    <Table striped bordered hover>
      <thead>
        {filteredCleaners.length>0 ?
        <tr>
          <th>Cleaner Name</th>
          <th style={actionHeaderStyle}>Actions</th>
        </tr>: <tr>No Any Unassigned Cleaners For This Site</tr>}
      </thead>
      <tbody>
        {filteredCleaners.length>0 &&
        filteredCleaners.map((cleaner, index) => (
          <tr key={index}>
            <td>{cleaner.f_name} {cleaner.l_name}</td>
            <td style={actionHeaderStyle}>
              <div className="text-right">
                <button
                    className="btn btn-primary"
                    onClick={() => assignCleaner(cleaner.user_id)}
                    style={{
                    border: "none",
                    backgroundColor: PRIMARY.darkGray,
                    padding: "5px 5px",
                    color: PRIMARY.white,
                    fontWeight: "300",
                    fontSize:"12px",
                    zIndex:5
                    }}
                >
                    Add to Site
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableAddCleanerList;
