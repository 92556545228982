import React, { useState } from "react";
import "./Help.css";
import PRIMARY from "../../Theme/palette";
import FilterHelp from "../../Componants/Filters/Help/Filter";
import TableHelp from "../../Componants/Tables/TableHelp/Table";
import HelpModal from "../../Componants/Modal/HelpModal";

function Help() {
  const [show, setShow] = useState(false);
  const [open, setopen] = useState(false);
  const handleShow = () => setShow(true);
  const [filterSite, setFilterSite] = useState("");
  const userType = localStorage.getItem("userType");
  return (
    <>
      <div className="cleaner-topic">
        <p>Help </p>
        {userType === "SuperAdmin" && (
          <button
            onClick={handleShow}
            className="add-button"
            // style={{
            //   border: "none",
            //   backgroundColor: PRIMARY.darkGray,
            //   padding: "0 20px",
            //   color: PRIMARY.white,
            //   fontWeight: "500",
            // }}
          >
            Add New +
          </button>
        )}
      </div>
      <div>
        <FilterHelp setFilterSite={setFilterSite} />
        <TableHelp filterSite={filterSite} show={show}/>
        <HelpModal
          show={show}
          setShow={setShow}
          open={setopen}
          Title="create"
        />
      </div>
    </>
  );
}

export default Help;
