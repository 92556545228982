import React, { useState } from 'react';
import {  FaPencilAlt, FaTrash } from 'react-icons/fa';
//import { Pagination } from 'react-bootstrap';

function AssignTaskHistoryTable () {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

    const data = [
        {
            client: "Sports Arena",
            site: "555 Stadium Road, Sports Complex",
            tasktitle: "Clean the Kitchen area ",
            priority: "High",
            action: (
              <div>
                <div>
                <FaPencilAlt /></div>
                <div><FaTrash /></div>
              </div>

            ),
        },
      
    ];

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = data.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    return (
      <div>
        <table class="table" style={{ margin: "10px 20px" }}>
          <thead class="thead-light">
            <tr>
              <th scope="col">Client</th>
              <th scope="col">Site</th>
              <th scope="col">Task Title</th>
              <th scope="col">Priority</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
          {currentData.map((item,index) => (
          <tr key={index}>
            <td>{item.client}</td>
            <td>{item.site}</td>
            <td>{item.tasktitle}</td>
            <td>{item.priority}</td>
            <td>{item.action}</td>
          </tr>
        ))}
      </tbody>
    </table>
    {/*
    <Pagination style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        />
        {Array.from({ length: totalPages }).map((_, index) => (
        <Pagination.Item
          key={index + 1}
          active={index + 1 === currentPage}
          onClick={() => handlePageChange(index + 1)}
        >
        {index + 1}
        </Pagination.Item>
        ))}
        <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        />
        </Pagination>
        */}
    </div>
    )
}
export default AssignTaskHistoryTable;