import React, { useState, useEffect } from 'react';
import { getOrderDetails } from '../../API/axios';
import { useParams } from 'react-router-dom';
import './OrderViewDetail.css';

function OrderViewDetail(props) {
    const { id } = useParams();
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchOrderDetails() {
          try {
            const response = await getOrderDetails(id);
            console.log("orderdetails",response.data.data);
            setSelectedOrder(response.data.data);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching order details:', error);
            setLoading(false);
          }
        }
    
        fetchOrderDetails();
      }, [id]);

  


  return (
    <>

    
    <div className="order-wrapper">
      {loading ? (
        <p>Loading...</p>
      ) : selectedOrder ?  (
        
        <table className="table">
          <tbody>
            
              <React.Fragment >
                <tr>
                  <td><strong>Client:</strong></td>
                  <td>{selectedOrder.site_name}</td>
                </tr>

                <tr>
                  <td><strong>Site:</strong></td>
                  <td>{selectedOrder.site_address}</td>
                </tr>

                <tr>
                  <td><strong>Reference No:</strong></td>
                  <td>{selectedOrder.reference_no}</td>
                </tr>

                <tr>
                  <td><strong>Product Name:</strong></td>
                  <td>{selectedOrder.product_name}</td>
                </tr>

                <tr>
                  <td><strong>Quantity:</strong></td>
                  <td>{selectedOrder.order_qty}</td>
                </tr>

                <tr>
                  <td><strong>Description:</strong></td>
                  <td>{selectedOrder.description}</td>
                </tr>

                
              </React.Fragment>
      
          </tbody>
        </table>
      ) : (
        <p>No data available for the selected order.</p>
      )}
    </div>
    </>
  );
}

export default OrderViewDetail;
