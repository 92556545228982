import React, { useState, useEffect } from "react";
import { getAllRecommendations } from "../../../API/axios";
import { Pagination } from "react-bootstrap";
import { FaEye, FaTrash } from "react-icons/fa";
import DeleteModal from "../../Modal/DeleteModal/DeleteModal";

function TableReccom(props) {
  const [recommendations, setRecommendations] = useState([]);
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [deleteShow, setdeleteShow] = useState(false);
  const [deleteid, setDeleteid] = useState("");
  const [filterChanged, setFilterChanged] = useState(false);

  const GetAllRecommendations = async () => {
    try {
      const data = await getAllRecommendations();

      if (data.status) {
        setRecommendations(data.siteRecommendations);
        setLoading(false);
      } else {
        console.log("No recommendations found:", data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllRecommendations();
  }, []);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = recommendations.filter((item) => {
      const clientMatch =
        props.filterClient === "" || item.site_name === props.filterClient;
      const siteMatch =
        props.filterSite === "" || item.site_address === props.filterSite;
      const statusMatch =
        props.filterStatus === "" || item.status === props.filterStatus;

      const datePickerDate = props.filterDate
        ? new Date(props.filterDate).toISOString().split("T")[0]
        : "";
      const createdAtDate = item.created_at
        ? new Date(item.created_at).toISOString().split("T")[0]
        : "";
      const dateMatch =
        props.filterDate === null ||
        props.filterDate === "" ||
        !item.created_at ||
        createdAtDate === datePickerDate;

      const isClient = localStorage.getItem("userType") === "Client";
      const approvedRecommendation = item.status.toLowerCase() === "approved";

      return (
        clientMatch &&
        siteMatch &&
        dateMatch &&
        statusMatch &&
        (isClient ? approvedRecommendation : true)
      );
    });

    setFilteredRecommendations(filteredData);
    setFilterChanged(true);
  }, [
    props.filterClient,
    props.filterSite,
    props.filterDate,
    props.filterStatus,
    recommendations,
  ]);

  const totalPages = Math.ceil(filteredRecommendations.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentRecommendations = filteredRecommendations.slice(
    startIndex,
    endIndex
  );
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const handleRowClick = (id) => {
    const link = `/recommendation/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const handleShowDelete = (id) => {
    setDeleteid(id);
    setdeleteShow(true);
  };

  const truncateTitle = (title) => {
    return title.length > 20 ? title.substring(0, 20) + "..." : title;
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "approved":
        return "#4CAF50";
      case "denied":
        return "#F44336";
      case "pending":
        return "#FFC107";
      default:
        return "black";
    }
  };
  const isClientUser = () => {
    const userRole = localStorage.getItem("userType");
    return userRole === "Client";
  };

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        margin: "10px 12px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            {!isClientUser() && <th scope="col">Client</th>}
            {!isClientUser() && <th scope="col">Site</th>}
            <th scope="col">Title</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentRecommendations.length > 0 ? (
            currentRecommendations.map((item) => (
              <tr key={item.id}>
                {!isClientUser() && (
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.id)}
                  >
                    {item.site_name}
                  </td>
                )}
                {!isClientUser() && (
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.id)}
                    title={item.site_address}
                  >
                    {truncateSiteName(item.site_address)}
                  </td>
                )}
                <td
                  title={item.title}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(item.id)}
                >
                  {truncateTitle(item.title)}
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(item.id)}
                >
                  {new Date(item.created_at).toLocaleDateString()}
                </td>
                <td
                  style={{
                    color: getStatusColor(item.status),
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRowClick(item.id)}
                >
                  {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                </td>
                <td>
                  <FaEye
                    className="action-icon"
                    style={{ cursor: "pointer", margin: "8px" }}
                    onClick={() => handleRowClick(item.id)}
                  />
                  {!isClientUser() && (
                    <FaTrash
                      className="delete-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => handleShowDelete(item.id)}
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No data</td>
            </tr>
          )}
        </tbody>
        <DeleteModal
          show={deleteShow}
          title={"recommendation-delete"}
          id={deleteid}
          setdeleteShow={setdeleteShow}
          GetAllRecommendations={GetAllRecommendations}
        />
      </table>

      {currentRecommendations.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default TableReccom;
