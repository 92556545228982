import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PRIMARY from "../../../Theme/palette";
import {
  deleteAuditDocument,
  deleteCleaner,
  deleteSite,
  deleteSiteArea,
  deleteSiteManual,
  deleteTaskArea,
  deleteToolbox,
  deleteHistoryToolbox,
  deleteCleanerDocument,
  deleteRecommendation,
  deleteIncident,
  deleteOrder

} from "../../../API/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";

function DeleteModal(props) {
  const [isButtonLoading, setisButtonLoading] = useState(false);
  //console.log(props.title);
  const handleClose = async () => {
    if (props.title === "area-delete") {
      setisButtonLoading(true);
      await deleteSiteArea(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            // setTimeout(() => {
            //   window.location.reload();
            // }, 5000);
            props.setAreas();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.response.data.message);
          setisButtonLoading(false);
        });
    }
    if (props.title === "mycleaner") {
      setisButtonLoading(true);
      await deleteCleaner(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setAreas();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.response.data.message);
          setisButtonLoading(false);
        });
    }
    if (props.title === "site") {
      setisButtonLoading(true);
      await deleteSite(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setAreas();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.response.data.message);
          setisButtonLoading(false);
        });
    }
    if(props.title === "incidentHistory-delete") {
      setisButtonLoading(true);
      await deleteIncident(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setIncidents((prevIncidents) =>
            prevIncidents.filter((item) => item.id !== props.id));
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.message);
          setisButtonLoading(false);
        });
        
    }

    if (props.title === "manual-delete") {
      setisButtonLoading(true);
      await deleteSiteManual(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setManuals();
            props.setNewManualsHistory();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error);
          setisButtonLoading(false);
        });
    }
    if (props.title === "audit-delete") {
      setisButtonLoading(true);
      await deleteAuditDocument(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setNewAudit();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error);
          setisButtonLoading(false);
        });
    }
    if (props.title === "asigntask") {
      setisButtonLoading(true);
      await deleteTaskArea(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setAreas();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.response.data.message);
          setisButtonLoading(false);
        });
    }

    if (props.title === "toolbox-delete") {
      setisButtonLoading(true);
      console.log("toolbox-delete", props.id);
      await deleteToolbox(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setToolBox();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }

    if (props.title === "toolboxhistory-delete") {
      setisButtonLoading(true);
      console.log("toolboxhistory-delete", props.id);
      await deleteHistoryToolbox(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.setNewToolboxHistory();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }
    if (props.title === "cleaner-document-delete") {
      setisButtonLoading(true);
      await deleteCleanerDocument(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.GetDocumentsByCleanerId();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }
    if (props.title === "recommendation-delete") {
      setisButtonLoading(true);
      await deleteRecommendation(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success(response.message);
            props.GetAllRecommendations();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }
    if (props.title === "order-delete") {
      setisButtonLoading(true);
      await deleteOrder(props.id)
        .then((response) => {
          if (response.status) {
            props.setdeleteShow(false);
            toast.success("Order delete successfully");
            props.GetAllOrders();
            setisButtonLoading(false);
          }
        })
        .catch((error) => {
          props.setdeleteShow(false);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }
  };

  const handleCloseButton = async () => {
    props.setdeleteShow(false);
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={props.show}
        onHide={handleCloseButton}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to delete this record ?</div>
          <br />
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              onClick={handleClose}
              style={{
                backgroundColor: PRIMARY.black,
                color: PRIMARY.white,
                fontWeight: "500",
                border: "none",
                padding: "10px 60px",
              }}
            >
              {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Delete"
            )}
            </Button>
          </div>
        </Modal.Body>
        {/*<Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              border: "none",
            }}
          >
            Delete
          </Button>
          </Modal.Footer>*/}
      </Modal>
    </>
  );
}

export default DeleteModal;
