import React, { useState} from "react";
import { Container, Form, Row, Col,Modal,Image } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "react-bootstrap";

function IncidentHistoryForm(props) {
  const [formData, setFormData] = useState({});
  const selectedIncident = props.selectedIncident;
  console.log(selectedIncident);
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const imageUrl = selectedIncident?.descImage;
  return (
   
    <Container>
      <Form >
        <Row style={{ marginBottom: "10px" }}></Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Client :</Form.Label>
              <Form.Control
                type="text"
                name="siteName"
                value={selectedIncident?.site_name || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Site :</Form.Label>
              <Form.Control
                type="text"
                name="siteAddress"
                value={selectedIncident?.site_address || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>First Name :</Form.Label>
              <Form.Control
                type="text"
                name="fName"
                value={selectedIncident?.f_name || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Surname :</Form.Label>
              <Form.Control
                type="text"
                name="lName"
                value={selectedIncident?.sur_name || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Persons Affected :</Form.Label>
              <Form.Control
                type="text"
                name="perAffe"
                value={selectedIncident?.person_affected || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Position :</Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={selectedIncident?.position || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Date of Birth :</Form.Label>
              <Form.Control
                type="text"
                name="dob"
                value={
                  new Date(selectedIncident?.dob).toLocaleDateString() || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Telephone :</Form.Label>
              <Form.Control
                type="text"
                name="telephone"
                value={selectedIncident?.telephone || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Date of Incident :</Form.Label>
              <Form.Control
                type="text"
                name="doi"
                value={
                  new Date(
                    selectedIncident?.date_of_incident
                  ).toLocaleDateString() || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Date Reported :</Form.Label>
              <Form.Control
                type="text"
                name="dr"
                value={
                  new Date(
                    selectedIncident?.date_reported
                  ).toLocaleDateString() || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Time Reported :</Form.Label>
              <Form.Control
                type="text"
                name="tp"
                value={selectedIncident?.time_reported || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Telephone Number :</Form.Label>
              <Form.Control
                type="text"
                name="tel"
                value={selectedIncident?.incident_reporter_tele || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Type of Incident :</Form.Label>
              <Form.Control
                type="text"
                name="toincident"
                value={selectedIncident?.type_of_incident || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Treat Type :</Form.Label>
              <Form.Control
                type="text"
                name="treat"
                value={selectedIncident?.treat_type || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Description :</Form.Label>
              <Form.Control
                as="textarea"
                name="typeInci"
                value={selectedIncident?.description || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Incident Image :</Form.Label>
              <div>
                {imageUrl ? (
                  <div>
                    <Image
                      src={imageUrl}
                      alt="Incident Image"
                      onClick={handleModalShow}
                      style={{
                        cursor: "pointer",
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        backgroundColor: "#e2e2e2",
                        border: "1px solid #dee2e6",
                      }}
                    />
                    <p style={{ fontFamily: "italic", fontSize: "11px" }}>
                      Click image to preview
                    </p>
                    <Modal
                      show={showModal}
                      onHide={handleModalClose}
                      dialogClassName="custom-modal"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Image Preview</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          src={imageUrl}
                          alt="Incident Image"
                          style={{ width: "100%", height: "auto" }}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                ) : (
                  <span
                    style={{
                      backgroundColor: "#e2e2e2",
                      border: "1px solid #dee2e6",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    No image available
                  </span>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Name of witness :</Form.Label>
              <Form.Control
                type="text"
                name="witness"
                value={selectedIncident?.witness || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Telephone Number :</Form.Label>
              <Form.Control
                type="text"
                name="telNo"
                value={selectedIncident?.witness_tele || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Was anyone injuired :</Form.Label>
              <Form.Control
                type="text"
                name="wasInjuird"
                value={selectedIncident?.is_anyone_injury || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Injured taken to hospital :</Form.Label>
              <Form.Control
                type="text"
                name="hospital"
                value={selectedIncident?.is_taken_hospital || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Has family been notified :</Form.Label>
              <Form.Control
                type="text"
                name="family"
                value={selectedIncident?.is_family_notified || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Nature of Injury :</Form.Label>
              <Form.Control
                type="text"
                name="nature"
                value={selectedIncident?.nature_of_injury || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Body Location :</Form.Label>
              <Form.Control
                type="text"
                name="body"
                value={selectedIncident?.body_location || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Mechanism of Injury :</Form.Label>
              <Form.Control
                type="text"
                name="mechanism"
                value={selectedIncident?.mechanism || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Agency of Injury :</Form.Label>
              <Form.Control
                type="text"
                name="agency"
                value={selectedIncident?.agency_of_injury || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h4>Corrective Actions</h4>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Internal investigation required?</Form.Label>
              <Form.Control
                type="text"
                readOnly
                name="Investigation"
                disabled
                value={selectedIncident?.investigate_require === 1? "Yes":"No" || ""}
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              >
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>
                What immediate action is to be taken to prevent reoccurrence and
                who is responsible ?:
              </Form.Label>
              <Form.Control
                as="textarea"
                name="action"
                readOnly
                value={selectedIncident?.admin_desc || ""}
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ marginBottom: "10px" }}>
            <Form.Group>
              <Form.Label>Date Completed:</Form.Label>
              <Form.Control
                type="text"
                name="startDate"
                readOnly
                value={
                  new Date(
                    selectedIncident?.completed_date
                  ).toLocaleDateString() || ""
                }
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Supervisor/Manager Name :</Form.Label>
              <Form.Control
                type="text"
                name="managerName"
                readOnly
                value={selectedIncident?.supervisor || ""}
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
              
            </Form.Group>
          </Col>
          <Row style={{ marginBottom: "10px" }}>
            <Form.Group>
              <Form.Label>Signature :</Form.Label>
            </Form.Group>
            <Form.Group>
              <div
                style={{
                  width: 200,
                  height: 200,
                  border: "1px solid #000",
                  backgroundColor: "#e2e2e2",
                  
                }}
              >
                <img style={{objectFit:'cover',width:'auto'}} src={selectedIncident?.signature || ""} alt="signature image"/>
              </div>

            </Form.Group>
          </Row>
        </Row>
      </Form>
    </Container>
     );
}

export default IncidentHistoryForm;
