import "./Profile.css"
import logo from "../../Assets/logo.png"

function MyProfile() {
  
  // window.location.reload()
  return (
    <div className="profile-container">
      <div className="content">
        <h1>Welcome to Clean Connect's Service Provider Portal</h1>
        <img src={logo} alt="Clean Connect Logo" className="p-logo" />
        <p>Commercial Cleaning & Property Services</p>
      </div>
      {/*
      <button className='pw-changed' onClick={handleShow}>Changed Your Password here!</button>
  */}
    </div>
  )
}

export default MyProfile