import React, { useState,useEffect } from "react";
import "./NewChatCreate.css";
import { createChat } from "../../../API/fireStoreService";
import Select from "react-select";

const NewChatCreate = ({ isOpen, onClose , setSelectedChat,fetchData ,clients}) => {
  const [chatName, setChatName] = useState("");
  const [showError, setShowError] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [error, setError] = useState("");
  const [clientName, setClientName] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedClientAddress, setSelectedClientAddress] = useState(null);

  const id=localStorage.getItem('ID');
  const admin=localStorage.getItem('Admin');
  const name=localStorage.getItem('Name');
  const site_address=localStorage.getItem('site_address');

  console.log("Seha name:",name);

  console.log("clients:",admin);
  const handleInputChange = (e) => {
    setChatName(e.target.value);
    if(chatName.trim() !== ""){
        setShowError(false);
    }
  };

  const handleAddChat = async() => {
    onClose();
    if(admin==='superAdmin'){
      if(selectedClient==null){
        setError("Please Select the Client.");
        setShowError(true);
        return;
      }
      if(selectedClientAddress==null){
        setError("Please Select the Site.");
        setShowError(true);
        return;
      }
    }
    if (chatName.trim() !== "") {
      const currentDate = new Date();
      const formattedDateTime = {
        seconds: Math.floor(currentDate.getTime() / 1000),
        nanoseconds: currentDate.getMilliseconds() * 1e6,
      }
      var site = admin==='superAdmin' && selectedClientAddress!=null ? selectedClientAddress.site_id : id ;
      var siteAddress = admin==='superAdmin' && selectedClientAddress!=null ? selectedClientAddress.site_address : site_address ;
      var creator = admin==='superAdmin' ? 'Super Admin' : name;
      var siteName = admin==='superAdmin' ? selectedClient?.site_name : name;

      const requset = {
        active:true,
        created_by:id,
        created_by_name:creator,
        created_date:formattedDateTime,
        site_id:site,
        title:chatName,
        site_address : siteAddress,
         site_name: siteName,
         readMessage: false,
         unReadMessageCount:0,
         senderID:null,
        title_lowercase:chatName.toLowerCase()
      };
      console.log(requset);
      const result =await createChat(requset);
      fetchData();
      setSelectedChat(result);
    }else{
        setError("Please Enter the Correct Title.")
        setShowError(true);
    }
  };

  console.log(clients);

  useEffect(()=>{
    filterData();
  },[clients]);

  useEffect(()=>{
    const filteredAddresses = clients
      .filter(client => client.site_name === clientName)
      .map(client => client);
    setAddressList(filteredAddresses);
  },[clientName]);

  const filterData = async () => {
    const uniqueSiteNames = new Set();
    const filteredItems = clients.filter((item) => {
      if (!uniqueSiteNames.has(item.site_name)) {
        uniqueSiteNames.add(item.site_name);
        return true;
      }
      return false;
    });
    setClientList(filteredItems);
  };

  const handleAssigneeChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    setClientName(selectedOption?.site_name ? selectedOption.site_name : null);
    setShowError(false);
  };

  const handleAssigneeChangeAddress = (selectedOption) => {
    setSelectedClientAddress(selectedOption);
    setShowError(false);
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-header">
              <h6>Before Create a New Chat, Enter the Title : </h6>
            </div>
            <div className="modal-content">
              <input
                type="text"
                value={chatName}
                onChange={handleInputChange}
                placeholder="Enter chat name"
                className="modal-input"
              />
              {admin==='superAdmin' && 
              <div><Select
                options={clientList}
                value={selectedClient}
                getOptionLabel={(option) => <>
                  <div>{`Site: ${option.site_name}`}</div>
                  {/* <div>{`Address: ${option.site_address}`}</div> */}
                </>}
                onChange={handleAssigneeChange}
                isClearable
                placeholder="Select Client"
                className="select-site-createchat"
                styles={{
                  control: provided => ({
                    ...provided,
                    padding: '3px',
                    border:'none',
                    boxShadow: 'none',
                  }),
                  menu: provided => ({
                    ...provided,
                    padding: '3px',
                    border:'none',
                  }),
                  singleValue: provided => ({
                    ...provided,
                    padding: '3px',
                    border:'none',
                  }),
                }}
                menuPlacement="top"
              />
              <Select
                options={addressList}
                value={selectedClientAddress}
                getOptionLabel={(option) => <>
                  {/* <div>{`Site: ${option.site_name}`}</div> */}
                  <div>{`Address: ${option.site_address}`}</div>
                </>}
                onChange={handleAssigneeChangeAddress}
                isClearable
                placeholder="Select Site"
                className="select-site-createchat"
                styles={{
                  control: provided => ({
                    ...provided,
                    padding: '3px',
                    border:'none',
                    boxShadow: 'none',
                  }),
                  menu: provided => ({
                    ...provided,
                    padding: '3px',
                    border:'none',
                  }),
                  singleValue: provided => ({
                    ...provided,
                    padding: '3px',
                    border:'none',
                  }),
                }}
                menuPlacement="top"
              /></div>
              }
             {showError && <p style={{color:"red"}}>{error}</p>}
            </div>
            <div className="crete-chat-modal-footer">
              <button className="crete-chat-modal-button" onClick={onClose}>
                Cancel
              </button>
              <button className="crete-chat-modal-button" onClick={handleAddChat}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewChatCreate;
