import React, { useEffect, useState } from "react";
import "./Filter.css";
import { GetCleaners } from "../../../API/axios";

function FilterCom(props) {
  const [cleaners, setCleaners] = useState([]);

  useEffect(() => {
    const GetAllCleaners = async () => {
      try {
        const Data = await GetCleaners();
        console.log(Data.data.result);
        setCleaners(Data.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllCleaners();
  }, []);
  
  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFilterSite(e.target.value);
            }}
          >
            <option selected>Select Client</option>
            {cleaners ? (
              cleaners.map((item, index) => {
                return <option value={item.site_name}>{item.site_name}</option>;
              })
            ) : (
              <option selected>No Client to Select</option>
            )}
          </select>
        </div>
        <div className="col">
          <select
            className="form-select"
            onChange={(e) => {
              props.setFilterSiteAdd(e.target.value);
            }}
          >
            <option selected>Select Site</option>
            {cleaners ? (
              cleaners.map((item, index) => {
                return (
                  <option value={item.site_address}>{item.site_address}</option>
                );
              })
            ) : (
              <option selected>No Site to Select</option>
            )}
          </select>
        </div>
      </div>
    </div>
  );
}

export default FilterCom;
