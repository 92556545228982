import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import PRIMARY from "../../../Theme/palette";
import { adminCompleteIncident,sendNotification } from "../../../API/axios";
import SignatureCanvas from "react-signature-canvas";
import { Spinner } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function IncidentForm(props) {
  const [signatureRef, setSignatureRef] = useState(null);
  const [formRef, setFormRef] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Investigation: "",
    action: "",
    managerName: "",
    signature: null,
    startDate: "",
    doc: null,
  });
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const selectedIncident = props.selectedIncident;
  const imageUrl = selectedIncident?.desc_image;
  const userType = localStorage.getItem("userType");
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    if (type === "file") {
      const file = event.target.files[0];
      setFormData({
        ...formData,
        [name]: file,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    if (selectedIncident) {
      setFormData({
        Investigation: selectedIncident.investigate_require?.toString() ?? "",
        action: selectedIncident.admin_desc ?? "",
        managerName: selectedIncident.supervisor ?? "",
        startDate:
          selectedIncident.completed_date ||
          new Date().toISOString().slice(0, 10),
      });
    }
  }, [selectedIncident]);

  const validateForm = () => {
    const errors = {};

    if (!formData.Investigation.trim()) {
      errors.Investigation = "Required.";
    }
    if (!formData.action.trim()) {
      errors.action = "Required.";
    }
    if (!formData.managerName.trim()) {
      errors.managerName = "Supervisor/Manager name is required.";
    }
    if (signatureRef.isEmpty()) {
      errors.signature = "Signature is required.";
    }
    if (!formData.startDate) {
      errors.startDate = "Date is required.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id = selectedIncident.id;
    try {
      const isValid = await validateForm();
      if (isValid) {
        setIsLoading(true);
        const signatureDataURL = signatureRef.toDataURL();
        const signatureBlob = await fetch(signatureDataURL).then((res) =>
          res.blob()
        );
        const notificationReqbody = {
          title: 'Your incident report is approved!',
          description: 
            `Your report acceptance has been approved by manager ${formData.managerName}!`,
          clientId: props.selectedIncident.site,
      };
      const responseNotificationSaved = await sendNotification(notificationReqbody);
      console.log("clientId", props.selectedIncident);
      if (responseNotificationSaved.status) {
        console.log("notification sent successfully", responseNotificationSaved.message);
        toast.success("Notification sent successfully!");
      } else {
        console.error("Error sent Notification:", responseNotificationSaved.message);
        toast.error("Error sent Notification");
      }

        const incidentFormPdf = formRef;
        const pdf = new jsPDF("p", "mm", "a4");
        const canvas = await html2canvas(incidentFormPdf);
        const imageData = canvas.toDataURL("image/jpeg");
        const pageWidth = 170;
        const pageHeight = 305;
        pdf.addImage(imageData, "JPEG", 20, 10, pageWidth, pageHeight);
        const pdfBlob = pdf.output("blob");
        pdfBlob.name = `incidentReport${id}.pdf`;
        // pdf.save(pdfBlob.name);

        const requestBody = {
          investigate_require: formData.Investigation,
          admin_desc: formData.action,
          supervisor: formData.managerName,
          image: signatureBlob,
          doc: pdfBlob,
        };
        adminCompleteIncident(id, requestBody)
          .then((response) => {
            setIsLoading(false);
            if (response.status === true) {
              toast.success(response.message);
              props.handleFormSubmission();
            } else {
              toast.error(response.response.data);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.response.data.message);
          });
      } else {
      }
    } catch (error) {}
  };
  const clearSignature = () => {
    signatureRef.clear();
  };

  const handlePdfDownload = async () => {
    const incidentFormPdf = formRef;
    const pdf = new jsPDF("p", "mm", "a4");
    const canvas = await html2canvas(incidentFormPdf);
    const imageData = canvas.toDataURL("image/jpeg");
    const pageWidth = 170;
    const pageHeight = 305;
    pdf.addImage(imageData, "JPEG", 20, 10, pageWidth, pageHeight);
    const pdfBlob = pdf.output("blob");
    pdfBlob.name = "incidentReport.pdf";
    pdf.save(pdfBlob.name);
  };

  const isClient = localStorage.getItem("userType") === "Client";

  return (
    <Container>
      <Form onSubmit={handleSubmit} ref={(ref) => setFormRef(ref)}>
        <Row style={{ marginBottom: "10px" }}></Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Client :</Form.Label>
              <Form.Control
                type="text"
                name="siteName"
                value={selectedIncident?.site_name || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Site :</Form.Label>
              <Form.Control
                type="text"
                name="siteAddress"
                value={selectedIncident?.site_address || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>First Name :</Form.Label>
              <Form.Control
                type="text"
                name="fName"
                value={selectedIncident?.f_name || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Surname :</Form.Label>
              <Form.Control
                type="text"
                name="lName"
                value={selectedIncident?.sur_name || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Persons Affected :</Form.Label>
              <Form.Control
                type="text"
                name="perAffe"
                value={selectedIncident?.person_affected || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Position :</Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={selectedIncident?.position || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Date of Birth :</Form.Label>
              <Form.Control
                type="text"
                name="dob"
                value={
                  new Date(selectedIncident?.dob).toLocaleDateString() || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Telephone :</Form.Label>
              <Form.Control
                type="text"
                name="telephone"
                value={`${selectedIncident?.telephone || "NA"} and ${
                  selectedIncident?.telephone2 || "NA"
                }`}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Date of Incident :</Form.Label>
              <Form.Control
                type="text"
                name="doi"
                value={
                  new Date(
                    selectedIncident?.date_of_incident
                  ).toLocaleDateString() || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Date Reported :</Form.Label>
              <Form.Control
                type="text"
                name="dr"
                value={
                  new Date(
                    selectedIncident?.date_reported
                  ).toLocaleDateString() || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Time Reported :</Form.Label>
              <Form.Control
                type="text"
                name="tp"
                value={selectedIncident?.time_reported || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Telephone Number :</Form.Label>
              <Form.Control
                type="text"
                name="tel"
                value={selectedIncident?.incident_reporter_tele || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Type of Incident :</Form.Label>
              <Form.Control
                type="text"
                name="toincident"
                value={selectedIncident?.type_of_incident || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Treat Type :</Form.Label>
              <Form.Control
                type="text"
                name="treat"
                value={selectedIncident?.treat_type || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Description :</Form.Label>
              <Form.Control
                as="textarea"
                name="typeInci"
                value={selectedIncident?.description || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Incident Image :</Form.Label>
              <div>
                {imageUrl ? (
                  <div>
                    <Image
                      src={imageUrl}
                      alt="Incident Image"
                      onClick={handleModalShow}
                      style={{
                        cursor: "pointer",
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        backgroundColor: "#e2e2e2",
                        border: "1px solid #dee2e6",
                      }}
                    />
                    <p style={{ fontFamily: "italic", fontSize: "11px" }}>
                      Click image to preview
                    </p>
                    <Modal
                      show={showModal}
                      onHide={handleModalClose}
                      dialogClassName="custom-modal"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Image Preview</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          src={imageUrl}
                          alt="Incident Image"
                          style={{ width: "100%", height: "auto" }}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                ) : (
                  <span
                    style={{
                      backgroundColor: "#e2e2e2",
                      border: "1px solid #dee2e6",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    No image available
                  </span>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Name of witness :</Form.Label>
              <Form.Control
                type="text"
                name="witness"
                value={selectedIncident?.witness || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Telephone Number :</Form.Label>
              <Form.Control
                type="text"
                name="telNo"
                value={selectedIncident?.witness_tele || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Was anyone injuired :</Form.Label>
              <Form.Control
                type="text"
                name="wasInjuird"
                value={
                  selectedIncident?.is_anyone_injury == 1 ? "Yes" : "No" || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Injured taken to hospital :</Form.Label>
              <Form.Control
                type="text"
                name="hospital"
                value={
                  selectedIncident?.is_taken_hospital == 1 ? "Yes" : "No" || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Has family been notified :</Form.Label>
              <Form.Control
                type="text"
                name="family"
                value={
                  selectedIncident?.is_family_notified == 1 ? "Yes" : "No" || ""
                }
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Nature of Injury :</Form.Label>
              <Form.Control
                type="text"
                name="nature"
                value={selectedIncident?.nature_of_injury || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Body Location :</Form.Label>
              <Form.Control
                type="text"
                name="body"
                value={selectedIncident?.body_location || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Mechanism of Injury :</Form.Label>
              <Form.Control
                type="text"
                name="mechanism"
                value={selectedIncident?.mechanism || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Agency of Injury :</Form.Label>
              <Form.Control
                type="text"
                name="agency"
                value={selectedIncident?.agency_of_injury || ""}
                readOnly
                style={{
                  backgroundColor: "#e2e2e2",
                  border: "1px solid #dee2e6",
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        {!isClient && (
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h4>Corrective Actions</h4>
            </Col>
          </Row>
        )}
        {!isClient && (
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <Form.Group>
                <Form.Label>
                  Internal investigation required?{" "}
                  <p style={{ fontFamily: "italic" }}>
                    (if very high, high or moderate risk, then an internal
                    investigation is required - see Form 12.2.2)
                  </p>{" "}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="Investigation"
                  value={formData.Investigation}
                  onChange={handleInputChange}
                >
                  <option value="">Select an option</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </Form.Control>
                {validationErrors.Investigation && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.Investigation}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        {!isClient && (
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <Form.Group>
                <Form.Label>
                  What immediate action is to be taken to prevent reoccurrence
                  and who is responsible ?:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="action"
                  value={formData.action}
                  onChange={handleInputChange}
                />
                {validationErrors.action && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.action}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        {!isClient && (
          <Row style={{ marginBottom: "10px" }}>
            <Col style={{ marginBottom: "10px" }}>
              <Form.Group>
                <Form.Label>Date Completed:</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                />
                {validationErrors.startDate && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.startDate}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Supervisor/Manager Name :</Form.Label>
                <Form.Control
                  type="text"
                  name="managerName"
                  value={formData.managerName}
                  onChange={handleInputChange}
                />
                {validationErrors.managerName && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.managerName}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Row style={{ marginBottom: "10px" }}>
              <Form.Group>
                <Form.Label>Signature :</Form.Label>
              </Form.Group>
              <Form.Group>
                <SignatureCanvas
                  ref={(ref) => setSignatureRef(ref)}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 200,
                    style: { border: "1px solid #000" },
                  }}
                />
                {validationErrors.signature && (
                  <p
                    style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                    className="text-danger"
                  >
                    {validationErrors.signature}
                  </p>
                )}
              </Form.Group>

              <Button
                style={{
                  width: "200px",
                  marginLeft: "10px",
                  marginTop: "15px",
                  background: "black",
                }}
                onClick={clearSignature}
              >
                Clear Signature
              </Button>
            </Row>
          </Row>
        )}
        <br />
        <div className="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 30px",
              marginRight: "10px",
            }}
            onClick={handlePdfDownload}
          >
            Downlaod PDF
          </Button>
          {!isClient && (
            <Button
              type="submit"
              style={{
                backgroundColor: PRIMARY.black,
                color: PRIMARY.white,
                fontWeight: "500",
                border: "none",
                padding: "10px 60px",
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" />{" "}
                </>
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
}

export default IncidentForm;
