import React, { useState, useEffect } from "react";
import logo from "../../Assets/logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { LoginApi } from "../../API/axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";
import { Spinner } from "react-bootstrap";


function Login({token}) {
  const [isLoginButtonDisabled, setLoginButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [isButtonLoading, setisButtonLoading] = useState(false);

  const Navigate = useNavigate();

  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required.");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required.");
    }
    //  else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(password)) {
    //   setPasswordError(
    //     "Password must contain at least one uppercase letter, one lowercase letter, and one number."
    //   );
    else {
      setPasswordError("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginButtonDisabled(true);
    validateEmail();
    validatePassword();
    if (!emailError && !passwordError) {
      try {
        setisButtonLoading(true);
        const data = {
          email: email,
          password: password,
          fcmToken: token,
        };
        const response = await LoginApi(data);
        handleApiResponse(response);
      } catch (error) {
        setisButtonLoading(false);
        setShowModal2(true);
      }
    }
    setTimeout(() => {
      setLoginButtonDisabled(false);
    }, 5000);
  };

  const handleApiResponse = (response) => {
    if (response.data.status === true) {
      console.log(response.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("ID", response.data.id);
      localStorage.setItem("site_address", response.data.location);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("UserNameVal", response.data.user);
      if (response.data.message === "Client loging success") {
        localStorage.setItem("userType", "Client");
        localStorage.setItem("user", response.data.user);
      } else {
        localStorage.setItem("userType", "SuperAdmin");
      }
      localStorage.setItem("Admin", response.data.admin);
      localStorage.setItem("Location", response.data.location);
      setShowModal1(true);
      setTimeout(() => {
        window.location.href = "/profile";
      }, 3000);
    }
  };
  const handleCloseModal2 = () => {
    setShowModal2(false);
  };
  useEffect(() => {
    if (showModal2) {
      const timeoutId = setTimeout(handleCloseModal2, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [showModal2]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // validatePassword();
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // validateEmail();
  };

  return (
    <>
    <div class="bg-light py-3 py-md-5">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
            <div class="bg-white p-4 p-md-5 rounded shadow-sm">
              <div class="row">
                <div class="col-12">
                  <div class="text-center mb-5">
                    <a href="#!">
                      <img
                        src={logo}
                        alt="BootstrapBrain Logo"
                        width="250"
                        height="157"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <form>
                <div class="row gy-3 gy-md-4 overflow-hidden">
                  <div class="col-12">
                    <label for="email" class="form-label">
                      Email <span class="text-danger">*</span>
                    </label>
                    <div
                      class="input-group"
                      style={{ boxShadow: "0 0 1px black" }}
                    >
                      <span class="input-group-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-envelope"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                        </svg>
                      </span>
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        // onBlur={validateEmail}
                        style={{ fontSize: "18px" }}
                      />
                    </div>

                    {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                  </div>
                  <div class="col-12">
                    <label for="password" class="form-label">
                      Password <span class="text-danger">*</span>
                    </label>
                    <div
                      class="input-group"
                      style={{ boxShadow: "0 0 1px black" }}
                    >
                      <span class="input-group-text">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-key"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                      </span>
                      <input
                        type={showPassword ? "text" : "password"}
                        class="form-control"
                        name="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        // onBlur={validatePassword}
                        style={{ fontSize: "18px" }}
                      />

                      <span
                        className="input-group-text"
                        onClick={togglePasswordVisibility}
                      >
                        {password ? (
                          <>
                            {showPassword ? (
                              <FaEye style={{ margin: "0 5px 0 5px" }} />
                            ) : (
                              <FaEyeSlash style={{ margin: "0 5px 0 5px" }} />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    {passwordError && (
                      <div className="text-danger">{passwordError}</div>
                    )}
                  </div>
                  <div class="col-12">
                    <div class="d-grid">
                      <button
                        class="btn btn-primary btn-lg"
                        className="btn btn-primary btn-lg"
                        type="submit"
                        onClick={handleSubmit}
                        style={{
                          backgroundColor: "#333",
                          border: "none",
                          transition: "background-color 0.2s",
                          fontSize: "18px",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "black";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#333";
                        }}
                        // disabled={isLoginButtonDisabled}
                      >
                        {isButtonLoading ? (
                          <>
                            <Spinner animation="border" size="sm" />{" "}
                          </>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-12">
                  <hr class="mt-5 mb-4 border-secondary-subtle" />
                  <div class="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal1 && (
        <Modal
          style={{ background: "rgba(15, 14, 14, 0.144)" }}
          show={showModal1}
          onHide={() => setShowModal1(false)}
        >
          <Modal.Header>
            <div className="d-flex justify-content-center align-items-center text-danger">
              <FaCheckCircle
                size={24}
                style={{ marginLeft: "220px", color: "green" }}
              />
            </div>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center ">
            Login Successfull !
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center "></Modal.Footer>
        </Modal>
      )}
      {showModal2 && (
        <Modal
          style={{ background: "rgba(15, 14, 14, 0.144)" }}
          show={showModal2}
          onHide={handleCloseModal2}
        >
          <Modal.Header closeButton>
            <div className="d-flex justify-content-center align-items-center text-danger">
              <FaExclamationCircle size={24} style={{ marginLeft: "220px" }} />
            </div>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center ">
            Login failed! Invalid Username or password
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center "></Modal.Footer>
        </Modal>
      )}
    </div>
    </>
  );
}

export default Login;
