import React, { useState,useEffect } from "react";
import "./ChatListLayout.css"; 
import {
    FaSearch,
    FaPlus
  } from "react-icons/fa";
import PRIMARY from "../../Theme/palette";
import ChatCard from "./ChatCard";
import NewChatCreate from "./NewChatCreate/NewChatCreate";
import {deleteChat, getMessages,setReadCountToZero} from '../../API/fireStoreService';
import FilterSite from "../../Componants/FilterSite/FilterSite";

const user = localStorage.getItem("ID");

const ChatListLayout = ({children , chats ,setSelectedChat,fetchData,clients}) => {
    const allChats = chats || null;
    const [chatList,setChatList] = useState([]);
    const [historyView,setHistoryView] = useState(false);
    const [showNewChatModal,setShowNewChatModal] = useState(false);
    const [filterClient, setFilterClient] = useState("");
    const [filterSite, setFilterSite] = useState("");
    const [isClient, setIsClient] = useState(localStorage.getItem("userType") === "Client");

    useEffect(() => {
      setIsClient(localStorage.getItem("userType") === "Client");
    }, []);
    
    useEffect(()=>{
      filterData(!historyView);
    },[allChats,historyView]);

    const filterData = async(status)=>{
      const filteredItems = allChats.filter((item) => 
        item.active == status
      );
      const sortedItems = filteredItems.sort((a, b) => {
        const dateA = a.created_date instanceof Date ? a.created_date : new Date(a.created_date.seconds * 1000 + a.created_date.nanoseconds / 1e6);
        const dateB = b.created_date instanceof Date ? b.created_date : new Date(b.created_date.seconds * 1000 + b.created_date.nanoseconds / 1e6);
        return dateB - dateA;
    });       
      setChatList(sortedItems);
    }

    
    useEffect(()=>{
      if(!isClient){
      if(filterSite===""){
        setChatList(allChats);
      }else{
        const filteredItems = allChats.filter(
          (item) => item.site_id==filterSite && item.active == !historyView
        );
        setChatList(filteredItems);
      }
    }
    },[filterSite]);
    
  
    useEffect(()=>{
      if(!isClient){
      setFilterSite("");
      if(filterClient==="" && isClient){
        setChatList(allChats);
      }else{
        const filteredItems = allChats.filter(
          (item) => item.created_by_name.toLowerCase().includes(filterClient)
        );
        setChatList(filteredItems);
      }
    }
    },[filterClient]);
  
    const handleHistory = async() => {
      setHistoryView(!historyView);
      setSelectedChat([]);
      setFilterClient(""); 
      setFilterSite("");
    }

    const search = async(val)=>{
      const searchTerm = val.toLowerCase();
  
      if (searchTerm !== "") {
        const filteredItems = allChats.filter(
          (item) => item.title.toLowerCase().includes(searchTerm)
        );
        setChatList(filteredItems);
      }
      else{
        setHistoryView(false);
        filterData(true);
      }
    }

    const chatselect = (chat) => {
      chat.unReadMessageCount = 0;
      setReadCountToZero(chat.id,user);
      setSelectedChat(chat);
      
    }

    const deleteChatFromList = (id) => {
      deleteChat(id);
      fetchData();
      setSelectedChat([]);
    }

  return (
    <>
    <div className="chat-layout">
      <div className="chat-sidebar">
      <div className="top-chat-head">
        <p><b>Communication</b></p>
        <FilterSite
        isClient={isClient}
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          filterClient={filterClient}
          filterSite={filterSite}
          page={"Communication" || "AnotherPage"}
        />
        {console.log("filtersits",filterSite)}
      </div>
      <div className="chat-list-menu">
        <button
                className="btn btn-primary"
                style={{
                border: "none",
                backgroundColor: PRIMARY.darkGray,
                padding: "0 10px",
                marginLeft:"10px",
                color: PRIMARY.white,
                fontWeight: "500",
                }}
                onClick={handleHistory}
            >
            {historyView? 'Active Chats' : 'Chat History'}
            </button>
            <div className="chat-list-search">
                <FaSearch className="search-icon" />
                <input className="chat-list-search-input" placeholder="Search" onChange={(e)=>{search(e.target.value)}} />
            </div>
            <div className="chat-list-topic">
            <h5>{historyView? 'Chat History' : 'Active Chat List'}</h5>
            </div>
            <div className="chat-list-array">
            {chatList.length === 0 && isClient === false && (filterClient != "" && filterSite != "") ? (
                <div>
              No chats available for the selected client and site.
              </div>
            ) : (
              
              chatList.map((chat,index)=>(
                  <div onClick={() => chatselect(chat)}>
                    <ChatCard key={index} title={chat.title} name={chat.site_name} address={chat.site_address || null} chatHistory={historyView} deleteChat={deleteChatFromList} chat={chat} unReadCount={chat.unReadMessageCount} senderID={chat.senderID}/>
                  </div>
                ))
            )
            }
            </div>
            <div className="add-button-container">
              <button className="add-button-newchat" onClick={() => setShowNewChatModal(true)}>
                <FaPlus className="add-icon" />
              </button>
            </div>
        </div>
      </div>
      <div className="chat-content">
       {children}
      </div>
      {showNewChatModal && <NewChatCreate isOpen={showNewChatModal} onClose={()=>{setShowNewChatModal(false)}} setSelectedChat={setSelectedChat} fetchData={fetchData} clients={clients}/>}
    </div>
    </>
  );
};

export default ChatListLayout;
