import Modal from "react-bootstrap/Modal";
import IncidentEmailCreateForm from "./IncidentEmailCreateForm";

function IncidentEmailModal(props) {
  const handleClose = () => {
    props.setShowBox(false);
  };

const handleFormSubmission = () => {
    handleClose();
    props.handleIncidentsUpdated();
  };


  return (
    <>
      <Modal show={props.showBox} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.Title === "create" ? "Send Email" : "Update Help"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IncidentEmailCreateForm
            title={props.Title}
            showBox={props.showBox}
            setShowBox={props.setShowBox}
            selectedIncident={props.selectedIncident}
            handleClose={handleClose}
            handleFormSubmission={handleFormSubmission}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default IncidentEmailModal;
