import React, { useState, useEffect, useContext } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { getadminCompleteIncident } from "../../../API/axios";
import jsPDF from "jspdf";
import { PDFContext } from "./PDFContext";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

function IncidentPdf() {
  const [incidentData, setIncidentData] = useState([]);
  const { setPdfBlob } = useContext(PDFContext);
  const [img, setImg]=useState(null)

  useEffect(() => {
    const generatePdf = async () => {
      try {
        const pdf = new jsPDF();
        // Adding content to the PDF
        pdf.text("Hello, this is my PDF!", 10, 10);
        pdf.text("This is a sample text.", 10, 20);

        // Output the PDF as a blob
        const generatedPdfBlob = pdf.output("blob");
        setPdfBlob(generatedPdfBlob); // Update pdfBlob state
        console.log(generatedPdfBlob);
      } catch (error) {
        console.error("Error generating PDF:", error);
        alert("Error generating PDF");
        throw error;
      }
    };

    generatePdf(); // Automatically generate the PDF when the component mounts
  }, [setPdfBlob]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getadminCompleteIncident();
        console.log(response);
        // Filter the data based on a specific ID
        const specificId = "0564eee5-8a99-11ee-81c8-0a616426a2b7"; // Change this to the specific ID you want to display
        const filteredData = response.incidentList.filter(
          (incident) => incident.id === specificId
        );
        setIncidentData(filteredData);
        setImg(incidentData[0]?.signature); 
        // console.log(incidentData[0]?.signature);
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const currentDate = new Date().toLocaleDateString();

  const content = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text
            style={{
              border: "1.5px solid black",
              padding: 10,
              textAlign: "center",
            }}
          >
            Incident Form
          </Text>
          <Text style={{ fontSize: "10" }}>Downloaded Date: {currentDate}</Text>

          <Text
            style={{
              backgroundColor: "#204060",
              border: "1.5px solid black",
              padding: 4,
              color: "white",
              fontSize: 12,
              marginTop: 25,
              paddingLeft: 5,
            }}
          >
            Persons Involved
          </Text>
          {incidentData ? (
            <>
              {incidentData.map((incident, index) => (
                <View key={index} style={{ flexDirection: "row", flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 12,
                      marginTop: 8,
                      paddingLeft: 10,
                    }}
                  >
                    <Text style={{ marginBottom: 5 }}>
                      Persons Affected :{" "}
                      {incident.person_affected
                        ? incident.person_affected
                        : "null"}
                    </Text>
                    <Text style={{ marginBottom: 5 }}>
                      First Name : {incident.f_name ? incident.f_name : "null"}{" "}
                    </Text>
                    <Text style={{ marginBottom: 5 }}>
                      Surname : {incident.sur_name ? incident.sur_name : "null"}
                    </Text>
                    <Text style={{ marginBottom: 5 }}>
                      Telephone :{" "}
                      {incident.telephone ? incident.telephone : "null"}
                    </Text>
                  </View>

                  <View style={{ flex: 1, fontSize: 12, marginTop: 5 }}>
                    <Text style={{ marginBottom: 5 }}>
                      Position :{incident.position ? incident.position : "null"}{" "}
                    </Text>
                    <Text style={{ marginBottom: 5 }}>
                      Site Address :{" "}
                      {incident.site_address ? incident.site_address : "null"}{" "}
                    </Text>
                  </View>
                </View>
              ))}
            </>
          ) : (
            "No Data available"
          )}

          <Text
            style={{
              backgroundColor: "#204060",
              border: "1.5px solid black",
              padding: 4,
              color: "white",
              fontSize: 12,
              marginTop: -55,
              paddingLeft: 5,
            }}
          >
            Incident Details
          </Text>
          {incidentData ? (
            <>
              {incidentData.map((incident, index) => (
                <View key={index} style={{ flexDirection: "row", flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 12,
                      marginTop: 8,
                      paddingLeft: 10,
                    }}
                  >
                    <Text style={{ marginBottom: 6 }}>
                      Date of Incident :{" "}
                      {incident.date_of_incident
                        ? incident.date_of_incident
                        : "null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Date Reported :{" "}
                      {incident.date_reported ? incident.date_reported : "null"}{" "}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Time Reported :{" "}
                      {incident.time_reported ? incident.time_reported : "null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Telephone Number :{" "}
                      {incident.incident_reporter_tele
                        ? incident.incident_reporter_tele
                        : "null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Type of Incident :{" "}
                      {incident.type_of_incident
                        ? incident.type_of_incident
                        : "null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Treatment Type :{" "}
                      {incident.treat_type ? incident.treat_type : "null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Description :{" "}
                      {incident.description ? incident.description : "null"}
                    </Text>
                  </View>
                  <View style={{ flex: 1, fontSize: 12, marginTop: 5 }}>
                    <Text style={{ marginBottom: 6 }}>
                      Name of witness :
                      {incident.witness ? incident.witness : "null"}{" "}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      witness telephone :{" "}
                      {incident.witness_tele ? incident.witness_tele : "null"}{" "}
                    </Text>
                  </View>
                </View>
              ))}
            </>
          ) : (
            "No Data available"
          )}
          <Text
            style={{
              backgroundColor: "#204060",
              border: "1.5px solid black",
              padding: 4,
              color: "white",
              fontSize: 12,
              marginTop: 5,
              paddingLeft: 5,
            }}
          >
            Injury Details
          </Text>
          {incidentData ? (
            <>
              {incidentData.map((incident, index) => (
                <View key={index} style={{ flexDirection: "row", flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 12,
                      marginTop: 8,
                      paddingLeft: 10,
                    }}
                  >
                    <Text style={{ marginBottom: 6 }}>
                      Was anyone injured? :{" "}
                      {incident.is_anyone_injury === 1 ? "Yes" : "No"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Injured taken to hospital? :{" "}
                      {incident.is_taken_hospital === 1 ? "Yes" : "No"}{" "}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Has family been notified? :{" "}
                      {incident.is_family_notified === 1 ? "Yes" : "No"}
                    </Text>
                  </View>
                </View>
              ))}
            </>
          ) : (
            "No Data available"
          )}

          <Text
            style={{
              backgroundColor: "#204060",
              border: "1.5px solid black",
              padding: 4,
              color: "white",
              fontSize: 12,
              marginTop: -65,
              paddingLeft: 5,
            }}
          >
            Injury Specifications
          </Text>
          {incidentData ? (
            <>
              {incidentData.map((incident, index) => (
                <View key={index} style={{ flexDirection: "row", flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 12,
                      marginTop: 8,
                      paddingLeft: 10,
                    }}
                  >
                    <Text style={{ marginBottom: 6 }}>
                      Nature of Injury :{" "}
                      {incident.nature_of_injury
                        ? incident.nature_of_injury
                        : " Null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Body Location :{" "}
                      {incident.body_location ? incident.body_location : "Null"}{" "}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Mechanism of Injury :{" "}
                      {incident.mechanism ? incident.mechanism : "Null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Agency of Injury :{" "}
                      {incident.agency_of_injury
                        ? incident.agency_of_injury
                        : "Null"}
                    </Text>
                  </View>
                </View>
              ))}
            </>
          ) : (
            "No Data available"
          )}
          <Text
            style={{
              backgroundColor: "#204060",
              border: "1.5px solid black",
              padding: 4,
              color: "white",
              fontSize: 12,
              marginTop: -65,
              paddingLeft: 5,
            }}
          >
            Corrective Actions
          </Text>
          {incidentData ? (
            <>
              {incidentData.map((incident, index) => (
                <View key={index} style={{ flexDirection: "row", flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 12,
                      marginTop: 8,
                      paddingLeft: 10,
                    }}
                  >
                    <Text style={{ marginBottom: 6 }}>
                      Internal Investigation Required :{" "}
                      {incident.investigate_require === 1 ? "Yes" : " No"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      What immediate action is to be undertaken to prevent
                      reoccurrence and who is responsible :{" "}
                      {incident.admin_desc ? incident.admin_desc : "Null"}{" "}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Date Completed :{" "}
                      {incident.completed_date
                        ? incident.completed_date
                        : "Null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Supervisor/Manager Name :{" "}
                      {incident.supervisor ? incident.supervisor : "Null"}
                    </Text>
                    <Text style={{ marginBottom: 6 }}>
                      Signature:
                    </Text>
                    {img ? (
                      <Image style={{height:"55px", width:"55px"}} src={img} />
                    ) : (
                      <Text>Null</Text>
                    )}
                  </View>
                </View>
              ))}
            </>
          ) : (
            "No Data available"
          )}
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <PDFDownloadLink document={content} fileName="incident_report.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>

      <PDFViewer width="100%" height={600}>
        {content}
      </PDFViewer>
    </>
  );
}

export default IncidentPdf;
