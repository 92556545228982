import React, { useState, useEffect } from "react";
import "./Recommendation.css";
import Recommendation from "../../Componants/Recommendation/Recommendation";
import { useParams } from "react-router-dom";
import {
  getApprovedRecommendationByID,
  getRecommendationByID,
} from "../../API/axios";

function RecommendationView() {
  const { index } = useParams();
  const [recommendation, setRecommendation] = useState([]);
  const [approvalDetails, setApprovalDetails] = useState();

  useEffect(() => {
    getRecommendationByID(index)
      .then((res) => {
        setRecommendation(res.siteRecommendations);
        const recommendationId = res.siteRecommendations[0].id;
        return getApprovedRecommendationByID(recommendationId);
      })
      .then((approvalDetails) => {
        setApprovalDetails(approvalDetails);
        console.log("approve details", approvalDetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [index]);

  const setNewRecommendation = () => {
    getRecommendationByID(index)
      .then((res) => {
        setRecommendation(res.siteRecommendations);
        const recommendationId = res.siteRecommendations[0].id;
        return getApprovedRecommendationByID(recommendationId);
      })
      .then((approvalDetails) => {
        setApprovalDetails(approvalDetails);
        console.log("approve details", approvalDetails);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="cleaner-topic">
        <p>Site Recommendation </p>
      </div>
      <div>
        <Recommendation
          recommendation={recommendation}
          approvalDetails={approvalDetails}
          setNewRecommendation={setNewRecommendation}
        />
      </div>
    </>
  );
}

export default RecommendationView;
