import axios from "axios";
const token = localStorage.getItem("token");
const userType = localStorage.getItem("userType")

// const token = localStorage.getItem("token");
// const token =
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOjQsImFkbWluX25hbWUiOiJzYWppdGgiLCJlbWFpbCI6InNhaml0aHRoaWxhbmdhOTRAZ21haWwuY29tIiwic3RhdHVzIjoiQURNSU4ifSwiaWF0IjoxNjc3MjU1MzI0MjYxLCJleHAiOjE2NzcyNTY1MzM4NjF9.SDG0zFeP2BuyPc5v1-1meoJjIryKO2YKzp4DKHPfda8";
// const token = localStorage.getItem("token");

// const token =
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjUzYTBmMGJlLTY5NzYtMTFlZS1hNDc2LWIwYTUyOWRiZDI4YiIsImVtYWlsIjoidGVzdGVtYWlsQGdtYWlsLmNvbSIsInJvbGUiOiJzdXBlciBhZG1pbiIsImlhdCI6MTY5ODI2MzQ5NCwiZXhwIjoxNjk4MjY3MDk0fQ.6eqx3gItwl1UMcM1mRCFmkfE20Nk7b6JyuZ9GJoWhBU";

export const API = axios.create({
  baseURL: "https://backend.clean-connect.com.au",
  //baseURL: "http://localhost:8080",
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `${token}`,
    "Content-Type": "application/json",
  },
});

export const multipartAPI = axios.create({
  baseURL: "https://backend.clean-connect.com.au",
  //baseURL: "http://localhost:8080",
  timeout: 400000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `${token}`,
    "Content-Type": "multipart/form-data",
  },
});

export const registerAPI = axios.create({
  baseURL: "https://backend.clean-connect.com.au",
  //baseURL: "http://localhost:8080",
});

export const LoginApi = async (formData) => {
  const res = await registerAPI.post(`/user/login`, formData);
  console.log(res);
  return res;
};

export const getSiteAreaById = async (id) => {
  const res = await API.get(`/site/site-area/${id}`);
  return res.data;
};

export const addSiteArea = async (formData) => {
  const res = await API.post(`/site/site-area/`, formData);
  return res.data;
};

export const deleteSiteArea = async (id) => {
  const res = await API.delete(`/site/site-area/${id}`);
  return res.data;
};

export const deleteCleaner = async (id) => {
  const res = await API.delete(`/user/deleteCleaner/${id}`);
  return res.data;
};

export const deleteSite = async (site) => {
  const res = await API.delete(`/site/delete/${site}`);
  return res.data;
};

export const GetCleaners = async () => {
  const res = await API.get(`/user/getAllCleanerUsers`);
  return res;
};

export const GetClients = async () => {
  const res = await API.get(`/site/getall-sites`);
  return res;
};

export const GetScandList = async () => {
  const res = await API.get(`/user/scan-data`);
  return res;
};

export const addNewSite = async (requestBody) => {
  const res = await API.post(`/site/register-client`, requestBody);
  return res.data;
};

export const updateSite = async (site_id, requestBody) => {
  const res = await API.put(`/site/edit-client/${site_id}`, requestBody);
  return res.data;
};

export const addNewCleaner = async (requestBody, config) => {
  console.log(requestBody);
  const res = await multipartAPI.post(`/user/register-cleaner`, requestBody);
  return res.data;
};

export const updateCleaner = async (cleaner_id, requestBody) => {
  const res = await multipartAPI.post(
    `/user/editCleanerUser/${cleaner_id}`,
    requestBody
  );
  return res.data;
};

export const getSiteById = async (id) => {
  const res = await API.get(`site/get-sites/${id}`);
  return res.data;
};

export const updateSiteArea = async (formData) => {
  const res = await API.put(`/site/site-area/`, formData);
  return res.data;
};

export const assignCleaners = async (formData) => {
  const res = await API.put(`/user/assignCleaner`, formData);
  return res.data;
};
export const getIncidents = async () => {
  const res = await API.get(`/incident/adminList`);
  return res.data;
};
export const getSignedIncident = async () => {
  const res = await API.get(`/incident/CompleteIncidentForm`);
  return res.data;
};
// export const adminCompleteIncident = async (id, requestBody, imageFormData) => {
//   const res = await API.put(`/incident/${id}`,{
//     ...requestBody,
//     pdf: imageFormData,
//   });
//   return res.data;
// };
export const adminCompleteIncident = async (id, requestBody) => {
  const res = await multipartAPI.put(`/incident/${id}`, requestBody);
  return res.data;
};
export const getadminCompleteIncident = async () => {
  const res = await API.get(`/incident/completeList`);
  return res.data;
};
export const deleteIncident = async (id) => {
  const res = await API.delete(`/incident/deleteHistoryIncident/${id}`);
  return res.data;
};
export const getShowList = async () => {
  const res = await API.get(`/site/show-coloumn`);
  return res;
};

export const updateShowList = async (formData) => {
  const res = await API.put(`/site/show-coloumn`, formData);
  return res;
};

export const unAssignCleaners = async (siteID, id) => {
  const res = await API.delete(`/user/unassignCleaner/${siteID}/${id}`);
  return res.data;
};
export const AddToolBoxTalkDoc = async (formData) => {
  const res = await multipartAPI.post(`/toolbox-talk/add`, formData);
  return res;
};
export const getSiteToolBoxTalk = async () => {
  const res = await API.get(`/toolbox-talk/get-site-toolbox-talk`);
  return res;
};
export const getCleanerToolBoxBySiteID = async (id) => {
  const res = await API.get(`/toolbox-talk/get-cleaner-toolbox-talk/${id}`);
  return res;
};

export const CreateHelp = async (formData) => {
  const res = await multipartAPI.post(`/site/help`, formData);
  return res.data;
};

export const getAllTaskTraking = async () => {
  const res = await API.get(`/tasks/all-tasks-trakings`);
  return res;
};

export const changeSuperAdminPwd = async (id, requestBody) => {
  const res = await API.post(
    `/user/changeSuperAdminPassword/${id}`,
    requestBody
  );
  return res.data;
};

export const getAllCleanerWorkingHrs = async () => {
  const res = await API.get(`/tasks/client-summery-by/:any`);
  return res;
};
export const getAllStock = async () => {
  const res = await API.get(`order/get-all`);
  return res;
};
export const getCleanersummary = async () => {
  const res = await API.get(`/tasks/cleaner-traking-order-by-user`);
  return res;
};
export const getAllStockByMonth = async (year, month) => {
  const res = await API.get(`/order/stock-summery/get-approved-stocks/${month}/year/${year}`);
  return res;
};
export const getAllStocksummary = async () => {
  const res = await API.get(`/order/stock-summery/get-all-approved-stocks`);
  return res;
};
export const getAllCleanerWorkingHrsBySite = async (fname, lname, year, month) => {
  const res = await API.get(
    `/tasks/cleaner-summery-by/${fname}/lname/${lname}/year/${year}/month/${month}`
  );
  return res;
};
export const getAllCleanerWorking = async () => {
  const res = await API.get(
    `/tasks/cleaner-summery-get-all/:all`
  );
  return res;
};

export const getOrders = async () => {
  const res = await API.get(`/order/get-all-per-order`);
  return res;
};
export const getOrderDetails = async (id) => {
  const res = await API.get(`/order/get/${id}`);
  return res;
};
export const updateOrder = async (id, status) => {
  const res = await API.put(`/order/update-order/${id}`, {
    status: status,
  });
  return res;
};

export const getAllRecommendations = async () => {
  try {
    const res = await API.get(`/recommendation`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getRecommendationByID = async (id) => {
  try {
    const res = await API.get(`/recommendation/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getApprovedRecommendationByID = async (id) => {
  try {
    const res = await API.get(
      `/recommendation/approved/site-recommendation/${id}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const changeRecommendationStatus = async (id, status) => {
  try {
    const res = await API.put(`/recommendation/update-status/${id}`, {
      status: status,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createAdminRecommendation = async (requestBody) => {
  try {
    console.log("request body in axios", requestBody);
    const res = await multipartAPI.post(
      `/recommendation/approved/create`,
      requestBody
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getHelpData = async (id) => {
  const res = await API.get(`/site/help`);
  return res;
};

export const getHelpDetailsById = async (id) => {
  try {
    const res = await API.get(`/site/help/id/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteManuals = async (id) => {
  try {
    const res = await API.get(`/site/site-manual/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteManualHistory = async (id) => {
  try {
    const res = await API.get(`/site/site-manual/history/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const uploadSiteManual = async (requestBody) => {
  try {
    const res = await multipartAPI.post(
      `/site/upload-site-manual`,
      requestBody
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

//delete Tasks
export const deleteTask = async (id) => {
  const res = await API.delete(`/tasks/${id}`);
  return res.data;
};

//ALL Tasks
export const GetTasks = async () => {
  const res = await API.get(`/tasks`);
  return res;
};

//Add Task
export const addTask = async (requestBody) => {
  console.log("qqq", requestBody.description);
  if (requestBody.image) {
    const res = await multipartAPI.post(`/tasks/createTask`, requestBody);

    return res;
  } else {
    const res = await API.post(`/tasks/createTask`, requestBody);

    return res;
  }
};

// Get getAllCleanerUsers

export const GetAllCleanerUsers = async () => {
  const res = await API.get(`/user/getAllCleanerUsers`);
  return res;
};

export const GetCleanerUsersBySiteId = async () => {
  const res = await API.get(`/user/getCleanerUsersBySiteId`);
  return res;
};

//Update Task
export const updateTask = async (requestBody) => {
  const res = await multipartAPI.put(`/tasks/updateTask`, requestBody);
  return res.data;
};

export const gettaskByID = async (id) => {
  try {
    const res = await API.get(`/tasks/tasks-byId/${id}`);
    console.log("www", res);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const gettaskCommentByID = async (id) => {
  try {
    const res = await API.get(`/comments/${id}`);
    console.log("www", res);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCleanerUsersBySiteId = async (id) => {
  try {
    const res = await API.get(`/user/getCleanerUsersBySiteId/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteManualReaders = async (siteID, id) => {
  try {
    const res = await API.get(`/user/site-manual-refer/${siteID}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSiteManualById = async (id) => {
  try {
    const res = await API.get(`/site/site-manual-by-id/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSiteManual = async (id) => {
  try {
    const res = await API.delete(`/site/site-manual/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const uploadAuditDocument = async (requestBody) => {
  try {
    const res = await multipartAPI.post(`/audit/create-audit`, requestBody);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//this one need to update with send email to server
export const SendEmails = async (requestBody) => {
  try {
    const res = await multipartAPI.post(`/incident/incidentReportEmail`, requestBody);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAuditDocumentBySiteId = async (id) => {
  try {
    const res = await API.get(`/audit/get-audit-by-site/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAuditDocument = async (id) => {
  try {
    const res = await API.delete(`/audit/delete-audit/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//Add Toolbox

export const addToolbox = async (requestBody, config) => {
  console.log(requestBody);
  const res = await multipartAPI.post(`/toolbox-talk/add`, requestBody);
  return res.data;
};

//get talk

export const GetallToolbox = async () => {
  const res = await API.get(`/toolbox-talk/get-grouped`);
  return res;
};

//Delete task
export const deleteTaskArea = async (id) => {
  const res = await API.delete(`/tasks/${id}`);
  return res.data;
};

export const getSiteToolboxHistory = async (id) => {
  try {
    const res = await API.get(
      `/toolbox-talk/get-cleaner-toolbox-history-talk/${id}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

//Delete Toolbox
export const deleteToolbox = async (id) => {
  const res = await API.delete(
    `/toolbox-talk/cleaner-toolbox-talk-delete/${id}`
  );
  return res.data;
};

// Move to History
export const movehistory = async (id) => {
  const res = await API.put(`/toolbox-talk/move-history/${id}`);
  return res.data;
};

//Delete Toolbox
export const deleteHistoryToolbox = async (id) => {
  const res = await API.delete(
    `/toolbox-talk/cleaner-toolbox-talk-history-delete/${id}`
  );
  return res.data;
};

//get Document Details
export const getDocumentDetailsbyID = async (id) => {
  const res = await API.get(`/toolbox-talk/get-doc-id/${id}`);
  return res;
};

export const manualMoveToHistory = async (siteId, manualID) => {
  const res = await API.put(`/site/site-manual/move-history/${siteId}/${manualID}`);
  return res.data;
};

//get Readers Details
export const getReaderDetailsbyID = async (id) => {
  const res = await API.get(`/toolbox-talk/get-readers-with-doc-id/${id}`);
  return res;
};

// Move Back from History
export const movefromhistory = async (id) => {
  const res = await API.put(`/toolbox-talk/move-history-back/${id}`);
  return res.data;
};

export const manualMoveFromHistory = async (siteId, manualID) => {
  const res = await API.put(`/site/site-manual/move-history-back/${siteId}/${manualID}`);
  return res.data;
};
//deletehelp
export const deleteHelpById = async (id) => {
  const res = await API.delete(`/site/help/delete/${id}`);
  return res.data;
};

//update Help
export const updateHelpById = async (id, formData) => {
  const res = await multipartAPI.put(`/site/help/update/${id}`, formData);

  return res.data;
};

export const totalAttendence = async (year, month) => {
  const res = await API.get(`/tasks/dashboard/avg-attendance/year/${year}/month/${month}`);
  
  return res;
};

//get month Wise attendance
export const monthWiseAttendence = async (year) => {
  const res = await API.get(`/tasks/dashboard/average-attendance-by-months/year/${year}`);
  // /tasks/dashboard/average-attendance-by-months/year/:year
  return res;
};
//get month Wise stock
export const monthWiseStock = async (year) => {
  const res = await API.get(`/order/dashboard/get-all-stocks-by-months/${year}`);
  return res;
};
//get client progress
export const clientProgress = async (siteId, year, month) => {
  const res = await API.get(`/tasks/client-progress/${siteId}/year/${year}/month/${month}`);
  return res;
};

//time on site get all
export const getAllTimeonSite = async () => {
  try {
    const res = await API.get(`/tasks/attendance-summary`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//get cleaner by id

export const getCleanerById = async (id) => {
  try {
    const res = await API.get(`/user/getCleanerUsersById/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSitesByCleanerId = async (id) => {
  try {
    const res = await API.get(`/site/get-cleaner-sites/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDocumentsByCleanerId = async (id) => {
  try {
    const res = await API.get(`/user/docs/getByCleanerId/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};


export const addCleanerDocument = async (id, requestBody) => {
  try {
    const res = await multipartAPI.post(`/user/docs/addDocument/${id}`, requestBody);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateCleanerDocument = async (id, requestBody) => {
  try {
    const res = await multipartAPI.put(`/user/docs/updateByDocumentId/${id}`, requestBody);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCleanerDocument = async (id) => {
  try {
    const res = await API.delete(`/user/docs/deleteDocumentByDocId/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRecommendation = async (id) => {
  try {
    const res = await API.delete(`/recommendation/delete/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllCleanerUsersEach = async () => {
  try {
    const res = await API.get(`/user/getAllCleanerUsersEach`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteOrder = async (id) => {
  try {
    const res = await API.delete(`/order/delete-order-by-id/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getServerTime = async () => {
  try {
    const res = await API.get(`/site/server-time`);
    return res.data;
  } catch (error) {
    throw error;
  }
};


export const getAllNotifications = async () => {
  try {
    const res = await API.get(`/notification/get-all-notifications`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getNotification = async (id) => {
  try {
    const res = await API.get(`/notification/get-notification/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getSavedNotificationsByRecipient = async () => {
  try {
    const res = await API.get(`/notification/get-saved-notifications/admin`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const sendNotification = async (requestBody) => {
  try {
    const res = await API.post(`/notification/sendToUserAndClient`, requestBody);
      return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllbranchCount = async () => {
  try {
    const res = await API.get(`/site/dashboard/get-all-branches`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
