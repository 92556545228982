import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import PRIMARY from "../../../Theme/palette";
import { addCleanerDocument } from "../../../API/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

function CleanerDocumentUploadForm(props) {
  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialFormData = {
    document: null,
    startDate: "",
    endDate: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "startDate") {
      setFormData((prevData) => ({
        ...prevData,
        endDate: "",
      }));
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;

    if (files && files.length > 0) {
      const selectedFile = files[0];

      setFormData({
        ...formData,
        [name]: selectedFile,
      });
    } else {
      setFormData({
        ...formData,
        [name]: formData.document,
      });
    }

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  // Validation logic
  const validateForm = () => {
    const errors = {};
    const allowedTypes = ["application/pdf"];

    if (!formData.startDate.trim()) {
      errors.startDate = "Start date is required.";
    }

    if (!formData.endDate.trim()) {
      errors.endDate = "End date is required.";
    }

    if (!formData.document) {
      errors.document = "Document is required.";
    } else if (
      formData.document.type &&
      !allowedTypes.includes(formData.document.type)
    ) {
      errors.document = "Please upload valid PDF files.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);
    console.log(formData);

    if (validateForm()) {
      try {
        setisButtonLoading(true);

        const createRequestBody = {
          documents: formData.document,
          start_date: formData.startDate,
          end_date: formData.endDate,
        };

        // Create request
        await addCleanerDocument(props.id, createRequestBody)
          .then((response) => {
            console.log("response : ", response);
            if (response.status) {
              props.handleClose();
              props.setShow(!props.show);
              props.GetDocumentsByCleanerId();
              toast.success("Document added successfully!");
              setisButtonLoading(false);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error(error.response.data.message);
            setisButtonLoading(false);
            setIsDisabled(false);
          });
      } catch (error) {
        console.error("Error submitting document:", error);
      } finally {
        setisButtonLoading(false);
        setIsDisabled(false);
      }
    } else {
      setisButtonLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group>
            <Form.Label>Upload Document:</Form.Label>
            <Form.Control
              type="file"
              name="document"
              onChange={handleFileChange}
              accept=".pdf"
            />
            {formData.document && (
              <p
                style={{
                  marginTop: "5px",
                  marginBottom: "0",
                  fontWeight: "bold",
                }}
              >
                Selected Document: {formData.document.name}
              </p>
            )}
            {validationErrors.document && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.document}
              </p>
            )}
          </Form.Group>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Start Date:</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleInputChange}
              />
              {validationErrors.startDate && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.startDate}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>End Date:</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleInputChange}
                min={
                  formData.startDate &&
                  (() => {
                    const nextDay = new Date(formData.startDate);
                    nextDay.setDate(nextDay.getDate() + 1);
                    return nextDay.toISOString().split("T")[0];
                  })()
                }
              />
              {validationErrors.endDate && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.endDate}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default CleanerDocumentUploadForm;
