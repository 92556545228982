import React, { useState, useEffect } from "react";
import { getHelpData, getHelpDetailsById } from "../../../API/axios";
import { FaEye, FaPen, FaTrash, FaExclamationCircle } from "react-icons/fa";
import { Pagination, Button, Modal } from "react-bootstrap";
import { deleteHelpById } from "../../../API/axios";
import { toast } from "react-toastify";
import EditHelp from "./EditHelp";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

function TableHelp(props) {
  const [modules, setModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteComfirmation, setDeleteComfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);

  const GetAllHelps = async () => {
    try {
      const Data = await getHelpData();
      setModules(Data.data.helpDocs);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllHelps();
  }, []);

  useEffect(() => {
    GetAllHelps();
  }, [props.show, props.filterSite]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);


  useEffect(() => {
    const filteredData = modules.filter((item) => {
      const moduleMatch =
        props.filterSite === "" || item.module === props.filterSite;
      
      return moduleMatch;
    });

    setFilteredModules(filteredData);
    setFilterChanged(true);
  }, [ props.filterSite, modules]);


  const handleRowClick = async (id) => {
    try {
      const link = `/myhelps/${id}`;
      window.history.pushState(null, null, link);
      const navEvent = new PopStateEvent("popstate", { state: {} });
      window.dispatchEvent(navEvent);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(filteredModules.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentModules = filteredModules.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const deleteHelp = (id) => {
    setDeleteId(id);
    setDeleteComfirmation(true);
  };

  const confirmDelete = async () => {
    setisButtonLoading(true);
    try {
      const response = await deleteHelpById(deleteId);
      setDeleteComfirmation(false);
      GetAllHelps();
      toast.success(response.message);
      setisButtonLoading(false);
    } catch (error) {
      toast.error(error);
      console.error("Error deleting help:", error);
      setisButtonLoading(false);
    }
  };

  const openEditModal = (id) => {
    setUpdateId(id);
    setEditModalVisible(true);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };
  const isClient = localStorage.getItem("userType") === "Client";
  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
             <th scope="col" style={{width:"400px"}}>Module</th>
              <th scope="col" style={{width:"400px"}}>Title</th>
              <th scope="col" style={{ paddingLeft: "30px", width:"150px" }}>
                Actions
              </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentModules.length > 0 ? (
            currentModules.map((module) => (
              <tr key={module.id}>
                <td
                  onClick={() => handleRowClick(module.id)}
                  style={{ cursor: "pointer", margin: "10px" }}
                >
                  {module.module}
                </td>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id={`tooltip-${module.id}`}>
                      {module.heading}
                    </Tooltip>
                  }
                >
                  <td onClick={() => handleRowClick(module.id)}>
                    {module.heading && module.heading.length > 15
                      ? module.heading.substring(0, 15) + "...."
                      : module.heading}
                  </td>
                </OverlayTrigger>

                <td>
                  {!isClient && (
                    <FaPen
                      className="action-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => openEditModal(module.id)}
                    />
                  )}
                  <FaEye
                    className="action-icon"
                    onClick={() => handleRowClick(module.id)}
                    style={{ cursor: "pointer", margin: "10px" }}
                  />
                  {!isClient && (
                    <FaTrash
                      className="delete-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => deleteHelp(module.id)}
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No data available.</td>
            </tr>
          )}
        </tbody>
      </table>
      {currentModules.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
      {deleteComfirmation && (
        <Modal
          style={{ background: "rgba(15, 14, 14, 0.144)" }}
          show={deleteComfirmation}
          onHide={() => setDeleteComfirmation(false)}
        >
          <Modal.Header className="text-center">
            <div className="d-flex justify-content-center align-items-center text-danger">
              <FaExclamationCircle size={24} style={{ marginLeft: "220px" }} />
            </div>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center ">
            Are you sure you want to delete this ?
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="secondary"
              onClick={() => setDeleteComfirmation(false)}
            >
              No
            </Button>
            <Button variant="dark" onClick={confirmDelete}>
              {isButtonLoading ? (
                <>
                  <Spinner animation="border" size="sm" />{" "}
                </>
              ) : (
                "Yes"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <EditHelp
        show={editModalVisible}
        onHide={closeEditModal}
        updateId={updateId}
        GetAllHelps={GetAllHelps}
      />
    </div>
  );
}

export default TableHelp;
