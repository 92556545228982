import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { getAllTimeonSite } from "../../API/axios";

function TimeOnSiteProgressTable(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [allAttendance, setAllAttendance] = useState([]);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const userType = localStorage.getItem("userType");
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";


  const GetAllTimeonsite = async () => {
    try {
      const Data = await getAllTimeonSite();
      setAllAttendance(Data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllTimeonsite();
  }, []);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  //transform response data befor mapping
  const transformApiResponse = (allAttendance) => {
    const transformedData = [];

    // Iterate through the API response
    allAttendance.forEach((siteData) => {
      // Group attendance data by site_id and week
      const groupedAttendance = {};
      siteData.forEach((attendance) => {
        const siteKey = attendance.site_id;
        const weekKey = `${attendance.year || "N/A"}-${
          attendance.week_no || "N/A"
        }`;

        if (!groupedAttendance[siteKey]) {
          groupedAttendance[siteKey] = {};
        }

        if (!groupedAttendance[siteKey][weekKey]) {
          groupedAttendance[siteKey][weekKey] = {
            year: attendance.year || "N/A",
            month: attendance.month || "N/A",
            week_no: attendance.week_no || "N/A",
            site_id: attendance.site_id,
            site_name: attendance.site_name || "N/A",
            site_address: attendance.site_address || "N/A",
            rate: attendance.rate || 0,
            attendance: [],
          };
        }

        if (
          parseFloat(attendance.time_alocated_hours) > 0 ||
          parseFloat(attendance.total_cleaner_work_of_day_in_hours) > 0
        ) {
          groupedAttendance[siteKey][weekKey].attendance.push({
            Odate: attendance.Odate || "N/A",
            date: attendance.date || "N/A",
            day_of_week: attendance.day_of_week || "N/A",
            year_month_week: attendance.year_month_week || "N/A",
            grouped_cleaner_id: attendance.grouped_cleaner_id || "N/A",
            total_cleaner_work_of_day_in_hours:
              attendance.total_cleaner_work_of_day_in_hours || 0,
            total_workers: attendance.total_workers || 0,
            total_work_sessions: attendance.total_work_sessions || 0,
            time_alocated_hours: attendance.time_alocated_hours || 0,
            percentage: attendance.percentage || 0,
          });
        }
      });

      // Convert groupedAttendance object to an array
      const siteAttendanceArray = Object.values(groupedAttendance).flatMap(
        (weekData) => Object.values(weekData)
      );

      // Push transformed data to the result array
      transformedData.push(...siteAttendanceArray);
    });

    // Sort the transformedData array based on year and week_no in decreasing order
    transformedData.sort((a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year; // Sort by year in decreasing order
      }
      if (a.month !== b.month) {
        return b.month - a.month; // Sort by month in decreasing order
      }
      return b.week_no - a.week_no; // If years and months are equal, sort by week_no in decreasing order
    });

    return transformedData;
  };

  const transformedData = transformApiResponse(allAttendance);

  useEffect(() => {
    const filteredData = transformedData.filter((item) => {
      const clientMatch =
        props.filterClient === "" || item.site_name === props.filterClient;
      const siteMatch =
        props.filterSite === "" || item.site_address === props.filterSite;
      const dateMatch =
        props.filterDate === null ||
        props.filterDate === "" ||
        (props.filterDate.getFullYear() === item.year &&
          props.filterDate.getMonth() + 1 === item.month);

      return clientMatch && siteMatch && dateMatch;
    });
    setFilteredAttendance(filteredData);
    setFilterChanged(true);
  }, [props.filterClient, props.filterSite, props.filterDate, allAttendance]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRecords = filteredAttendance.filter(
    (item) =>
      item.attendance.length > 0 &&
      (parseFloat(item.attendance[0].time_alocated_hours) > 0 ||
        parseFloat(item.attendance[0].total_cleaner_work_of_day_in_hours) > 0)
  );
  const currentData = visibleRecords.slice(startIndex, endIndex);
  const totalPages = Math.ceil(visibleRecords.length / itemsPerPage);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  function getSaturdayDate(year, weekNumber) {
    const januaryFirst = new Date(year, 0, 1);
    const daysToSaturday = weekNumber * 7 + (6 - januaryFirst.getDay());

    const saturdayDate = new Date(januaryFirst);
    saturdayDate.setDate(januaryFirst.getDate() + daysToSaturday);

    return saturdayDate.toLocaleDateString();
  }

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        padding: "0 40px 0 0",
        marginRight: "20px",
      }}
    >
      <table
        className="table"
        style={{ margin: "10px 20px", textAlign: "center" }}
      >
        <thead className="thead-light">
          <tr>
            {!isClient && (
              <th
                scope="col"
                style={{
                  verticalAlign: "top",
                  width: "100px",
                  minWidth: "100px",
                }}
              >
                Client
              </th>
            )}
            {!isClient && (
              <th
                scope="col"
                style={{
                  verticalAlign: "top",
                  width: "100px",
                  minWidth: "100px",
                }}
              >
                Site
              </th>
            )}
            <th
              scope="col"
              style={{
                verticalAlign: "top",
                width: isClient ? "150px" : "100px",
                minWidth: "100px",
              }}
            >
              Clean date
            </th>
            <th
              scope="col"
              style={{
                verticalAlign: "top",
                borderRight: "1px solid #ddd",
                width: isClient ? "150px" : "100px",
                minWidth: "100px",
              }}
            >
              Clean day
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "#2E96FF",
                width: isClient ? "200px" : "150px",
                minWidth: "150px",
                verticalAlign: "top",
                color: "white",
                borderRight: "1px solid #ddd",
              }}
            >
              Contract hours
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "#02B2AF",
                width: isClient ? "200px" : "150px",
                minWidth: "150px",
                verticalAlign: "top",
                color: "white",
                borderRight: "1px solid #ddd",
              }}
            >
              Delivered hours
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "#1c4ca1",
                width: isClient ? "200px" : "150px",
                minWidth: "150px",
                verticalAlign: "top",
                color: "white",
                borderRight: "1px solid #ddd",
              }}
            >
              TOS less Contracted hours
            </th>
            <th
              scope="col"
              style={{
                backgroundColor: "#027977",
                width: isClient ? "200px" : "150px",
                minWidth: "150px",
                verticalAlign: "top",
                color: "white",
              }}
            >
              Attendance rate
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" style={{ textAlign: "left" }}>
                Loading...
              </td>
            </tr>
          ) : currentData.length > 0 ? (
            currentData.map((item, index) => (
              <React.Fragment key={index}>
                {item.attendance.length > 0 && ( // Check if there are any attendance records for the current item
                  <>
                    <tr key={`${index}-week`} style={{ fontWeight: "bold" }}>
                      <td colSpan="4" />
                      <td colSpan="3">
                        Week ending Saturday{" "}
                        {getSaturdayDate(item.year, item.week_no)}
                      </td>
                      <td />
                    </tr>
                    {item.attendance.map((rec, recIndex) => (
                      <tr key={recIndex}>
                        {recIndex === 0 ? (
                          <>
                            {" "}
                            {!isClient && <td>{item.site_name}</td>}
                            {!isClient && (
                              <td title={item.site_address}>
                                {truncateSiteName(item.site_address)}
                              </td>
                            )}
                          </>
                        ) : (
                          <>
                            {userType === "SuperAdmin" && <td />}
                            {userType === "SuperAdmin" && <td />}
                          </>
                        )}
                        <td>{new Date(rec.Odate).toLocaleDateString()}</td>
                        <td style={{ borderRight: "1px solid #ddd" }}>
                          {rec.day_of_week}
                        </td>
                        <td style={{ borderRight: "1px solid #ddd" }}>
                          {parseFloat(rec.time_alocated_hours).toFixed(2)}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #ddd",
                            color:
                              rec.total_cleaner_work_of_day_in_hours &&
                              rec.time_alocated_hours === 0
                                ? "red"
                                : "inherit",
                          }}
                        >
                          {parseFloat(
                            rec.total_cleaner_work_of_day_in_hours
                          ).toFixed(2)}
                        </td>
                        <td style={{ borderRight: "1px solid #ddd" }}>
                          {parseFloat(rec.total_cleaner_work_of_day_in_hours) -
                            parseFloat(rec.time_alocated_hours) >
                            0 && "+"}
                          {(
                            parseFloat(rec.total_cleaner_work_of_day_in_hours) -
                            parseFloat(rec.time_alocated_hours)
                          ).toFixed(2)}
                        </td>
                        <td
                          style={{
                            color:
                              parseFloat(rec.percentage) < parseFloat(item.rate)
                                ? "red"
                                : "black",
                          }}
                        >
                          {parseFloat(rec.percentage).toFixed(2)}%
                        </td>
                      </tr>
                    ))}
                    <tr key={`${index}-total`} style={{ fontWeight: "bold" }}>
                      <td>Total</td>
                      {userType === "SuperAdmin" && <td />}
                      {userType === "SuperAdmin" && <td />}
                      <td />
                      <td>
                        {parseFloat(
                          item.attendance
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.time_alocated_hours),
                              0
                            )
                            .toFixed(2)
                        )}
                      </td>
                      <td>
                        {parseFloat(
                          item.attendance
                            .reduce(
                              (acc, curr) =>
                                acc +
                                parseFloat(
                                  curr.total_cleaner_work_of_day_in_hours
                                ),
                              0
                            )
                            .toFixed(2)
                        )}
                      </td>
                      <td>
                        {parseFloat(
                          item.attendance
                            .reduce(
                              (acc, curr) =>
                                acc +
                                parseFloat(
                                  curr.total_cleaner_work_of_day_in_hours -
                                    curr.time_alocated_hours
                                ),
                              0
                            )
                            .toFixed(2)
                        )}
                      </td>
                      <td />
                    </tr>
                    <tr style={{ height: "3em" }} key={`${index}-space`} />
                    <tr
                      style={{ borderBottom: "1px solid #ddd" }}
                      key={`${index}-divider`}
                    />
                  </>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="3">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>

      {visibleRecords.length > itemsPerPage && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}

export default TimeOnSiteProgressTable;
