import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PRIMARY from "../../../Theme/palette";
import React, { useState } from "react";
import { movefromhistory } from "../../../API/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

function HistorymoveModal(props) {
  const [isButtonLoading, setisButtonLoading] = useState(false);
  
  const handleClose = async () => {
    setisButtonLoading(true);
    if (props.title == "toolbox-movebackfromhistory") {
      await movefromhistory(props.id)
        .then((response) => {
          if (response.status) {
            props.sethistoryShow(false);
            toast.success(response.message);
            setisButtonLoading(false);
            // props.setToolBox();
          }
        })
        .catch((error) => {
          props.sethistoryShow(false);
          toast.error(error.message);
          setisButtonLoading(false);
        });
    }
  };

  const handleCloseButton = async () => {
    props.sethistoryShow(false);
  };

  return (
    <>
      <ToastContainer />
      <Modal
        show={props.show}
        onHide={handleCloseButton}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Move Back to ToolBox</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want to Move Back to ToolBox Talk! </div>
          <br />
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              onClick={handleClose}
              style={{
                backgroundColor: PRIMARY.black,
                color: PRIMARY.white,
                fontWeight: "500",
                border: "none",
                padding: "10px 60px",
              }}
            >
              {isButtonLoading ? (
                <>
                  <Spinner animation="border" size="sm" />{" "}
                </>
              ) : (
                "Move back to ToolBox Talk"
              )}
              
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HistorymoveModal;
