import React, { useState, useEffect } from "react";
import { getIncidents } from "../../../API/axios";
import IncidentModal from "./IncidentModal";
import { FaEye } from "react-icons/fa";
import { Pagination } from "react-bootstrap";

function TableIncident(props) {
  const [show, setShow] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [incidentsUpdated, setIncidentsUpdated] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [filterIncidents, setFilterIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterChanged, setFilterChanged] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";

  const handleIncidentsUpdated = () => {
    setIncidentsUpdated(true);
  };
  useEffect(() => {
    async function fetchIncidents() {
      try {
        const response = await getIncidents();
        setIncidents(response.incidentList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching incidents:", error);
        setLoading(false);
      }
    }

    fetchIncidents();
  }, [incidentsUpdated]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = incidents.filter((incident) => {
      const clientMatch =
        props.filterClient === "" || incident.site_name === props.filterClient;
      const siteMatch =
        props.filterSite === "" || incident.site_address === props.filterSite;

      const datePickerDate = props.filterDate
        ? new Date(props.filterDate).toISOString().split("T")[0]
        : "";
      const createdDate = incident.date_reported
        ? new Date(incident.date_reported).toISOString().split("T")[0]
        : "";
      const dateMatch =
        props.filterDate === null ||
        props.filterDate === "" ||
        !incident.date_reported ||
        createdDate === datePickerDate;

      return clientMatch && siteMatch && dateMatch;
    });

    setFilterIncidents(filteredData);
    setFilterChanged(true);
  }, [props.filterClient, props.filterSite, props.filterDate, incidents]);

  const totalPages = Math.ceil(filterIncidents.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentIncidents = filterIncidents.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const handleRowClick = (incident) => {
    setSelectedIncident(incident);
    setShow(true);
  };

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            {!isClient && <th scope="col">Client</th>}
            {!isClient && <th scope="col">Site</th>}
            <th scope="col">Incident number</th>
            <th scope="col">Cleaner Name</th>
            <th scope="col">Type of Incident</th>
            <th scope="col">Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentIncidents.length === 0 ? (
            <tr>
              <td colSpan="5">There is no data.</td>
            </tr>
          ) : (
            currentIncidents.map((incident) => (
              <tr
                key={incident.id}
                onClick={() => handleRowClick(incident)}
                style={{ cursor: "pointer" }}
              >
                {!isClient && <td>{incident.site_name}</td>}
                {!isClient && (
                  <td title={incident.site_address}>
                    {truncateSiteName(incident.site_address)}
                  </td>
                )}
                <td>{incident.incident_id}</td>
                <td>
                  {incident.f_name} {incident.sur_name}
                </td>
                <td>{incident.type_of_incident}</td>
                <td>{new Date(incident.date_reported).toLocaleDateString()}</td>
                <td>
                  <FaEye
                    style={{ cursor: "pointer", margin: "8px" }}
                    className="action-icon"
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {currentIncidents.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "300px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}

      <IncidentModal
        show={show}
        setShow={setShow}
        selectedIncident={selectedIncident}
        handleIncidentsUpdated={handleIncidentsUpdated}
      />
    </div>
  );
}

export default TableIncident;
