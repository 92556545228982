import React, { useState, useEffect } from "react";
import Layout from "../../Componants/Layout/Layout";
import ChangedPassword from "../../Componants/MyProfile/ChangedPassword/ChangedPassword";

function Passwordchange() {
  const [userRole, setUserRole] = useState("");
  const [showAccessDeniedMessage, setShowAccessDeniedMessage] = useState(false);

  useEffect(() => {
    // Retrieve user role from local storage
    const roleFromStorage = localStorage.getItem("userType");
    setUserRole(roleFromStorage);
  }, []);

  // Check if userRole is 'client' to decide whether to render ChangedPassword
  const canAccessChangedPassword = userRole !== "Client";

  useEffect(() => {
    setShowAccessDeniedMessage(!canAccessChangedPassword);
  }, [canAccessChangedPassword]);

  return (
    <>
      {canAccessChangedPassword && <ChangedPassword />}
      {showAccessDeniedMessage && (
        <p>Sorry, you don't have access to change the password.</p>
        // You can replace this with any component or message you want to display
      )}
    </>
  );
}

export default Passwordchange;
