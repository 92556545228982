import React, { useState, useEffect, useRef } from "react";
import "./ChatModal.css"; // Create this CSS file for styling
import {
  FaPaperPlane,
  FaPaperclip,
  FaWindowClose,
  FaTimes,
} from "react-icons/fa";
import PRIMARY from "../../Theme/palette";
import MessageCard from "./MessageCard";
import { ms } from "date-fns/locale";
import {
  getMessages,
  uploadImageToStorage,
  sendMessages,
  updateReadMessageCount,
} from "../../API/fireStoreService";
import { Timestamp } from "firebase/firestore";
import ImagePreviewModal from "./ImagePreviewModal";
import Select from "react-select";
import { getCleanerUsersBySiteId, getSiteById } from "../../API/axios";

const ChatModal = ({ selectedChat, setSelectedChat, chatEndShow }) => {
  const [message, setMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [messages, setMessages] = useState([]);
  const user = localStorage.getItem("ID");
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [cleaners, setCleaners] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [selectedAssigneeName, setSelectedAssigneeName] = useState(null);
  const [selectedAssigneeOption, setSelectedAssigneeOption] = useState(null);
  const [disable, setDisable] = useState(false);
  const [response, setResponse] = useState(null);

  const name = localStorage.getItem("Name");
  const admin = localStorage.getItem("Admin");

  const options = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const handlePriorityChange = (selectedOption) => {
    setSelectedPriority(selectedOption);
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleAssigneeChange = (selectedOption) => {
    setSelectedAssigneeOption(selectedOption);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    openImagePreview();
  };

  const chatMessagesRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
    if (messages.length > 0) {
      chatEndShow(true);
    } else {
      chatEndShow(false);
    }
  }, [messages]);

  useEffect(() => {
    if (
      messages.length === 0 &&
      (message === "" ||
        selectedAssigneeOption == null ||
        selectedPriority == null)
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedAssigneeOption, selectedPriority, message]);

  useEffect(() => {
    setResponse(null);
    const fetchData = async () => {
      try {
        const result = await getMessages(selectedChat.id);
        const sortedMessages = result
          .slice()
          .sort((a, b) => a.timestamp - b.timestamp);
        setMessages(sortedMessages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getSiteById(selectedChat.site_id)
      .then((response) => {
        setResponse(response.sites[0]);
        console.log(response.sites[0].site_name);
        console.log(response.sites[0].site_address);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedChat]);

  useEffect(() => {}, [selectedChat]);

  const openImagePreview = () => {
    setShowImagePreview(true);
  };

  const closeImagePreview = () => {
    setShowImagePreview(false);
  };

  const cancelImage = () => {
    setSelectedImage(null);
    setShowImagePreview(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; 
    }
  };

const closeImage = () => {
  setSelectedImage(null);
  if (fileInputRef.current) {
    fileInputRef.current.value = ''; 
  }
}



  const sendMessage = async () => {
    setShowImagePreview(false);
    if (message.trim() !== "" || selectedImage) {
      var imageUrl = "";
      if (selectedImage !== null) {
        imageUrl = await uploadImageToStorage(selectedImage);
      }
      const currentDate = new Date();
      const seconds = Math.floor(currentDate.getTime() / 1000);
      const nanoseconds = (currentDate.getTime() % 1000) * 1e6;
      const timestamp = new Timestamp(seconds, nanoseconds);
      const newMessage = {
        message: message,
        imageUrl: imageUrl,
        priorityLevel: selectedPriority != null ? selectedPriority.value : "",
        receiverId:
          selectedAssigneeOption != null ? selectedAssigneeOption.user_id : "",
        receiverName:
          selectedAssigneeOption != null
            ? selectedAssigneeOption.f_name +
              " " +
              selectedAssigneeOption.l_name
            : "",
        senderEmail: "",
        senderId: user,
        senderName: "Admin :" + name,
        subMessage: "",
        messageRead: false,
        timestamp: timestamp,
      };
      setMessages([...messages, newMessage]);
      setMessage("");
       setSelectedImage(null);
      setSelectedPriority(null);
      setSelectedAssigneeOption(null);
      await sendMessages(selectedChat.id, newMessage);
      await updateReadMessageCount(selectedChat.id,user);


      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const formatDate = (msg) => {
    return new Date(
      msg.timestamp.seconds * 1000 + msg.timestamp.nanoseconds / 1e6
    ).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCleanerUsersBySiteId(selectedChat.site_id);
        if (result.status) {
          setCleaners(result.result);
        }
      } catch (error) {
        setCleaners([]);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedChat]);

  const hadleCloseChat = () => {
    setSelectedChat(null);
    setMessages([]);
  }

  return (
    <div className="chat-modal">
      {selectedChat.length != 0 && (
        <div className="chat-modal-header">
          <h5>{selectedChat.title}</h5>
          <FaTimes
            onClick={
              hadleCloseChat
            }
          />
        </div>
      )}
      {admin === "superAdmin" && response != null && (
        <div className="chat-modal-header-site">
          <h5>
            Site : {response.site_name} , Address : {response.site_address}
          </h5>
        </div>
      )}
      <div className="chat-messages" ref={chatMessagesRef}>
        {messages.map((msg, index) => (
          <div>
            <div className="msg-date-field">
              {index == 0 ? (
                <p>{formatDate(msg)}</p>
              ) : new Date(formatDate(messages[index - 1])) <
                new Date(formatDate(msg)) ? (
                <p>{formatDate(messages[index])}</p>
              ) : null}
            </div>
            <MessageCard key={index} msg={msg} index={index} />
          </div>
        ))}
      </div>

      {selectedChat.length != 0 && selectedChat.active == true && (
        <div className="chat-input">
        {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDlpFmlfc-7lW7zWbXmYUgSWtmSPTbDorPvA&usqp=CAU"/> */}
        {selectedImage ? (
              <>
              <FaTimes 
              style={{
                cursor:"pointer"
              }} 
              onClick={closeImage}
               />
              <img
                style={{ 
                  maxWidth: "5vw",
                  height:"8vh",
                  alignItems:"flex-start",
                  marginBottom:"0.3rem"
                 }}
                src={selectedImage && URL.createObjectURL(selectedImage)}
                alt="image1"
                onMouseEnter={(e) => {
                e.target.style.opacity = 0.8;
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = 1;
              }}
              />
              </>
            ):(
              <>

              </>
            )
            }
          <div className="combined-input">
            <input
              type="text"
              value={message}
              onChange={handleInputChange}
              className={
                messages.length == 0
                  ? "combined-input-message"
                  : "combined-input-message-msg"
              }
              placeholder="Type your message..."
            />
            
            {messages.length == 0 && (
              <Select
                options={cleaners}
                value={selectedAssigneeOption}
                getOptionLabel={(option) => option.f_name}
                onChange={handleAssigneeChange}
                isClearable
                placeholder="Select Assignee"
                className="select-user-assignee"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: "3px",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    padding: "3px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    padding: "3px",
                  }),
                }}
                menuPlacement="top"
              />
            )}
            {messages.length == 0 && (
              <Select
                options={options}
                value={selectedPriority}
                onChange={handlePriorityChange}
                isClearable
                placeholder="Select Priority"
                className="select-user-assignee"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: "3px",
                    marginRight: "10px",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    padding: "3px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    padding: "3px",
                  }),
                }}
                menuPlacement="top"
              />
            )}
            <button className="image-input-button" onClick={handleImageClick}>
              <FaPaperclip className="image-icon-input" />
            </button>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
           
            <button
              className="send-button"
              onClick={sendMessage}
              disabled={disable}
            >
              <FaPaperPlane className="image-icon" />
            </button>
          </div>
        </div>
      )}
      {showImagePreview && (
        <ImagePreviewModal
          imageUrl={selectedImage && URL.createObjectURL(selectedImage)}
          onCancel={cancelImage}
          onClose={closeImagePreview}
          onSend={sendMessage}
        />
      )}
    </div>
  );
};

export default ChatModal;
