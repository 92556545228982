import Modal from "react-bootstrap/Modal";
import ClientCreateForm from "./ClientCreateForm";

function ClientModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.Title === "create" ? "Create Client" : "Update Client"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientCreateForm title={props.Title} handleClose={handleClose} show={props.show} setShow={props.setShow} getAllClients={props.GetAllClients} item={props.item}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClientModal;
