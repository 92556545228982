import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PRIMARY from "../../../Theme/palette";
import ClientCreateForm from "./AreaCreateForm";
import AreaCreateForm from "./AreaCreateForm";

function AreaModal(props) {
  const handleClose = () => props.setShow(false);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.Title == "create" ? "Create Area" : "Update Area"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AreaCreateForm site={props.site} setAreas={props.setAreas} title={props.Title} area={props.area} handleClose={handleClose}/>
        </Modal.Body>
        {/*<Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              border: "none",
            }}
          >
            {props.Title == "create" ? "Submit" : "Save"}
          </Button>
          </Modal.Footer>*/}
      </Modal>
    </>
  );
}

export default AreaModal;
