import React, { useState } from "react";
import "./Mycleaner.css";
import CleanerModal from "../../Componants/Modal/CleanerModal";
import TableCleanerSummary from "../../Componants/TableCleanerSummary/Table";
import FilterStockSummary from "../../Componants/FilterStockSummary/FilterClientSummary";
import TableStockSummary from "../../Componants/TableStockSummary/Table";

function StockSummary() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filterName, setFilterName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterSiteAdd, setFilterSiteAdd] = useState("");
  const [filterYear, setFilterYear] = useState("");

  return (
    <>
      <div className="cleaner-topic">
        <p>Stock Summary</p>
      </div>
      <div>
        <FilterStockSummary
          setFilterName={setFilterName}
          setFilterSite={setFilterSite}
          setFilterSiteAdd={setFilterSiteAdd}
          setFilterYear={setFilterYear}
          filterName={filterName}
          filterYear={filterYear}
        />
        <TableStockSummary
          filterName={filterName}
          filterSite={filterSite}
          filterSiteAdd={filterSiteAdd}
          filterYear={filterYear}
        />
        <CleanerModal show={show} setShow={setShow} Title="create" />
      </div>
    </>
  );
}

export default StockSummary;
