import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import PRIMARY from "../../Theme/palette";
import { uploadSiteManual } from "../../API/axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function NewSiteManualDocument(props) {
  const [validationErrors, setValidationErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    DocumentName: "",
    document: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;

    if (files && files.length > 0) {
      const selectedFile = files[0];

      setFormData({
        ...formData,
        [name]: selectedFile,
      });
    } else {
      setFormData({
        ...formData,
        [name]: formData.document,
      });
    }

    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.DocumentName.trim()) {
      errors.DocumentName = "Document name is required.";
    }
    if (!formData.document) {
      errors.document = "Document is required.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsDisabled(true);

    if (validateForm()) {
      setisButtonLoading(true);

      const requestBody = {
        docName: formData.DocumentName,
        documents: formData.document,
      };

      uploadSiteManual(requestBody)
        .then((response) => {
          if (response.status === true) {
            toast.success("Site manual uploaded successfully!");
            props.setShowModal(!props.showModal);
            props.handleCloseModal();
          } else {
            toast.error("Error uploading document");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Error uploading document");
        })
        .finally(() => {
          setisButtonLoading(false);
          setIsDisabled(false);
        });
    } else {
      setisButtonLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "10px" }}></Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col>
            <Form.Group>
              <Form.Label>Document Name </Form.Label>
              <Form.Control
                type="text"
                name="DocumentName"
                value={formData.DocumentName}
                onChange={handleInputChange}
              />
              {validationErrors.DocumentName && (
                <p
                  style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                  className="text-danger"
                >
                  {validationErrors.DocumentName}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Form.Group>
            <Form.Label>Upload Document:</Form.Label>
            <Form.Control
              type="file"
              name="document"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx"
            />
            {formData.document && (
              <p
                style={{
                  marginTop: "5px",
                  marginBottom: "0",
                  fontWeight: "bold",
                }}
              >
                Selected Document: {formData.document.name}
              </p>
            )}
            {validationErrors.document && (
              <p
                style={{ fontSize: "12px", padding: "2px 0", margin: "0" }}
                className="text-danger"
              >
                {validationErrors.document}
              </p>
            )}
          </Form.Group>
        </Row>

        <br />
        <div className="d-flex justify-content-end">
          <Button
            type="submit"
            disabled={isDisabled}
            style={{
              backgroundColor: PRIMARY.black,
              color: PRIMARY.white,
              fontWeight: "500",
              border: "none",
              padding: "10px 60px",
            }}
          >
            {isButtonLoading ? (
              <>
                <Spinner animation="border" size="sm" />{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default NewSiteManualDocument;
