import { LineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  GetClients,
  getAllbranchCount,
  getOrders,
  monthWiseAttendence,
  monthWiseStock,
  totalAttendence,
} from "../../API/axios";

function Dashboard() {
  const [branches, setBranches] = useState([]);
  const [branchCount, setBranchCount] = useState([]);
  const [order, setOrder] = useState([]);
  const [monthAttendence, setMonthAttendence] = useState([]);
  const [monthStock, setMonthStock] = useState([]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  useEffect(() => {
    const GetAllBranches = async () => {
      try {
        const Data = await GetClients();
        const Oder = await getOrders();
        setBranches(Data.data.sites);
        setOrder(Oder.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const GetAllBranchCount = async () => {
      try {
        const Data = await getAllbranchCount();
        setBranchCount(Data.site_data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    const GetMonthWiseAttendence = async () => {
      try {
        const data = await monthWiseAttendence(currentYear, currentMonth);
        setMonthAttendence(data.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    const GetMonthWiseStock = async () => {
      try {
        const data = await monthWiseStock(currentYear, currentMonth);
        setMonthStock(data.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    GetAllBranches();
    GetMonthWiseStock();
    GetMonthWiseAttendence();
    GetAllBranchCount();
  }, []);

  const totalAttendanceArray = monthAttendence.map(
    (item) => item.percentage_working_hours ? item.percentage_working_hours : 0
  );
  const totalStockArray = monthStock.map(
    (item) => item.total_approved_order_quantity
  );

  return (
    <>
      <div className="cleaner-topic">
        <p>Executive Summary</p>
      </div>
      <div style={{ margin: "0 30px" }}>
        <Row>
          <Col style={{ margin: "0 50px" }}>
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <h5>{new Date().toLocaleDateString()}</h5>
            </div>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  padding: "10px 0px",
                  margin: "10px 0",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                }}
              >
                <h2 style={{ paddingRight: "30px", color: "blue" }}>
                  {branchCount.total_branches}
                </h2>
                <h5>Total Branches</h5>
              </div>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  padding: "30px 20px",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                  flexDirection: "column",
                  margin: "10px 0",
                }}
              >
                <h5>
                  Total Attendence:
                  <span
                    style={{
                      fontSize: "25px",
                      color: "blue",
                      paddingLeft: "20px",
                    }}
                  >
                    {parseFloat(totalAttendanceArray[currentMonth - 1]).toFixed(
                      2
                    )}
                    %
                  </span>
                </h5>
              </div>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "10px",
                  padding: "30px 20px",
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: "10px 0",
                }}
              >
                <h5>
                  Total Stock Count:
                  <span
                    style={{
                      fontSize: "25px",
                      color: "blue",
                      paddingLeft: "20px",
                    }}
                  >
                    {totalStockArray[currentMonth-1]}
                  </span>
                </h5>
              </div>
            </Row>
          </Col>
          <Col>
            <LineChart
              width={500}
              height={500}
              series={[
                {
                  data: totalAttendanceArray,
                  label: "Attendence",
                },
                {
                  data: totalStockArray,
                  label: "Stock",
                },
              ]}
              xAxis={[
                {
                  scaleType: "point",
                  data: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                },
              ]}
              xAxisTitle="Month"
              yAxisTitle="Count"
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
