import React, { useEffect, useState } from "react";
import "./Filter.css";
import { GetClients } from "../../API/axios";

function FilterClientProgress(props) {
  const [clients, setClients] = useState([]);

  const currentYear = new Date().getFullYear();
  const numberOfPreviousYears = 5;

  const yearOptions = Array.from(
    { length: numberOfPreviousYears + 1 },
    (_, index) => currentYear - numberOfPreviousYears + index
  );

  useEffect(() => {
    const GetAllCleaners = async () => {
      try {
        const Data = await GetClients();
        setClients(Data.data.sites);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    GetAllCleaners();
  }, []);

  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterName(localStorage.getItem("user"));
    props.setFilterSite(localStorage.getItem("Location"));
  }

  const uniqueClients = Array.from(
    new Set(clients.map((item) => item.site_name))
  );

  const uniqueSiteNames = Array.from(
    new Set(
      clients
        .filter((items) => items.site_name === props.filterName)
        .map((item) => item.site_address)
    )
  );
  useEffect(() => {
    const siteid = Array.from(
      new Set(
        clients
          .filter((items) => items.site_address === props.filterSite)
          .map((items) => items.site_id)
      )
    );
    props.setSiteId(siteid[0]);
  }, [props.filterSite]);
  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              value={props.filterName}
              className="form-select"
              onChange={(e) => {
                props.setFilterName(e.target.value);
                props.setFilterSite("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {clients ? (
                uniqueClients.map((name, index) => {
                  return <option value={name}>{name}</option>;
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              value={props.filterSite}
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {clients ? (
                uniqueSiteNames.map((name) => {
                  return <option value={name}>{name}</option>;
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
        <div className="col">
          <select
            value={props.filterYear}
            className="form-select"
            onChange={(e) => {
              props.setFilterYear(e.target.value);
            }}
          >
            <option value="">Select Year</option>
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <select
            value={props.filterMonth}
            className="form-select"
            onChange={(e) => {
              props.setFilterMonth(e.target.value);
            }}
          >
            <option selected value="">
              Select Month
            </option>
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default FilterClientProgress;
