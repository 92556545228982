import React from 'react';
import OrderViewDetail from '../../Componants/Orders/OrderViewDetail';

function OrderView () {

    return (
        <div>
            <div>
                <p className="cleaner-topic">Order Details</p>
            </div>
        <OrderViewDetail />
        </div>
    )

};
export default OrderView;