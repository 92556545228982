import React, { useState, useEffect } from "react";
import { getSiteById } from "../../API/axios";
import { useParams } from "react-router-dom";
import TableAuditDocuments from "../../Componants/Tables/TableSite/TableAuditDocuments";

function AuditDocuments(props) {

  const { id } = useParams();
  const [name, setName] = useState("");
  const [siteadd, setSiteadd] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getSiteById(id)
      .then((response) => {
        setName(response.sites[0].site_name);
        setSiteadd(response.sites[0].site_address);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div>
        <div className="cleaner-topic">
          <p>Audit Documents</p>
        </div>
        <div className="cleaner-topic">
          <div className="cleaner-lower-topic">
            <p>Client : {name} </p>
            <p>Site : {siteadd} </p>
          </div>
        </div>
        <div>
          <TableAuditDocuments id={id} reload={reload} setReload={setReload} />
        </div>
      </div>
    </>
  );
}

export default AuditDocuments;
