import React, { useEffect, useState } from "react";
import logo from "../../Assets/white_icon_transparent_background.png";
import "./Topbar.css";
import PRIMARY from "../../Theme/palette";
import { FaBars, FaBell } from "react-icons/fa";
import { getServerTime, getSiteById,getSavedNotificationsByRecipient } from "../../API/axios";
import NotificatioPanel from "../NotificationPanel/NotificatioPanel";

function Topbar(props) {
  const [serverTime, setServerTime] = useState(null);
  const [isshow, setIsshow] = useState(false);
  const userId = localStorage.getItem("ID");
  const userType = localStorage.getItem("userType");
  const [name, setName] = useState("");
  const [showPanel, setShowPanel] = useState(false);
  const [count,setCount] = useState(0)
  const [notifications,setNotification] = useState('')

  useEffect(() => {
    if (userType === "Client") {
      getSiteById(userId)
        .then((response) => {
          setName(response.sites[0].site_name);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    const getNotification = async() => {
        try {
            const response = await getSavedNotificationsByRecipient()
            setNotification(response.data)
            setCount(notifications.length)
            // console.log("Notification:", response.data);
            console.log(notifications);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    }
    getNotification()
}, [localStorage.getItem("notificationName")])
// console.log("NotificationCount:", count);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 660) {
        setIsshow(true);
        props.setIsShow(true);
      } else {
        setIsshow(false);
        props.setIsShow(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchServerTime = async () => {
      try {
        const response = await getServerTime();
        setServerTime(response.server_time);
      } catch (error) {
        console.error("Error fetching server time:", error);
      }
    };

    fetchServerTime();

    const interval = setInterval(fetchServerTime, 1000);

    return () => clearInterval(interval);
  }, []);


  const ToggleSideBar = () => {
    props.setIsShow(!props.isShow);
  };

  return (
    <div
      className="topbar"
      style={{ backgroundColor: PRIMARY.black, color: PRIMARY.white }}
    >
      <div className="left-content">
        {isshow && (
          <FaBars
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={ToggleSideBar}
          />
        )}
        <img src={logo} alt="Logo" className="logo" />
        <div className="text">Service Provider Portal</div>
      </div>
      <div className="right-content">
      {serverTime && new Date(serverTime).toISOString().split(".")[0].replace("T", " ")}
        {userType === "SuperAdmin" ? (
          <div className="user-info">Super Admin</div>
        ) : (
          <div className="user-info">{name}</div>
        )}
        <div className="bell ">
          <FaBell onClick={()=>{setShowPanel(!showPanel);setCount(0)}}/>
          {
            count !== 0 && <div className="bellNoti" onClick={()=>{setShowPanel(!showPanel);setCount(0)}}>{notifications.length}</div>
          }
          
          {
            showPanel && <NotificatioPanel notifications={notifications} setShowPanel={setShowPanel} />
          }
        </div>
      </div>
    </div>
  );
}

export default Topbar;
