import React, { useEffect, useState } from "react";
import "./Filter.css";
import {
  GetCleaners,
  GetClients,
  getAllCleanerWorkingHrs,
} from "../../API/axios";

function FilterClientSummary(props) {
  const [client, setClient] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const GetCleaners = async () => {
      try {
        const Data = await GetClients();
        //console.log(Data.data.sites);
        setClients(Data.data.sites);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const GetAllCleaners = async () => {
      try {
        const Data = await getAllCleanerWorkingHrs();
        //console.log(Data.data.data);
        setClient(Data.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    GetCleaners();
    GetAllCleaners();
  }, []);

  const uniqueClientNames = Array.from(
    new Set(clients.map((item) => item.site_name))
  );
  const uniqueSiteAddress = Array.from(
    new Set(
      clients
        .filter((items) => items.site_name === props.filterName)
        .map((item) => item.site_address)
    )
  );
  const isClient = localStorage.getItem("userType") === "Client";
  if (isClient) {
    props.setFilterName(localStorage.getItem("user"));
    props.setFilterSite(localStorage.getItem("Location"));
  }
  return (
    <div className="filter-wrapper p-3">
      <div className="row">
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterName(e.target.value);
                props.setFilterSite("");
              }}
            >
              <option selected value={""}>
                Select Client
              </option>
              {clients.length > 0 ? (
                uniqueClientNames.map((name) => {
                  return <option value={name}>{name}</option>;
                })
              ) : (
                <option selected>No Client to Select</option>
              )}
            </select>
          </div>
        )}
        {!isClient && (
          <div className="col">
            <select
              className="form-select"
              onChange={(e) => {
                props.setFilterSite(e.target.value);
              }}
            >
              <option selected value={""}>
                Select Site
              </option>
              {clients.length > 0 ? (
                uniqueSiteAddress.map((name) => {
                  return <option value={name}>{name}</option>;
                })
              ) : (
                <option selected>No Site to Select</option>
              )}
            </select>
          </div>
        )}
        {/* <div className="col">
          <select
            className="form-select"
            // onChange={(e) => {
            //   props.setFilterSiteAdd(e.target.value);
            // }}
          >
            <option selected>Select Week</option>
          </select>
        </div> */}
      </div>
    </div>
  );
}

export default FilterClientSummary;
