import React,{useEffect,useState} from 'react'
import './NotificatioPanel.css'
import { GoDotFill } from "react-icons/go";
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';

function NotificatioPanel({notifications,setShowPanel}) {
    
  return (
    <>
    <OutsideClickHandler onOutsideClick={() => setShowPanel(false)}>
    <div className='notification-header'>
        <h5 style={{background:'#333333', color:'white',borderRadius:'5px 5px 0 0',padding:'5px 0',margin:0}}>Notifications</h5>
        <div className='notification-wrapper'>
        <ul>
            {notifications && notifications.map((notification,index) => (
                <li key={index} >
                    <Link to={'/myorders'} className='notification-item'>
                        <div className='notification-item__content'>
                            <h6><GoDotFill color='gray'/>{notification.title}</h6>
                            <p style={{marginLeft:15}}>{
                            notification.description?.split(',').map((item, index) => (
                            <React.Fragment key={index}>
                                {item}
                                {index !== notification.description.split(',').length - 1 && <br />}
                            </React.Fragment>
                            ))}</p>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
        </div>
        
    </div>
    </OutsideClickHandler>
    </>
  )
}

export default NotificatioPanel