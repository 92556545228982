import React, { useState } from "react";
import FilterInci from "../../Componants/Filters/IncidentReport/Filter";
import TableIncidentHistory from "../../Componants/Tables/TableIncident/TableIncidentHistory";
import IncidentEmailModal from "../../Componants/Modal/IncidentEmailModal/IncidentEmailModal";

function IncidentReportHistory() {
  const [showBox, setShowBox] = useState(false);
  const [filterClient, setFilterClient] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterDate, setFilterDate] = useState("");


  return (
    <>
      <div className="cleaner-topic">
        <p>Incident Report History</p>
      </div>
      <div>
        <FilterInci
          setFilterClient={setFilterClient}
          setFilterSite={setFilterSite}
          setFilterDate={setFilterDate}
          filterClient={filterClient}
        />
        <TableIncidentHistory
          filterClient={filterClient}
          filterSite={filterSite}
          filterDate={filterDate}
          showBox={showBox}
          setShowBox={setShowBox}
        />
        <IncidentEmailModal showBox={showBox} setShowBox={setShowBox} Title="create" />
      </div>
    </>
  );
}

export default IncidentReportHistory;
