import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { getHelpDetailsById, updateHelpById } from "../../../API/axios";
import PRIMARY from "../../../Theme/palette";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const EditHelp = ({ show, onHide, updateId, GetAllHelps }) => {
  const [module, setModule] = useState("");
  const [description, setDescription] = useState("");
  const [heading, setTitle] = useState("");
  const [video, setVideo] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    async function fetchHelpDetails() {
      try {
        const response = await getHelpDetailsById(updateId);
        const { module, description, heading, url } = response.helpDocs;

        setModule(module || "");
        setDescription(description || "");
        setTitle(heading || "");
        setVideo(url || "");
      } catch (error) {
        console.error("Error fetching help details:", error);
      }
    }

    if (show && updateId) {
      fetchHelpDetails();
    }
  }, [show, updateId]);

  const clearValidationError = (fieldName) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      setIsButtonLoading(true);
      const formData = new FormData();
      formData.append("videos", video);
      formData.append("description", description);
      formData.append("module", module);
      formData.append("heading", heading);

      const response = await updateHelpById(updateId, formData);
      toast.success(response.message);
      onHide();
      GetAllHelps();
    } catch (error) {
      toast.error(error);
      console.error("Error updating help:", error);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!module.trim()) {
      errors.module = "Module is required";
    }

    if (!description.trim()) {
      errors.description = "Description is required";
    }

    if (!heading.trim()) {
      errors.title = "Title is required";
    }

    // if (!video) {
    //   errors.video = "Video file is required";
    // } else if (!video.type.startsWith("video/")) {
    //   errors.video = "Please upload a valid video file";
    // }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="module">
            <Form.Label>Module Name</Form.Label>
            <Form.Select
              value={module}
              onChange={(e) => {
                setModule(e.target.value);
                clearValidationError("module");
              }}
              isInvalid={!!validationErrors.module}
            >
              <option value="">Select a Module</option>
              <option value="Communication">Communication</option>
              <option value="Site manual">Site Manual</option>
              <option value="Recommendation">Recommendation</option>
              <option value="Incident Report">Incident Report</option>
              <option value="Site information">Site information</option>
              <option value="Tool box talk">Tool box talk</option>
              <option value="Stock">Stock</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {validationErrors.module}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={heading}
              onChange={(e) => {
                setTitle(e.target.value);
                clearValidationError("title");
              }}
              isInvalid={!!validationErrors.title}
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.title}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                clearValidationError("description");
              }}
              isInvalid={!!validationErrors.description}
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="video">
            <Form.Label>Uploaded Video</Form.Label>
            {video && (
              <div>
                <video height={250} controls>
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
            <Form.Control
              type="file"
              onChange={(e) => {
                setVideo(e.target.files[0]);
                clearValidationError("video");
              }}
              accept="video/*"
              isInvalid={!!validationErrors.video}
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.video}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button
              style={{
                backgroundColor: PRIMARY.black,
                color: PRIMARY.white,
                fontWeight: "500",
                border: "none",
                padding: "10px 60px",
                marginTop: "20px",
              }}
              type="submit"
              disabled={isButtonLoading}
            >
              {isButtonLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Updating...
                </>
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditHelp;
