import React, { useEffect, useState } from "react";
import "./ToolBoxDocument.css";
import PRIMARY from "../../Theme/palette";
import TableDocument from "../../Componants/Tables/TableTool/TableDocument";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { getSiteById, getCleanerToolBoxBySiteID } from "../../API/axios";

function ToolBoxDocuments() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const [site, setSite] = useState("");
  const [sitToolbox, setSiteToolbox] = useState("");
  const navigate = useNavigate();
  console.log("SiteID", id);
  
  useEffect(() => {
    getSiteById(id)
      .then((response) => {
        setSite(response.sites[0]);
      })
      .catch((error) => {
        console.error(error);
      });
    getCleanerToolBoxBySiteID(id)
      .then((res) => {
        setSiteToolbox(res.site_name);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const setToolBox = () => {
    getCleanerToolBoxBySiteID(id)
      .then((res) => {
        setSiteToolbox(res.site_name);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleHistoryClick = (id) => {
    const link = `/toolbox-history/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };
 
  return (
    <>
      <div className="toolboxdoc-topic">
        <p>Tool Box Talk </p>
      </div>
      <div className="toolboxdoc-topic">
        <div className="toolbox-lower-topic">
          <p>Client : {site.site_name} </p>
          <p>Site : {site.site_address} </p>
        </div>
        <div className="toolboxdoc-button-container">
          <button
            onClick={() => handleHistoryClick(site.site_id)}
            className="toolboxdoc-button"
            // style={{
            //   border: "none",
            //   backgroundColor: PRIMARY.darkGray,
            //   padding: "5px 20px",
            //   color: PRIMARY.white,
            //   fontWeight: "500",
            // }}
          >
            History
          </button>
          {/* <button
            onClick={() => setShowModal(true)}
            className="btn btn-primary toolboxdoc-button"
            style={{
              border: "none",
              backgroundColor: PRIMARY.darkGray,
              padding: "5px 20px",
              color: PRIMARY.white,
              fontWeight: "500",
            }}
          >
            Add New +
          </button> */}
        </div>
      </div>

      <div>
        <TableDocument
          sitToolbox={sitToolbox}
          setToolBox={setToolBox}
          siteID={id}
        />
      </div>
    </>
  );
}

export default ToolBoxDocuments;
