import React from 'react'
import MyProfile from '../../Componants/MyProfile/MyProfile'
import Layout from "../../Componants/Layout/Layout";

function Profile() {
  return (
    <>
        <MyProfile/>
    </>
  )
}

export default Profile