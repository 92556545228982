import React, { useState, useEffect } from "react";
import "./CleanerProfile.css";
import {
  getCleanerById,
  getDocumentsByCleanerId,
  getSitesByCleanerId,
} from "../../API/axios";
import { FaPen, FaTrash, FaEye, FaPlus } from "react-icons/fa";
import CleanerDocumentUploadModal from "../Modal/CleanerDocumentUploadModal/CleanerDocumentUploadModal";
import CleanerDocumentUpdateModal from "../Modal/CleanerDocumentUploadModal/CleanerDocumentUpdateModal";
import DeleteModal from "../Modal/DeleteModal/DeleteModal";
import { Modal } from "react-bootstrap";

function CleanerDetails({ id }) {
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cleanerDetails, setCleanerDetails] = useState("");
  const [cleanerSites, setCleanerSites] = useState("");
  const [cleanerDocuments, setCleanerDocuments] = useState("");
  const [item, setItem] = useState([]);
  const [deleteid, setDeleteid] = useState("");
  const [deleteShow, setdeleteShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const handleShow = () => {
    setShow(true);
    setUpdateShow(false);
  };

  const handleShowView = (item) => {
    setItem(item);
    setShow(false);
    setUpdateShow(true);
  };

  const handleShowDelete = (id) => {
    setDeleteid(id);
    setdeleteShow(true);
  };

  const GetCleanerById = async () => {
    try {
      const Data = await getCleanerById(id);
      setCleanerDetails(Data.result[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const GetSitesByCleanerId = async () => {
    try {
      const Sites = await getSitesByCleanerId(id);
      setCleanerSites(Sites.siteAreas);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const GetDocumentsByCleanerId = async () => {
    try {
      const documents = await getDocumentsByCleanerId(id);
      console.log("doc", documents.result);
      setCleanerDocuments(documents.result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleRowClick = (item) => {
    setShowModal(true);
    setSelectedDocument(item);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    GetCleanerById();
    GetSitesByCleanerId();
    GetDocumentsByCleanerId();
  }, []);

  const isWithinOneMonth = (endDate) => {
    const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // Assuming 30 days in a month
    const currentDate = new Date();
    const endDateTime = new Date(endDate).getTime();

    return endDateTime - currentDate.getTime() < oneMonthInMilliseconds;
  };

  const isClient = localStorage.getItem("userType") === "Client";

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };
  return (
    <>
      <div className="cleanerProfile-wrapper">
        <div className="cleanerDetails">
          <div className="cleanerimg">
            <img src={cleanerDetails?.image} className="profile-photo" />
          </div>
          <div className="cleaner-records">
            <div className="cleaner-bio">
              <div className="cleaner-bio-detail">
                <span className="cleaner-bio-topic">Employee ID : </span>{" "}
                <span>{cleanerDetails?.emp_no}</span>
              </div>
              <div className="cleaner-bio-detail">
                <span className="cleaner-bio-topic">Name : </span>{" "}
                <span>
                  {cleanerDetails?.f_name} {cleanerDetails?.l_name}
                </span>
              </div>
              <div className="cleaner-bio-detail">
                <span className="cleaner-bio-topic">Email : </span>{" "}
                <span>{cleanerDetails?.email}</span>
              </div>
              <div className="cleaner-bio-detail">
                <span className="cleaner-bio-topic">Mobile : </span>{" "}
                <span>{cleanerDetails?.phone}</span>
              </div>
            </div>
            <table className="table" style={{ margin: "10px 0px 10px -10px" }}>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Client</th>
                  <th scope="col">Site</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : cleanerSites.length > 0 ? (
                  cleanerSites.map((item) => (
                    <tr key={item.site_id}>
                      <td>{item.site_name}</td>
                      <td title={item.site_address}>
                        {truncateSiteName(item.site_address)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">There is no data.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <div>
          {!isClient && <FaPlus className="plus-icon" onClick={handleShow} />}
        </div>
        <div className="cleaner-documents">
          <table className="table" style={{ margin: "10px 0px" }}>
            <thead className="thead-light">
              <tr>
                <th scope="col">Document Name</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                {!isClient && <th scope="col">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5">Loading...</td>
                </tr>
              ) : cleanerDocuments.length > 0 ? (
                cleanerDocuments.map((item) => (
                  <tr key={item.document_id}>
                    <td
                      onClick={() => handleRowClick(item)}
                      style={{ cursor: "pointer" }}
                    >
                      {item.document_name.replace(/\.pdf$/, "")}
                    </td>
                    <td>{item.start_date ? item.start_date : ""}</td>
                    <td
                      style={{
                        color: isWithinOneMonth(item.end_date)
                          ? "red"
                          : "inherit",
                      }}
                    >
                      {item.end_date ? item.end_date : ""}
                    </td>
                    <td>
                      {!isClient && (
                        <FaPen
                          className="action-icon"
                          style={{ cursor: "pointer", margin: "8px" }}
                          onClick={() => handleShowView(item)}
                        />
                      )}
                      {!isClient && (
                        <FaTrash
                          className="delete-icon"
                          style={{ cursor: "pointer", margin: "8px" }}
                          onClick={() => handleShowDelete(item.document_id)}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No uploaded documents.</td>
                </tr>
              )}
            </tbody>
            <CleanerDocumentUpdateModal
              updateShow={updateShow}
              setUpdateShow={setUpdateShow}
              title="update"
              item={item}
              GetDocumentsByCleanerId={GetDocumentsByCleanerId}
            />
            <DeleteModal
              show={deleteShow}
              title={"cleaner-document-delete"}
              id={deleteid}
              setdeleteShow={setdeleteShow}
              GetDocumentsByCleanerId={GetDocumentsByCleanerId}
            />
          </table>
        </div>
        <CleanerDocumentUploadModal
          show={show}
          setShow={setShow}
          title="create"
          id={id}
          GetDocumentsByCleanerId={GetDocumentsByCleanerId}
        />
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedDocument?.document_name.replace(/\.pdf$/, "")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdf-view">
            <iframe
              title={selectedDocument?.document_name.replace(/\.pdf$/, "")}
              src={selectedDocument?.url}
              width="100%"
              height="500"
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default CleanerDetails;
