import React, { useState, useEffect } from "react";
import "./ToolBoxDocument.css";
import CleanerModal from "../../Componants/Modal/CleanerModal";
import TableHistory from "../../Componants/Tables/TableTool/TabletoolboxHistory";
import { getSiteById, getSiteToolboxHistory } from "../../API/axios";
import { useParams } from "react-router-dom";

function ToolBoxHistory() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const { id } = useParams();
  const [site, setSite] = useState("");
  const [siteToolboxHistory, setSiteToolboxHistory] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSiteById(id)
      .then((response) => {
        setSite(response.sites[0]);
      })
      .catch((error) => {
        console.error(error);
      });
    getSiteToolboxHistory(id)
      .then((response) => {
        setSiteToolboxHistory(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  });

  const setNewToolboxHistory = () => {
    getSiteToolboxHistory(id)
      .then((response) => {
        setSiteToolboxHistory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setNewToolboxHistory();
  }, [show]);
  //console.log("yy", siteToolboxHistory);
  return (
    <>
      <div className="toolboxdoc-topic">
        <p>Tool Box Talk History </p>
      </div>
      <div className="toolboxdoc-topic">
        <div className="toolbox-lower-topic">
          <p>Client : {site.site_name} </p>
          <p>Site : {site.site_address} </p>
        </div>
      </div>

      <div>
        <TableHistory
          siteToolboxHistory={siteToolboxHistory}
          setNewToolboxHistory={setNewToolboxHistory}
          loading={loading}
        />
      </div>
    </>
  );
}

export default ToolBoxHistory;
