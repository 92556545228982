import React, { useState } from "react";
import "./Mycleaner.css";
import CleanerModal from "../../Componants/Modal/CleanerModal";
import FilterClientSummary from "../../Componants/FilterClientSummary/FilterClientSummary";
import TableClientSummary from "../../Componants/TableClientSummary/Table";
import FilterCleanerSummary from "../../Componants/FilterCleanerSummary/FilterClientSummary";
import TableCleanerSummary from "../../Componants/TableCleanerSummary/Table";

function CleanerSummary() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");

  return (
    <>
      <div className="cleaner-topic">
        <p>Cleaner Summary </p>
      </div>
      <div>
        <FilterCleanerSummary
          setLastName={setLastName}
          setFirstName={setFirstName}
          setFilterSite={setFilterSite}
          setFilterMonth={setFilterMonth}
          setFilterYear={setFilterYear}
          firstName={firstName}
          filterMonth={filterMonth}
          filterYear={filterYear}
          lastName={lastName}
        />
        <TableCleanerSummary
          firstName={firstName}
          filterSite={filterSite}
          filterMonth={filterMonth}
          filterYear={filterYear}
          lastName={lastName}
        />
        <CleanerModal show={show} setShow={setShow} Title="create" />
      </div>
    </>
  );
}

export default CleanerSummary;
