import React, { useEffect, useState } from "react";
import "./SiteManualDocuments.css";
import PRIMARY from "../../Theme/palette";
import TableSiteManual from "../../Componants/Tables/TableTool/TableSiteManual";
import { useParams } from "react-router-dom";
import { getSiteById, getSiteManuals } from "../../API/axios";

function SiteManualDocuments() {
  const { id } = useParams();
  const [site, setSite] = useState("");

  useEffect(() => {
    getSiteById(id)
      .then((response) => {
        setSite(response.sites[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleHistoryClick = (id) => {
    const link = `/sitemanual-history/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  return (
    <>
      <div className="toolboxdoc-topic">
        <p>Site Manual</p>
      </div>
      <div className="toolboxdoc-topic">
        <div className="toolbox-lower-topic">
          <div className="toolbox-topic">
            <span style={{ fontWeight: "bold" }}>Client : </span>
            <span>{site.site_name}</span>
          </div>
          <div className="toolbox-topic">
            <span style={{ fontWeight: "bold" }}>Site : </span>
            <span>{site.site_address}</span>
          </div>
        </div>
        <div className="toolboxdoc-button-container">
          <button
            onClick={() => handleHistoryClick(site.site_id)}
            className="toolboxdoc-button"
            // style={{
            //   border: "none",
            //   backgroundColor: PRIMARY.darkGray,
            //   padding: "5px 20px",
            //   color: PRIMARY.white,
            //   fontWeight: "500",
            // }}
          >
            History
          </button>
        </div>
      </div>
      <div>
        <TableSiteManual
          siteID={id}
        />
      </div>
    </>
  );
}

export default SiteManualDocuments;
