import React, { useState, useEffect } from "react";
import ChatPopupModal from "../../../Pages/Communication/ChatPopUp";
import { Pagination } from "react-bootstrap";

const dummyChatData = [
  {
    chatId: "101",
    chatTitle: "Chat Room 1",
  },
  {
    chatId: "102",
    chatTitle: "Chat Room 2",
  },
  {
    chatId: "103",
    chatTitle: "Chat Room 3",
  },
];

function ChatsTable(props) {
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setChats(dummyChatData);
  }, []);

  const openChatPopup = (chat) => {
    console.log("CHAT : ",chat);
    setSelectedChat(chat);
    setShowChatPopup(true);
  };

  const closeChatPopup = () => {
    setShowChatPopup(false);
    setSelectedChat(null);
  };

  const totalPages = Math.ceil(chats.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentChatData = chats.slice(startIndex, endIndex);

  return (
    <>
      <table className="table" style={{ margin: "10px 20px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col">Chat ID</th>
            <th scope="col">Chat Title</th>
          </tr>
        </thead>
        <tbody>
          {currentChatData.length > 0 ? (
            currentChatData.map((chat, index) => (
              <tr key={index} onClick={() => openChatPopup(chat)}>
                <td
                  style={{ cursor: "pointer" }}                 
                  >
                  {chat.chatId}</td>
                <td
                  style={{ cursor: "pointer" }}
                >
                  {chat.chatTitle}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">There is no data.</td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination  style={{ display: "flex", justifyContent: "center", marginTop: "20px" }} >
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {Array.from({ length: totalPages }).map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
      </Pagination>

      <ChatPopupModal
      chatData={selectedChat || { chatId: "", chatTitle: "" }}
      isOpen={showChatPopup}
      onRequestClose={closeChatPopup}
    />
    </>
  );
}

export default ChatsTable;
