import React, { useState, useEffect } from "react";
import "./Mycleaner.css";
import Filter from "../../Componants/Filter/Filter";
import Table from "../../Componants/Table/Table";
import PRIMARY from "../../Theme/palette";
import CleanerModal from "../../Componants/Modal/CleanerModal";
import { getSiteById } from "../../API/axios";
import { useParams } from "react-router-dom";
import CleanerTable from "../../Componants/Tables/TableSiteCleaners/TableSiteCleaners";
import AssignCleanerModal from "../../Componants/Modal/AssignCleanersModal/AssignCleanersModal";
import AddColoumn from "../../Componants/Modal/AddColoumn/AddColoumn";
const userType = localStorage.getItem("userType");

function Mycleaner(props) {
  const [show, setShow] = useState(false);
  const [showColoumnModal, setShowColoumnModal] = useState(false);
  const [deleteShow, setdeleteShow] = useState(false);

  // const handleShow = () => setShow(true);

  const handleShow = () => {
    setShow(true);
  };

  const handleShowColoumnModal = () => {
    setShowColoumnModal(true);
  };

  const [filterName, setFilterName] = useState("");
  const [filterLastName, setFilterLastName] = useState("");
  const [filterSite, setFilterSite] = useState("");
  const [filterSiteAdd, setFilterSiteAdd] = useState("");
  const { site } = useParams();
  const [name, setName] = useState("");
  const [siteadd, setSiteadd] = useState("");
  const [reload, setReload] = useState(false);
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    getSiteById(site)
      .then((response) => {
        setName(response.sites[0].site_name);
        setSiteadd(response.sites[0].site_address);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      {props.path === "siteinformation" ? (
        <div>
          <div className="cleaner-topic">
            <p>Site Information </p>
          </div>
          <div className="cleaner-topic">
            <div className="cleaner-lower-topic">
              <p>Client : {name} </p>
              <p>Site : {siteadd} </p>
            </div>
            <div className="cleaner-lower-topic-button">
              {userType === "SuperAdmin" && (
                <button
                  onClick={handleShowColoumnModal}
                  className="add-buttonc"
                  // style={{
                  //   border: "none",
                  //   backgroundColor: PRIMARY.darkGray,
                  //   padding: "5px 30px",
                  //   color: PRIMARY.white,
                  //   fontWeight: "500",
                  // }}
                >
                  Add Coloumn +
                </button>
              )}
              {userType === "SuperAdmin" && (
                <button
                  onClick={handleShow}
                  className="add-button"
                  // style={{
                  //   border: "none",
                  //   backgroundColor: PRIMARY.darkGray,
                  //   padding: "5px 20px",
                  //   color: PRIMARY.white,
                  //   fontWeight: "500",
                  // }}
                >
                  Assign Cleaners +
                </button>
              )}
            </div>
          </div>
          <div>
            <CleanerTable site={site} reload={reload} setReload={setReload} />
          </div>
          <AssignCleanerModal
            show={show}
            setShow={setShow}
            setReload={setReload}
            reload={reload}
            site={site}
          />
          <AddColoumn
            show={showColoumnModal}
            setShow={setShowColoumnModal}
            setReload={setReload}
            reload={reload}
            site={site}
          />
        </div>
      ) : (
        <div>
          <div className="cleaner-topic">
            <p>My Cleaners </p>
            {userType === "SuperAdmin" && (
              <button
                onClick={handleShow}
                className="add-button"
                // style={{
                //   border: "none",
                //   backgroundColor: PRIMARY.darkGray,
                //   padding: "0 20px",
                //   color: PRIMARY.white,
                //   fontWeight: "500",
                // }}
              >
                Add New +
              </button>
            )}
          </div>
          <div>
            <Filter
              setFilterLastName={setFilterLastName}
              setFilterName={setFilterName}
              setFilterSite={setFilterSite}
              setFilterSiteAdd={setFilterSiteAdd}
              filterName={filterName}
              filterSite={filterSite}
              filterSiteAdd={filterSiteAdd}
              filterLastName={filterLastName}
              show={show}
              deleteShow={deleteShow}
            />
            <Table
              setdeleteShow={setdeleteShow}
              deleteShow={deleteShow}
              filterName={filterName}
              filterSite={filterSite}
              filterSiteAdd={filterSiteAdd}
              filterLastName={filterLastName}
              show={show}
              setShow={setShow}
            />
            <CleanerModal show={show} setShow={setShow} Title="create" />
          </div>
        </div>
      )}
    </>
  );
}

export default Mycleaner;
