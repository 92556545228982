import React, { useState } from "react";
import "./SiteManual.css";
import PRIMARY from "../../Theme/palette";
import CleanerModal from "../../Componants/Modal/CleanerModal";
import TableHelp from "../../Componants/Tables/TableHelp/Table";
import filterSiteManual from "../../Componants/Filters/Site-Manual/Filter";
import FilterSiteManual from "../../Componants/Filters/Site-Manual/Filter";
import TableSiteManual from "../../Componants/Tables/Tablesitemanual/Table";
import NewSiteManualDocument from "./NewSiteManualDocument";
import { Modal } from "react-bootstrap"; 
import { Form } from "react-bootstrap";

function SiteManual() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [filterName, setFilterName] = useState("");
  const [filterSiteAdd, setFilterSiteAdd] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filterSite, setFilterSite] = useState("");
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="cleaner-topic">
        <p>Site Manual </p>
        <button
          onClick={() => setShowModal(true)}
          className="add-button"
          // style={{
          //   border: "none",
          //   backgroundColor: PRIMARY.darkGray,
          //   padding: "5px 20px",
          //   color: PRIMARY.white,
          //   fontWeight: "500",
          // }}
        >
          Add New +
        </button>
      </div>
      <div>
        <FilterSiteManual
          
          setFilterSite={setFilterSite}
          filterSite={filterSite}
          setFilterSiteAdd={setFilterSiteAdd}
        />
        <TableSiteManual
          filterSite={filterSite}
          filterSiteAdd={filterSiteAdd}
        />
        <CleanerModal show={show} setShow={setShow} Title="create" />
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <NewSiteManualDocument
              handleCloseModal={handleCloseModal}
              setShowModal={setShowModal}
              showModal={showModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default SiteManual;
