import React, { useEffect, useState } from "react";
import { FaTrash, FaEye, FaPen } from "react-icons/fa";
import { GetTasks } from "../../API/axios";
import CreateTaskModal from "../Modal/CreateTaskModal/CreateTaskModal";
import DeleteModal from "../Modal/DeleteModal/DeleteModal";
import { Pagination } from "react-bootstrap";

function AssignTasksTable(props) {
  const [tasks, setTasks] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState([]);
  const [siteId, setSiteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);
  const [deleteShow, setdeleteShow] = useState(false);
  const isClient = localStorage.getItem("userType") === "Client";

  const limitUserNames = (userNames) => {
    if (userNames === null) {
      return "";
    }
    const MAX_NAMES = 3;
    const namesArray = userNames.split(" ").map((name) => name.trim());

    if (namesArray.length <= MAX_NAMES) {
      return userNames;
    }

    const limitedNames = namesArray.slice(0, MAX_NAMES).join(" ");
    return `${limitedNames} ...`;
  };

  const handleRowClick = (id) => {
    const link = `/taskinformation/${id}`;
    window.history.pushState(null, null, link);
    const navEvent = new PopStateEvent("popstate", { state: {} });
    window.dispatchEvent(navEvent);
  };

  const handleShowView = (item) => {
    setItem(item);
    setShow(true);
  };

  const GetAllTasks = async () => {
    try {
      const Data = await GetTasks();
      setTasks(Data.data.tasks);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllTasks();
  }, [props.filterClient, props.filterSite, props.filterPriority]);

  useEffect(() => {
    GetAllTasks();
  }, [props.show, show]);

  useEffect(() => {
    // Reset to the first page whenever the filter changes
    if (filterChanged) {
      setCurrentPage(1);
      setFilterChanged(false);
    }
  }, [filterChanged]);

  useEffect(() => {
    const filteredData = tasks.filter((item) => {
      const clientMatch =
        props.filterClient === "" || item.senderUserName === props.filterClient;
      const siteMatch =
        props.filterSite === "" || item.senderSiteAddress === props.filterSite;
      const priorityMatch =
        props.filterPriority === "" || item.priority === props.filterPriority;

      return clientMatch && siteMatch && priorityMatch;
    });

    setFilteredClients(filteredData);
    setFilterChanged(true);
  }, [props.filterClient, props.filterSite, props.filterPriority, tasks]);

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClients = filteredClients.slice(startIndex, endIndex);
  const maxDisplayedPages = 5;
  const startPage = Math.max(
    1,
    currentPage - Math.floor(maxDisplayedPages / 2)
  );
  const endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

  const handleShowDelete = (id) => {
    setSiteId(id);
    setdeleteShow(true);
  };

  const truncateSiteName = (name) => {
    return name.length > 20 ? name.substring(0, 20) + "..." : name;
  };

  return (
    <div
      style={{
        margin: "10px 10px",
        padding: "0 40px 0 0",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <table class="table" style={{ margin: "10px 20px" }}>
        <thead class="thead-light">
          <tr>
            {!isClient && (
              <th scope="col" style={{ width: "150px" }}>
                Client
              </th>
            )}
            {!isClient && (
              <th scope="col" style={{ width: "150px" }}>
                Site
              </th>
            )}
            <th scope="col" style={{ width: "200px" }}>
              Task Title
            </th>
            <th scope="col" style={{ width: "150px" }}>
              Priority
            </th>
            <th scope="col" style={{ width: "180px" }}>
              Deadline
            </th>
            <th scope="col" style={{ width: "150px" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          ) : currentClients.length > 0 ? (
            currentClients.map((item, index) => {
              const deadlineDate = new Date(item.deadline);
              const deadlineDateFormatted = deadlineDate
                .toISOString()
                .split("T")[0];

              return (
                <tr key={item.id}>
                  {!isClient && (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(item.id)}
                    >
                      {item.senderUserName}
                    </td>
                  )}
                  {!isClient && (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(item.id)}
                      title={item.senderSiteAddress}
                    >
                      {truncateSiteName(item.senderSiteAddress)}
                    </td>
                  )}
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.id)}
                    title={item.task_tittle}
                  >
                    {limitUserNames(item.task_tittle)}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.id)}
                  >
                    {item.priority === 1 && "High"}
                    {item.priority === 2 && "Medium"}
                    {item.priority === 3 && "Low"}
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(item.id)}
                  >
                    {deadlineDateFormatted}
                  </td>

                  <td>
                    <FaEye
                      className="action-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => handleRowClick(item.id)}
                    />
                    <FaPen
                      className="action-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => handleShowView(item)}
                    />
                    <FaTrash
                      className="delete-icon"
                      style={{ cursor: "pointer", margin: "10px" }}
                      onClick={() => handleShowDelete(item.id)}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">No data</td>
            </tr>
          )}
        </tbody>
        <CreateTaskModal
          show={show}
          setShow={setShow}
          item={item}
          GetAllTasks={GetAllTasks}
          Title="update"
        />
        <DeleteModal
          show={deleteShow}
          title={"asigntask"}
          id={siteId}
          setAreas={GetAllTasks}
          setdeleteShow={setdeleteShow}
        />
      </table>
      {currentClients.length > 0 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "200px",
          }}
        >
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {startPage > 1 && <Pagination.Ellipsis disabled />}
          {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
            <Pagination.Item
              key={startPage + index}
              active={startPage + index === currentPage}
              onClick={() => setCurrentPage(startPage + index)}
            >
              {startPage + index}
            </Pagination.Item>
          ))}
          {endPage < totalPages && <Pagination.Ellipsis disabled />}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
    </div>
  );
}
export default AssignTasksTable;
