import React, { useState, useEffect } from "react";
import Layout from "../../Componants/Layout/Layout";
import "./SiteArea.css";
import AreaTable from "../../Componants/AreaTable/AreaTable";
import AreaModal from "../../Componants/Modal/AreaModal/AreaModal";
import PRIMARY from "../../Theme/palette";
import { useParams } from "react-router-dom";
import { getSiteAreaById, getSiteById } from "../../API/axios";

function SiteArea() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const { index } = useParams();
  const [areas, setAreas] = useState([]);
  const [name, setName] = useState("");
  const userType = localStorage.getItem("userType");
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState("");

  useEffect(() => {
    getSiteAreaById(index)
      .then((response) => {
        setAreas(response.siteAreas);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    getSiteById(index)
      .then((response) => {
        setSite(response.sites[0]);
        setName(response.sites[0].site_name);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const setNewAreas = () => {
    getSiteAreaById(index)
      .then((response) => {
        const updatedAreas = response.siteAreas || [];
        setAreas(updatedAreas);
      })
      .catch((error) => {
        console.error(error);
        setAreas([]);
      });
  };

  console.log("site", site);

  return (
    <>
      <div className="site-details-container">
        <div className="site-details-wrapper">
          <div className="site-details">
            <div className="site-details-line">
              {/* <span className="details-topic">Site Name:</span> */}
              <span className="site-name">{site.site_name ? site.site_name : ""}</span>
            </div>
            <div className="site-details-line">
              <span className="details-topic">Site No:</span>
              <span>{site.site_no ? site.site_no : ""}</span>
            </div>
            <div className="site-details-line">
              <span className="details-topic">Site Mobile:</span>
              <span>{site.mobile ? site.mobile : ""}</span>
            </div>
            <div className="site-details-line">
              <span className="details-topic">Site Email:</span>
              <span>{site.site_email ? site.site_email : ""}</span>
            </div>
            <div className="site-details-line">
              <span className="details-topic">Latitude:</span>
              <span>{site.latitude ? site.latitude : "0"}</span>
            </div>
            <div className="site-details-line">
              <span className="details-topic">Longitude:</span>
              <span>{site.longitude ? site.longitude : "0"}</span>
            </div>
          </div>
          <div className="site-details">
            <div>
              <span className="site-name">{site.site_address ? site.site_address : ""}</span>
            </div>
          </div>
          <div className="site-details">
            <div>
              {/* <span className="details-topic">Site QR</span> */}
              <img
                src={site.qr_site ? site.qr_site : ""}
                alt="Sample Image"
                className="site-qr"
              />
            </div>
          </div>
        </div>
        <div className="cleaner-topic">
          <p>My Areas </p>
          {userType === "SuperAdmin" && (
            <button onClick={handleShow} className="add-button">
              Add New +
            </button>
          )}
        </div>
      </div>

      <div>
        {/* <FilterSite /> */}
        <AreaTable areas={areas} setAreas={setNewAreas} loading={loading} />
        <AreaModal
          show={show}
          site={index}
          setAreas={setNewAreas}
          setShow={setShow}
          Title="create"
        />
      </div>
    </>
  );
}

export default SiteArea;
