// ChatModal.js
import React, { useState } from "react";
import "./MessageCard.css";
import { FaSearch } from "react-icons/fa";
import PRIMARY from "../../Theme/palette";

const MessageCard = ({ msg ,index}) => {

  const user = localStorage.getItem('ID');

  console.log(msg)
  console.log("messages:",new Date(msg.timestamp.seconds * 1000 + msg.timestamp.nanoseconds / 1e6)
  .toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }));  

  return (
        <div
        key={msg.id}
        className={`message ${msg.senderId === user ? "user" : "other"}`}
        >
        <div className="message-header">
          <p> {msg.senderId === user ? "You" : msg.senderName} </p>
        </div>
        <div className="message-body">
          <p style={{color:"black"}}><b>{msg.message}</b></p>
          {
            msg.receiverId !== "" && msg.receiverName && <p>Assigned to : {msg.receiverName}</p>
          }
          {
            msg.priorityLevel !== "" && <p>Priority level : {msg.priorityLevel}</p>
          }
          {
            msg.imageUrl !== "" && <img style={{width:"27.5vh",height:"22vh"}} src={msg.imageUrl}/>
          }
        </div>
        <div className="message-footer">
          <p>{msg.timestamp.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
          {/* <p>{msg.timestamp.toDate().toLocaleString()}</p> */}
        </div>
        </div>
  );
};

export default MessageCard;
